<template>
    <nav class="sidebar sidebar-offcanvas" id="sidebar">

        <ul class="nav">
            <li class="nav-item nav-profile">
                <a href="#" class="nav-link">
                    <div class="nav-profile-image">
                        <img src="/admin_assets/images/faces/face1.jpg" alt="profile" />
                        <span class="login-status online"></span>
                        <!-- change to offline or busy as needed -->
                    </div>
                    <div class="nav-profile-text d-flex flex-column">
                        <span class="font-weight-bold mb-2">David Grey. H</span>
                        <span class="text-secondary text-small">Project Manager</span>
                    </div>
                    <i class="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
                </a>
            </li>
            <li class="nav-item">
                <Link class="nav-link" href="/dashboard">Dashboard
                <!-- <span class="menu-title"></span>
<i class="mdi mdi-home menu-icon"></i> -->
                </Link>
            </li>

            <!-- <li class="nav-item">
                <router-link class="nav-link" to="/color">
                    <span class="menu-title">Color</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/size">
                    <span class="menu-title">Size</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/brand">
                    <span class="menu-title">Brand</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/category">
                    <span class="menu-title">Category</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/subcategory">
                    <span class="menu-title">Sub Category</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/childcategory">
                    <span class="menu-title">Child Category</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/attribute">
                    <span class="menu-title">Attribute</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/attributetype">
                    <span class="menu-title">Attribute Type</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/filter">
                    <span class="menu-title">Filters</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/filtertype">
                    <span class="menu-title">Filters Type</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/product">
                    <span class="menu-title">Product</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>
-->
            <li class="nav-item">
                <router-link class="nav-link" to="/productdetail">
                    <span class="menu-title">Product Details</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>


        </ul>
    </nav>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
    components: {
        RouterLink
    },
    // other component options
}

</script>
<style></style>

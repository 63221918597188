<template>
  <!DOCTYPE html>
  <html lang="en">

  <head>
    <meta charset="utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

    <!-- Mobile Web-app fullscreen -->
    <meta name="apple-mobile-web-app-capable" content="yes" />
    <meta name="mobile-web-app-capable" content="yes" />

    <!-- Meta tags -->
    <meta name="description" content="" />
    <meta name="author" content="" />
    <link rel="icon" href="favicon.ico" />

    <!--Title-->
    <title>Login register | Lager - eCommerce HTML Template</title>

  </head>

  <body>

    <div class="wrapper">
      <!-- ========================  Login & register ======================== -->
      <section class="login-wrapper login-wrapper-page">
        <div class="container">
          <div class="login-block login-block-signup">
            <div class="h4">
              Register now
              <router-link to="/login" class="btn btn-main btn-xs btn-login pull-right">Log in</router-link>
            </div>

            <hr />

            <div class="row">
              <div class="col-md-12">
                <div class="form-group" :class="{ 'has-error': errors && errors.first_name }">
                  <input type="text" v-model="form.first_name" class="form-control" placeholder="First Name" />
                  <span class="error-message" v-if="errors && errors.first_name">{{ errors.first_name[0] }}</span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group" :class="{ 'has-error': errors && errors.last_name }">
                  <input type="text" v-model="form.last_name" class="form-control" placeholder="Last Name" />
                  <span class="error-message" v-if="errors && errors.last_name">{{ errors.last_name[0] }}</span>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group" :class="{ 'has-error': errors && errors.email }">
                  <input type="email" v-model="form.email" class="form-control" placeholder="Your Email" />
                  <span class="error-message" v-if="errors && errors.email">{{ errors.email[0] }}</span>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group" :class="{ 'has-error': errors && errors.password }">
                  <input type="password" v-model="form.password" class="form-control" placeholder="Your Password" />
                  <span class="error-message" v-if="errors && errors.password">{{ errors.password[0] }}</span>
                </div>
              </div>

              <div class="col-md-12">
                <button @click.prevent="saveForm" type="submit" class="btn btn-main btn-block">
                  Create Account
                </button>
              </div>
            </div>
          </div>
          <!--/signup-->
        </div>
      </section>
    </div>
    <!--/wrapper-->
  </body>

  </html>
</template>
<script>
import { BASE_URL } from "../../../../config";
import router from "@/router";
import axios from "axios";

export default {
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        roles: "0",
        password: "",
      },
      errors: null,
    };
  },
  methods: {
    saveForm() {
      axios
        .post(`${BASE_URL}register`, this.form)
        .then(() => {
          router.push({ path: '/login', query: { registrationSuccess: true } });
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            // Validation error
            this.errors = error.response.data.errors;
          } else {
            // Other types of errors
            this.errors = {
              general: "An error occurred while processing your request.",
            };
          }
        });
    },

    // removeErrorElements() {
    //   const errorElements = document.getElementsByClassName("error-message");
    //   for (let i = 0; i < errorElements.length; i++) {
    //     const errorElement = errorElements[i];
    //     errorElement.style.display = "none";
    //   }
    // },
  },
};
</script>

<style scoped>
section {
  margin-top: 150px !important;
}

.text-danger {
  color: red !important;
}

body {
  background-color: #2e73bb;
  color: #ffffff;
  font-family: Arial, sans-serif;
}

.wrapper {
  margin-top: 150px;
}

.login-wrapper-page {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-block-signup {
  text-align: center;
}

.login-block-signup .h4 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.login-block-signup .btn-login {
  background-color: transparent;
  color: #2e73bb;
  font-weight: bold;
  border: 2px solid #2e73bb;
  margin-top: -5px;
}

.login-block-signup .btn-login:hover {
  background-color: #2e73bb;
  color: #ffffff;
}

.login-block-signup hr {
  margin-top: 20px;
  margin-bottom: 30px;
  border-color: #ccc;
}

.login-block-signup .form-group {
  margin-bottom: 20px;
}

.login-block-signup input[type="text"],
.login-block-signup input[type="email"],
.login-block-signup input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.login-block-signup .error-message {
  display: block;
  margin-top: 5px;
  font-size: 12px;
}

.login-block-signup button {
  background-color: #2e73bb;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  border: none;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}

.login-block-signup button:hover {
  background-color: #1c528c;
}

</style>
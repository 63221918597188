<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save">

                        <select v-model="branddiscount.brand_id" class="form-control mb-3">
                            <option value="0" selected disabled hidden>-- Choose Brand --</option>
                            <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                                {{ brand.brand_name }}
                            </option>
                        </select>

                        <select v-model="branddiscount.discount_id" class="form-control mb-3">
                            <option value="0" selected disabled hidden>-- Choose Discount --</option>
                            <option v-for="discount in discounts" :key="discount.id" :value="discount.id">
                                {{ discount.discount_name }}
                            </option>
                        </select>

                        <button type="submit" class="btn btn-success">Create</button>

                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    

<script>
import axios from "axios";
import router from "@/router";
import { BASE_URL } from "../../../../../config";

export default {
    name: "CreateBrandDiscount",

    data() {
        return {
            branddiscount: {
                brand_id: 0,
                discount_id: 0,
            },
            brands: [], // Update variable name to employees
            discounts: []
        };
    },

    methods: {
        save() {
            this.saveData();
        },
        saveData() {
            let formData = new FormData();
            formData.append('brand_id', this.branddiscount.brand_id);
            formData.append('discount_id', this.branddiscount.discount_id);
            axios.post(`${BASE_URL}brand-discount-store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/brand-discount");
            });
        },
        fetchAttributes() {
            axios
                .get(`${BASE_URL}brand-discount-create`) // Update endpoint to fetch employees
                .then(response => {
                    this.brands = response.data.brand;
                    this.discounts = response.data.discount;
                });
        },
    },
    mounted() {
        this.fetchAttributes();
    },
};
</script>

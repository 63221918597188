<template>
    <!-- Cart Start -->
    <div class="container-fluid">
        <div class="row px-xl-5">
            <div class="col-lg-12 table-responsive mb-5">
                <table class="table table-light table-borderless table-hover text-center mb-0">
                    <thead class="thead-dark">
                        <tr>
                            <th>Products</th>
                            <th>Price</th>
                            <th>Remove</th>
                        </tr>
                    </thead>
                    <tbody class="align-middle">
                        <tr v-for="favorite in favorites" :key="favorite.id">
                            <td><img :src="'https://api.loop-delivery.com/product_detail/' + favorite.productdetail.image" alt=""
                                    style="width: 50px;"> {{
                                        favorite.productdetail.title }}
                            </td>
                            <td class="align-middle">$ {{ favorite.productdetail.sale_price }}</td>
                            <td class="align-middle"><button @click="remove(favorite)" class="btn btn-sm btn-danger"><i
                                        class="fa fa-times"></i></button></td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div class="col-lg-4">

            </div>
        </div>
    </div>
    <!-- Cart End -->
</template>

<script>
import axios from "axios";
import { BASE_URL } from "../../../../config";
import router from "@/router";

export default {
    name: "addtofavorite-view",

    data() {
        return {
            favorites: [],
        };
    },

    created() {
        this.favoriteLoad();
    },

    methods: {
        favoriteLoad() {
            var page = `${BASE_URL}favorite?user_id=${localStorage.getItem("id")}`;
            axios.get(page).then(({ data }) => {
                this.favorites = data.favorite;
            });
        },

        remove(favorite) {
            const url = `${BASE_URL}favorite-destroy/${favorite.id}`;
            axios.delete(url)
                .then(() => {
                    const index = this.favorites.indexOf(favorite);
                    this.favorites.splice(index, 1);
                    router.go(0); // Go back one step in the browser history
                })
                .catch((error) => {
                    console.error(error);
                    alert('An error occurred while removing the favorite item.');
                });
        },

    },
};

</script>
<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="editData" enctype="multipart/form-data">
                        <input type="hidden" v-model="childcategory.id" />

                        <input type="text" class="form-control" v-model="childcategory.child_category_name" /><br /><br />

                        <select class="form-control mb-3" v-model="childcategory.sub_category_id">
                            <option v-for="subcategory in subcategories" :key="subcategory.id" :value="subcategory.id">
                                {{ subcategory.sub_category_name }}
                            </option>
                        </select>

                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />

                        <button type="submit" class="btn btn-success">Save</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axios from "axios";
import router from "@/router";
import { BASE_URL } from "../../../../../config";

export default {
    name: "EditChildCategory",

    data() {
        return {
            childcategory: {
                sub_category_id: "",
                child_category_name: "",
                child_category_image: "",
                id: this.$route.params.id,
            },
            subcategories: {}, // new data property to store the old employee data
            oldChildCategory: {},
        };
    },
    methods: {
        onFileChange(event) {
            this.childcategory.child_category_image = event.target.files[0];
        },

        editData() {
            let formData = new FormData();
            if (this.childcategory.child_category_name) {
                formData.append('child_category_name', this.childcategory.child_category_name);
            }
            if (this.childcategory.child_category_image) {
                formData.append('child_category_image', this.childcategory.child_category_image);
            }
            if (this.childcategory.sub_category_id) {
                formData.append('sub_category_id', this.childcategory.sub_category_id);
            }
            axios.post(`${BASE_URL}childcategory-update/${this.childcategory.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                this.childcategory = {
                    child_category_name: "",
                    sub_category_id: "",
                    child_category_image: "",
                    id: "",
                };
                router.push("/childcategory");
            }).catch((error) => {
                console.log(error);
            });
        },
        fetchData() {
            axios
                .get(`${BASE_URL}childcategory-edit/` + this.childcategory.id)
                .then((response) => {
                    this.childcategory = response.data.childcategory;
                    this.subcategories = response.data.subcategories;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.fetchData(); // call the fetchData method when the component is mounted to fetch the old employee data
    },
};
</script>
<template>
    <div>
        <section class="products">
            <header>
                <div class="row">
                    <div class="col-md-offset-2 col-md-8 text-center">
                        <h2 class="title">Categories</h2>
                        <div class="text">
                            <p>Select category</p>
                        </div>
                    </div>
                </div>
            </header>

            <div class="row row-clean">
                <div class="col-xs-6 col-sm-4 col-md-3" style="padding: 0 !important; margin:0 !important"
                    v-for="category in categories" :key="category.id">
                    <article>
                        <div class="card">
                            <router-link :to="{ name: 'SubCategory', params: { id: category.id } }">
                                <img :src="'https://api.loop-delivery.com/category_image/' + category.category_image" alt="" />
                            </router-link>
                            <div class="card-body">
                                <p class="card-text">
                                    <router-link :to="{ name: 'SubCategory', params: { id: category.id } }">
                                        {{ category.category_name }}
                                    </router-link>
                                </p>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </section>
    </div>
    <FooterLayout />
</template>

<script>
import FooterLayout from "./FooterLayout.vue";
import axios from "axios";
import { BASE_URL } from "../../../../config";

export default {
    components: { FooterLayout },
    name: "SubCategory",

    data() {
        return {
            categories: [],
        };
    },

    created() {
        this.dataLoad();
    },

    methods: {
        dataLoad() {
            var page = `${BASE_URL}allCategories`;
            axios.get(page).then(({ data }) => {
                this.categories = data;
                console.log(data);
            });
        },
    },
};
</script>

<style scoped>
.card img {
    width: 42vh;
    height: 50vh;
}


.card {
    position: relative;
}

.card-body {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white !important
}

a {
    color: white;
    text-decoration: none;
}

a:hover{
    color: #ccc;
}

.card-text {
    margin: 0;
    color: white !important;
}
</style>
<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save">
                        <div class="flex flex-wrap max-w-xl">
                            <div class="p-2 text-2xl text-gray-800 font-semibold">
                                <h3>Register an account</h3>
                            </div>
                            <div class="p-2">
                                <label class="" for="name">First Name</label>
                                <input class="form-control" placeholder="First Name" type="text" v-model="form.first_name">
                            </div>
                            <div class="p-2">
                                <label class="" for="last_name">Last Name</label>
                                <input class="form-control" placeholder="Last Name" type="text" v-model="form.last_name">
                            </div>

                            <div class="p-2">
                                <label for="email">Your e-mail</label>
                                <input class="form-control" placeholder="Email" type="email" v-model="form.email">
                            </div>
                            <div class="p-2">
                                <label for="password">Password</label>
                                <input class="form-control" placeholder="Password" type="password" v-model="form.password"
                                    name="password">
                            </div>

                            <div class="p-2">
                                <label for="role">Roles</label>
                                <select v-model="form.roles" class="form-control">
                                    <option value="0">User</option>
                                    <option value="5">Sub Affiliate</option>
                                </select>
                            </div>

                            <div class="p-2 w-full mt-4">
                                <button type="submit" class="btn btn-primary">Register</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { BASE_URL } from "../../../../../config";
import axios from "axios";
import router from "@/router";

export default {

    data() {
        return {
            form: {
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                roles: 0
            },
            errors: []
        }
    },

    methods: {
        save() {
            this.saveData();
        },

        saveData() {
            let formData = new FormData();
            formData.append('first_name', this.form.first_name);
            formData.append('last_name', this.form.last_name);
            formData.append('email', this.form.email);
            formData.append('password', this.form.password);
            formData.append('roles', this.form.roles);
            formData.append('user_id', localStorage.getItem("id"));
            axios.post(`${BASE_URL}affiliate-user-store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/affiliate-user");
            });
        },
        //     saveForm() {
        //         axios.post(`${BASE_URL}affiliate-user-store`, this.form).then(() => {

        //             router.push("/affiliate-user");
        //         }).catch((error) => {
        //             this.errors = error.response.data.errors;
        //         })
        //     }
    }
}
</script>
<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Cart Panel</h3>

                    <select name="" id="" v-model="selectedUserId" @change="cartLoad">
                        <option v-for="user in users" :key="user.user_id" :value="user.user_id">
                            {{ user.user.first_name }} {{ user.user.last_name }}
                        </option>
                    </select>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product Image</th>
                                <th>Product Sku</th>
                                <th>Product Title</th>
                                <th>Product Price</th>
                                <th>Discount</th>
                                <th>Quantity</th>
                                <th>Price After Discount</th>
                                <th>Total</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="invoice in invoices" v-bind:key="invoice.id">
                                <th scope="row">{{ invoice.id }}</th>
                                <td><img :src="'https://api.loop-delivery.com/product_detail/' + invoice.image"></td>
                                <td>{{ invoice.sku }}</td>
                                <td>{{ invoice.title }}</td>
                                <td>$ {{ invoice.sale_price.toFixed(2) }}</td>
                                <td>{{ invoice.discount }}%</td>
                                <td>
                                    <button @click="incrementQuantity(invoice)">+</button>
                                    <span>{{ invoice.quantity }}</span>
                                    <button @click="decrementQuantity(invoice)">-</button>
                                </td>
                                <td v-if="invoice.discount > 0">
                                    <span class="final h3" style="margin-right: 10px;"
                                        v-if="invoice.price_after_discount != null">
                                        ${{ (invoice.price_after_discount - (invoice.price_after_discount * invoice.quantity
                                            *
                                            invoice.discount / 100)).toFixed(2) }}
                                    </span>

                                    <span class="final h3" style="margin-right: 10px;" v-else>
                                        ${{ (invoice.sale_price - (invoice.sale_price * invoice.quantity *
                                            invoice.discount / 100)).toFixed(2) }}
                                    </span>

                                    <span class="discount" v-if="invoice.price_after_discount != null"
                                        style="margin-right: 10px;">
                                        $<s>{{ (invoice.sale_price * invoice.quantity).toFixed(2) }}</s>
                                    </span>

                                    <span class="discount" v-else-if="invoice.price_after_discount === null"
                                        style="margin-right: 10px;">
                                        $<s>{{ (invoice.sale_price * invoice.quantity).toFixed(2) }}</s>
                                    </span>
                                </td>
                                <td v-else>

                                    <span class="final h3" style="margin-right: 10px;">
                                        ${{ invoice.price_after_discount ? (invoice.price_after_discount).toFixed(2) :
                                            (invoice.sale_price *
                                                invoice.quantity - (invoice.sale_price * invoice.quantity * invoice.discount /
                                                    100)).toFixed(2) }}
                                    </span>

                                    <span class="discount" v-if="invoice.price_after_discount != null"
                                        style="margin-right: 10px;">
                                        $<s>{{ (invoice.sale_price * invoice.quantity).toFixed(2) }}</s>
                                    </span>
                                </td>

                                <td>$ {{ (invoice.sale_price * invoice.quantity - (invoice.sale_price * invoice.quantity *
                                    invoice.discount / 100)).toFixed(2) }}</td>

                                <td>$ 1</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="clearfix" style="margin-top: 2%">
                        <div class="cart-block cart-block-footer clearfix">
                            <div>
                                <strong>sub Total</strong>
                            </div>
                            <div>
                                <span>$ {{ cartTotal.toFixed(2) }}</span>
                            </div>
                        </div>

                        <div class="cart-block cart-block-footer clearfix" v-if="selectedGovernorateDetails">
                            <div>
                                <strong>Delivery</strong>
                            </div>
                            <div>
                                <span>$ {{ selectedGovernorateDetails.price.toFixed(2) }}</span>
                            </div>
                        </div>

                        <div class="cart-block cart-block-footer clearfix" v-if="selectedGovernorateDetails">
                            <div>
                                <strong>Total</strong>
                            </div>
                            <div>
                                <span>$ {{ (cartTotal + selectedGovernorateDetails.price).toFixed(2) }}</span>
                            </div>
                        </div>
                    </div>

                    <!--cart final price -->

                    <div class="clearfix">

                        <div class="cart-block cart-block-footer cart-block-footer-price clearfix">
                            <div>
                                <form @submit.prevent="save">
                                    <div class="alert" :class="[isCouponError ? 'alert-danger' : 'alert-success']"
                                        role="alert" v-if="couponMessage">
                                        <span v-if="isCouponError"><i class="fa fa-close"></i></span>
                                        <span v-else>&#10004;</span>
                                        {{ couponMessage }}
                                    </div>
                                    <span class="checkbox">
                                        <input type="checkbox" id="couponCodeID" />
                                        <label for="couponCodeID">Promo code</label>
                                        <input type="text" v-model="cart.coupon_code" class="form-control form-coupon"
                                            placeholder="Enter your coupon code" />
                                    </span>

                                    <button type="submit" class="btn btn-main">Apply</button>
                                </form>
                            </div>
                            <div>
                                <div></div>
                            </div>
                        </div>
                    </div>

                    <form @submit.prevent="saveOrder">
                        <div class="login-block login-block-signup">
                            <hr />

                            <h3>Your Information</h3>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" v-model="checkout.first_name" class="form-control"
                                            placeholder="First name: *" />
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" v-model="checkout.middle_name" class="form-control"
                                            placeholder="Middle name: *" />
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" v-model="checkout.last_name" class="form-control"
                                            placeholder="Last name: *" />
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <select class="form-control" v-model="checkout.selectedGovernorate">
                                            <option value="0" selected hidden>-- Choose your governorates --</option>
                                            <option v-for="governorate in governorates" :key="governorate.id"
                                                :value="governorate.id">
                                                {{ governorate.governorates_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <select class="form-control" v-model="checkout.selectedCity">
                                            <option value="0" selected hidden>-- Choose your city --</option>
                                            <option v-for="city in filteredFilterGovernorate(checkout.selectedGovernorate)"
                                                :key="city.id" :value="city.id">
                                                {{ city.city_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <select class="form-control" v-model="checkout.area_id">
                                            <option value="0" selected hidden>-- Choose your area --</option>
                                            <option v-for="area in filteredFilterCity(checkout.selectedCity)" :key="area.id"
                                                :value="area.id">
                                                {{ area.area_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" v-model="checkout.address" class="form-control"
                                            placeholder="Address: *" />
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" v-model="checkout.phone" class="form-control"
                                            placeholder="Phone: *" />
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" v-model="checkout.note" class="form-control"
                                            placeholder="Note: *" />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="white-block">
                                <div class="h4">Choose delivery</div>

                                <hr />

                                <span class="checkbox">
                                    <input type="radio" id="deliveryId2" name="deliveryOption"
                                        v-model="checkout.deliveryOption" value="delivery" />
                                    <label for="deliveryId2">Delivery - <strong>{{ selectedGovernorateDetails ? '$ ' +
                                        selectedGovernorateDetails.price.toFixed(2) : '' }}</strong></label>
                                </span>

                                <span class="checkbox">
                                    <input type="radio" id="deliveryId3" name="deliveryOption"
                                        v-model="checkout.deliveryOption" value="pickup" />
                                    <label for="deliveryId3">Pick up in the store - <strong>0</strong></label>
                                </span>

                                <div class="col-xs-6 text-center">
                                    <button type="submit" class="btn btn-main">
                                        <span class="icon icon-cart"></span> Checkout
                                    </button>
                                </div>

                                <hr />
                            </div>
                        </div>
                    </form>
                    <!--/signup-->

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "../../../../../config";


export default {

    data() {
        return {
            cart: {
                coupon_code: "",
            },

            invoices: [],
            invoice: [],
            users: [],
            selectedUserId: null,
            checkout: {
                area_id: "",
                first_name: "",
                middle_name: "",
                last_name: "",
                address: "",
                note: "",
                phone: "",
                deliveryOption: 'delivery',
                pick_up: 1,
                selectedGovernorate: [],
                selectedCity: [],
                user_id: '',
            },
            priceAfterDiscount: null,
            carts: [],
            governorates: [],
            areas: [],
            cities: [],
            isLoading: false, // Add this line
            message: "", // Define the "message" property with an initial value
            isError: false,
            isCouponError: false,
            couponMessage: ""
        }
    },

    watch: {
        carts: {
            handler() {
                this.$nextTick(() => {
                    this.updateCartTotal();
                });
            },
            deep: true,
        },
    },

    computed: {
        selectedGovernorateDetails() {
            if (this.checkout.selectedCity === 0) {
                return null; // No governorate selected
            }

            if (this.checkout.deliveryOption === 'pickup') {
                return { price: 0 }; // Set price to 0 for pick up in store
            }

            return this.cities.find((city) => city.id === this.checkout.selectedCity);
        },

        cartTotal() {
            return this.invoices.reduce((total, invoice) => {
                let price;
                if (invoice.price_after_discount !== null) {
                    price = invoice.price_after_discount - (invoice.price_after_discount * invoice.quantity * invoice.discount / 100);
                } else {
                    price = invoice.sale_price * invoice.quantity - (invoice.sale_price * invoice.quantity * invoice.discount / 100);
                }
                return total + parseFloat(price);
            }, 0);
        },
    },

    created() {
        this.cartLoad();
        this.userLoad();
    },

    methods: {
        save() {
            this.saveData();
        },

        saveData() {
            let formData = new FormData();
            formData.append("coupon_code", this.cart.coupon_code);
            formData.append("user_id", this.selectedUserId);
            axios
                .post(
                    `${BASE_URL}affiliate-coupon`,
                    formData
                )
                .then((response) => {
                    if (response.status === 200) {
                        this.couponMessage = "Coupon has been added successfully";
                        this.isCouponError = false;
                        this.priceAfterDiscount = response.data.price_after_discount; // Update the price after discount
                        this.updateCartTotal(); // Update the cart total
                        this.cartLoad();
                    } else {
                        this.couponMessage = "Failed to add coupon";
                        this.isCouponError = true;
                    }
                    this.hideCouponMessageAfterDelay(5000);
                })
                .catch((error) => {
                    this.couponMessage = "Failed to add coupon";
                    this.isCouponError = true;
                    console.error("Failed to add coupon", error);
                    this.hideCouponMessageAfterDelay(5000);
                });
        },

        filteredFilterGovernorate(governorateId) {
            return this.cities.filter(city => city.governorates_id === governorateId)
        },

        filteredFilterCity(cityId) {
            return this.areas.filter(areas => areas.city_id === cityId);
        },

        saveOrder() {
            this.saveOrderData();
        },

        saveOrderData() {
            if (this.checkout.deliveryOption === 'pickup') {
                this.checkout.pick_up = 0; // Pick up from the store
            } else {
                this.checkout.pick_up = 1; // Not pick up from the store
            }

            // Prepare the order data to be sent to the server
            const orderData = {
                user_id: this.selectedUserId,
                area_id: this.checkout.area_id,
                first_name: this.checkout.first_name,
                middle_name: this.checkout.middle_name,
                last_name: this.checkout.last_name,
                address: this.checkout.address,
                note: this.checkout.note,
                phone: this.checkout.phone,
                pick_up: this.checkout.pick_up,
                // other order data properties...
            };

            // Make the API request to save the order data
            axios.post(`${BASE_URL}affiliate-checkout`, orderData)
                .then((response) => {
                    if (response.status === 200) {
                        // Handle success case
                    } else {
                        // Handle error case
                    }
                })
                .catch(() => {
                    // Handle error case
                });
        },

        userLoad() {
            var page = `${BASE_URL}userCart?user_id=${localStorage.getItem("id")}`;
            axios.get(page).then(({ data }) => {
                this.users = data.user;
                this.governorates = data.governorate;
                this.cities = data.city;
                this.areas = data.area;
                this.isLoading = false;
            });
        },

        cartLoad() {
            var page = `${BASE_URL}get-cart?user_id=${this.selectedUserId}`;
            axios.get(page).then(({ data }) => {
                this.invoices = data.invoice;
                this.governorates = data.governorate;
                this.cities = data.city;
                this.areas = data.area;
                this.isLoading = false;
            });
        },

        incrementQuantity(invoice) {
            invoice.quantity++;
            this.updateCartTotal();
            this.updateQuantityInDatabase(invoice);
        },

        decrementQuantity(invoice) {
            if (invoice.quantity > 1) {
                invoice.quantity--;
                this.updateCartTotal();
                this.updateQuantityInDatabase(invoice);
            }
        },

        updateQuantityInDatabase(invoice) {
            const productDetailId = invoice.product_detail_id || invoice.id; // Use invoice.product_detail_id if available, otherwise fall back to invoice.id

            axios.post(`${BASE_URL}cart-update/${invoice.id}`, {
                quantity: invoice.quantity,
                user_id: this.selectedUserId,
                product_detail_id: productDetailId,
            })
        },

        updateCartTotal() {
            if (
                this.invoice &&
                this.invoice.discount !== undefined &&
                this.invoice.quantity !== undefined
            ) {
                if (this.invoice.discount > 0 && this.priceAfterDiscount !== null) {
                    // Calculate total with discount
                    this.cartTotal = (this.priceAfterDiscount * this.invoice.quantity).toFixed(2);
                } else {
                    // Calculate total without discount
                    this.cartTotal = (this.invoice.sale_price * this.cart.quantity - (this.invoice.sale_price * this.invoice.quantity * this.invoice.discount / 100)).toFixed(2);
                }
            }
        },

        hideCouponMessageAfterDelay(delay) {
            setTimeout(() => {
                this.Couponmessage = ""; // Clear the message
                this.isCouponError = false; // Reset isError to false
            }, delay);
        },

        hideMessageAfterDelay(delay) {
            setTimeout(() => {
                this.message = ""; // Clear the message
                this.isError = false; // Reset isError to false
            }, delay);
        },
        // remove(carts) {
        //     var url = `${BASE_URL}cart-delete/${carts.id}`;
        //     axios
        //         .delete(url)
        //         .then((response) => {
        //             if (Array.isArray(this.carts) && carts) {
        //                 const index = this.carts.indexOf(carts);
        //                 if (index !== -1) {
        //                     this.carts.splice(index, 1);
        //                 }
        //             }
        //             if (response.status === 200) {
        //                 this.message = "Product has been removed from cart successfully"; // Update success message
        //                 this.isError = false; // Reset isError to false
        //             } else {
        //                 this.message = "Failed to remove product"; // Update error message
        //                 this.isError = true; // Set isError to true
        //             }
        //             this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
        //         })
        //         .catch((error) => {
        //             this.message = "Failed to remove product"; // Update error message
        //             this.isError = true; // Set isError to true
        //             console.error("Failed to remove product", error);
        //             this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
        //         });
        // },

    }
}
</script>
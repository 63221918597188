<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save" enctype="multipart/form-data">
                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />

                        <progress v-if="logo.progress" :value="logo.progress.percentage" max="100">
                            {{ logo.progress.percentage }}%
                        </progress>

                        <button type="submit" class="btn btn-primary">Create Logo</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axios from "axios";
import router from "@/router";
import { BASE_URL } from "../../../../../config";

export default {
    name: "CreateLogo",

    data() {
        return {
            logo: {
                logo: ""
            },
        };
    },

    methods: {
        onFileChange(event) {
            this.logo.logo = event.target.files[0];
        },

        save() {
            this.saveData();
        },
        saveData() {
            let formData = new FormData();
            formData.append('logo', this.logo.logo);
            axios.post(`${BASE_URL}logo-store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/logo-view");
            });
        },
    },
};
</script>
<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save">
                        <input type="text" class="form-control" placeholder="Filter Name"
                            v-model="filter.filter_name" /><br /><br />

                        <select v-model="filter.child_category_id" class="form-control mb-3">
                            <option value="0" selected disabled hidden>-- Choose Child Category --</option>
                            <option v-for="childcategory in childcategories" :key="childcategory.id" :value="childcategory.id">
                                {{ childcategory.child_category_name }}
                            </option>
                        </select>

                        <button type="submit" class="btn btn-success">Create</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    

<script>
import axios from "axios";
import router from "@/router";
import { BASE_URL } from "../../../../../config";

export default {
    name: "CreateFilter",

    data() {
        return {
            filter: {
                child_category_id: 0,
                filter_name: "",
            },
            childcategories: [],
        };
    },

    methods: {
        save() {
            this.saveData();
        },
        saveData() {
            let formData = new FormData();
            formData.append('child_category_id', this.filter.child_category_id);
            formData.append('filter_name', this.filter.filter_name);
            axios.post(`${BASE_URL}filter-store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/filter");
            });
        },
        fetchChildcategories() {
            axios
                .get(`${BASE_URL}filter-create`) // Update endpoint to fetch employees
                .then(response => {
                    this.childcategories = response.data;
                });
        },
    },
    mounted() {
        this.fetchChildcategories();
    },
};
</script>

<template>
  <div id="top"></div>
  <section class="main-header text-center">
    <header>
      <div class="container">
        <h1 class="h2 title">Product category</h1>
        <ol class="breadcrumb breadcrumb-inverted">
          <li>
            <a href="index.html"><span class="icon icon-home"></span></a>
          </li>
          <li>
            <a href="category.html">Product Category</a>
          </li>
          <li>
            <a href="products-grid.html">Product Sub-category</a>
          </li>
          <li>
            <a class="active" href="product.html">Product overview</a>
          </li>
        </ol>
      </div>
    </header>
  </section>

  <!-- ========================  Product ======================== -->

  <section class="product">

    <div class="toast" :class="{ 'show': showSuccessMessage, 'error': isError }">
      {{ message }}
    </div>

    <form @submit.prevent="save">
      <div class="main">
        <div class="container">
          <div class="row product-flex">
            <div class="col-md-4 col-sm-12 product-flex-info">
              <div class="clearfix">
                <!-- === product-title === -->

                <h1 class="title" data-title="">
                  {{ products.title }}
                </h1>

                <h5>SKU: {{ products.sku }}</h5>
                <div class="clearfix">
                  <!-- === price wrapper === -->
                  <div class="price" v-if="products">

                    <span class="h3" v-if="products.discount > 0">

                      $ {{ (products.sale_price - (products.sale_price * products.discount) / 100).toFixed(2) }}
                      <small v-if="products.sale_price">
                        $ {{ products.sale_price ? products.sale_price.toFixed(2) : "" }}
                      </small>

                    </span>

                    <span class="h3" v-else>
                      $ {{ products.sale_price ? products.sale_price.toFixed(2) : "" }}
                    </span>

                  </div>

                  <hr />

                  <!-- === info-box === -->

                  <div class="info-box">
                    <span><strong>Maifacturer</strong></span>
                    <span>{{ products.brand_name }}</span>
                  </div>

                  <div class="info-box" v-if="products.quantity > 0">
                    <span><strong>Availability</strong></span>
                    <span><i class="fa fa-check-square-o"></i> In stock</span>
                  </div>

                  <div class="info-box" v-else>
                    <span><strong>Availability</strong></span>
                    <span><i class="fa fa-truck"></i> Out of stock</span>
                  </div>

                  <hr />

                  <div class="info-box info-box-addto added">
                    <span>
                      <i class="add"><i class="fa fa-heart-o"></i> Add to favorites</i>
                      <i class="added"><i class="fa fa-heart"></i> Remove from favorites</i>
                    </span>
                  </div>
                  <i class="add"><i class="fa fa-heart-o"></i> Add to favorites</i>

                  <hr />

                  <!-- === info-box === -->
                  <div class="product-options">
                    <div v-if="colors.size > 0" class="info-box">
                      <span><strong>Available Colors: </strong></span>
                      <div class="product-colors clearfix">
                        <fieldset>
                          <div class="custom-radio" v-for="color in colors" :key="color && color.id">
                            <input type="radio" class="custom-control-input" :checked="selectedColor === color"
                              :id="color" name="colors" field="color" @click="changeColor('color_name', color)" />
                            <label class="custom-control-label" :for="color">{{ color }}</label>
                          </div>
                        </fieldset>
                      </div>
                    </div>

                    <div v-if="sizes.size > 0" class="info-box">
                      <span><strong>Choose size: </strong></span>
                      <div class="product-colors clearfix">
                        <fieldset>
                          <div class="custom-radio" v-for="size in sizes" :key="size && size.id">
                            <input type="radio" class="custom-control-input" :checked="selectedSize === size" :id="size"
                              :key="size" name="sizes" :value="size" @click="changeSize('size_name', size)" />
                            <label class="custom-control-label" :for="size">{{ size }}</label>
                          </div>
                        </fieldset>
                      </div>
                    </div>

                    <div v-if="attributes.size > 0" class="info-box">
                      <span><strong>{{ products.attribute }}: </strong></span>
                      <div class="product-colors clearfix">
                        <fieldset>
                          <div class="custom-radio" v-for="attribute in attributes" :key="attribute && attribute.id">
                            <input type="radio" class="custom-control-input" :checked="selectedAttribute === attribute"
                              :id="attribute" name="attributes" field="attribute"
                              @click="changeAttribute('attribut_type_name', attribute)" />
                            <label class="custom-control-label" :for="attribute">{{ attribute }}</label>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>

                </div>
                <!--/clearfix-->
              </div>
              <!--/product-info-wrapper-->
            </div>
            <!--/col-md-4-->
            <!-- === product item gallery === -->

            <div class="col-md-8 col-sm-12 product-flex-gallery">
              <!-- === add to cart === -->

              <button type="submit" class="btn btn-buy" data-text="Cart" v-if="products.quantity > 0"></button>

              <!-- === product gallery === -->

              <div class="owl-product-gallery open-popup-gallery">
                <img :src="'https://api.loop-delivery.com/product_detail/' + products.image
                  " alt="" height="500" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <!-- === product-info === -->

    <div class="info">
      <div class="container">
        <div class="row">
          <!-- === product-designer === -->

          <div class="col-md-9">
            <ul class="nav nav-tabs" role="tablist">
              <li role="presentation" class="active">
                <a href="#about" aria-controls="about" role="tab" data-toggle="tab">
                  <i class="icon icon-history"></i>
                  <span>About</span>
                </a>
              </li>
              <!-- <li role="presentation">
                <a
                  href="#rating"
                  aria-controls="rating"
                  role="tab"
                  data-toggle="tab"
                >
                  <i class="icon icon-thumbs-up"></i>
                  <span>Rating</span>
                </a>
              </li> -->
            </ul>

            <!-- === tab-panes === -->

            <div class="tab-content">
              <!-- ============ tab #1 ============ -->

              <div role="tabpanel" class="tab-pane active" id="about">
                <div class="content">
                  <h3>About this Item</h3>
                  <p v-html="products.details"></p>

                  <hr />
                </div>
                <!--/content-->
              </div>
              <!--/tab-pane-->

              <div role="tabpanel" class="tab-pane" id="rating">
                <!-- ============ ratings ============ -->

                <div class="content">
                  <h3>Rating</h3>

                  <div class="row">
                    <!-- === comments === -->

                    <div class="col-md-12">
                      <div class="comments">
                        <!-- === rating === -->
                        <div class="rating clearfix">
                          <div class="rate-box">
                            <strong>Quality</strong>
                            <div class="rating">
                              <i class="fa fa-star active"></i>
                              <i class="fa fa-star active"></i>
                              <i class="fa fa-star active"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <span>3</span>
                            </div>
                            <div class="rating">
                              <i class="fa fa-star active"></i>
                              <i class="fa fa-star active"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <span>5</span>
                            </div>
                            <div class="rating">
                              <i class="fa fa-star active"></i>
                              <i class="fa fa-star active"></i>
                              <i class="fa fa-star active"></i>
                              <i class="fa fa-star active"></i>
                              <i class="fa fa-star"></i>
                              <span>0</span>
                            </div>
                            <div class="rating">
                              <i class="fa fa-star active"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <span>2</span>
                            </div>
                            <div class="rating">
                              <i class="fa fa-star active"></i>
                              <i class="fa fa-star active"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="comment-wrapper">

                        
                          <div class="comment-block">
                            <div class="comment-user">
                              <div>
                                <img src="assets/images/user-2.jpg" alt="Alternate Text" width="70" />
                              </div>
                              <div>
                                <h5>
                                  <span>John Doe</span>
                                  <span class="pull-right">
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star"></i>
                                  </span>
                                  <small>03.05.2017</small>
                                </h5>
                              </div>
                            </div>


                            <div class="comment-desc">
                              <p>
                                In vestibulum tellus ut nunc accumsan eleifend.
                                Donec mattis cursus ligula, id iaculis dui
                                feugiat nec. Etiam ut ante sed neque lacinia
                                volutpat. Maecenas ultricies tempus nibh, sit
                                amet facilisis mauris vulputate in. Phasellus
                                tempor justo et mollis facilisis. Donec placerat
                                at nulla sed suscipit. Praesent accumsan, sem
                                sit amet euismod ullamcorper, justo sapien
                                cursus nisl, nec gravida
                              </p>
                            </div>


                            <div class="comment-block">
                              <div class="comment-user">
                                <div>
                                  <img src="assets/images/user-2.jpg" alt="Alternate Text" width="70" />
                                </div>
                                <div>
                                  <h5>
                                    Administrator
                                    <small>08.05.2017</small>
                                  </h5>
                                </div>
                              </div>
                              <div class="comment-desc">
                                <p>
                                  Curabitur sit amet elit quis tellus tincidunt
                                  efficitur. Cras lobortis id elit eu vehicula.
                                  Sed porttitor nulla vitae nisl varius luctus.
                                  Quisque a enim nisl. Maecenas facilisis, felis
                                  sed blandit scelerisque, sapien nisl egestas
                                  diam, nec blandit diam ipsum eget dolor.
                                  Maecenas ultricies tempus nibh, sit amet
                                  facilisis mauris vulputate in.
                                </p>
                              </div>
                            </div>
                          </div>


                          <div class="comment-block">
                            <div class="comment-user">
                              <div>
                                <img src="assets/images/user-2.jpg" alt="Alternate Text" width="70" />
                              </div>
                              <div>
                                <h5>
                                  <span>John Doe</span>
                                  <span class="pull-right">
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                  </span>
                                  <small>03.05.2017</small>
                                </h5>
                              </div>
                            </div>
                            <div class="comment-desc">
                              <p>
                                Cras lobortis id elit eu vehicula. Sed porttitor
                                nulla vitae nisl varius luctus. Quisque a enim
                                nisl. Maecenas facilisis, felis sed blandit
                                scelerisque, sapien nisl egestas diam, nec
                                blandit diam ipsum eget dolor. In vestibulum
                                tellus ut nunc accumsan eleifend. Donec mattis
                                cursus ligula, id iaculis dui feugiat nec. Etiam
                                ut ante sed neque lacinia volutpat. Maecenas
                                ultricies tempus nibh, sit amet facilisis mauris
                                vulputate in. Phasellus tempor justo et mollis
                                facilisis. Donec placerat at nulla sed suscipit.
                                Praesent accumsan, sem sit amet euismod
                                ullamcorper, justo sapien cursus nisl, nec
                                gravida
                              </p>
                            </div>
                          </div>
                        </div> -->
                        <!--/comment-wrapper-->

                        <div class="comment-header">
                          <a href="#" class="btn btn-clean-dark">12 comments</a>
                        </div>
                        <!--/comment-header-->
                        <!-- === add comment === -->

                        <div class="comment-add">
                          <div class="comment-reply-message">
                            <div class="h3 title">Leave a Reply</div>
                            <p>Your email address will not be published.</p>
                          </div>
                          <form action="#" method="post">
                            <div class="form-group">
                              <input type="text" class="form-control" name="name" value="" placeholder="Your Name" />
                            </div>
                            <div class="form-group">
                              <input type="text" class="form-control" name="name" value="" placeholder="Your Email" />
                            </div>
                            <div class="form-group">
                              <textarea rows="10" class="form-control" placeholder="Your comment"></textarea>
                            </div>
                            <div class="clearfix text-center">
                              <a href="#" class="btn btn-main">Add comment</a>
                            </div>
                          </form>
                        </div>
                        <!--/comment-add-->
                      </div>
                      <!--/comments-->
                    </div>
                  </div>
                  <!--/row-->
                </div>
                <!--/content-->
              </div>
              <!--/tab-pane-->
            </div>
            <!--/tab-content-->
          </div>
        </div>
        <!--/row-->
      </div>
      <!--/container-->
    </div>
    <!--/info-->
  </section>

  <!-- ========================  Best seller ======================== -->

  <section class="products">
    <!-- === header title === -->

    <header>
      <div class="row">
        <div class="col-md-offset-2 col-md-8 text-center">
          <h2 class="title">Related Products</h2>

        </div>
      </div>
    </header>

    <div class="row row-clean">
      <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
        <article>
          <div class="info">
            <span class="add-favorite">
              <a href="javascript:void(0);" data-title="Add to favorites" data-title-added="Added to favorites list"><i
                  class="icon icon-heart"></i></a>
            </span>
            <span>
              <a href="#productid1" class="mfp-open" data-title="Quick wiew"><i class="icon icon-eye"></i></a>
            </span>
          </div>
          <div class="btn btn-add">
            <i class="icon icon-cart"></i>
          </div>
          <div class="figure-grid">
            <span class="label label-danger">-50%</span>
            <div class="image">
              <a href="#productid1" class="mfp-open">
                <img src="assets/images/product-8.png" alt="" width="360" />
              </a>
            </div>
            <div class="text">
              <h2 class="title h5">
                <a href="product.html">Iron set</a>
              </h2>
              <sub>$ 1999,-</sub>
              <sup>$ 1499,-</sup>
              <span class="description clearfix">Gubergren amet dolor ea diam takimata consetetur facilisis
                blandit et aliquyam lorem ea duo labore diam sit et consetetur
                nulla</span>
            </div>
          </div>
        </article>
      </div>

      <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
        <article>
          <div class="info">
            <span class="add-favorite">
              <a href="javascript:void(0);" data-title="Add to favorites" data-title-added="Added to favorites list"><i
                  class="icon icon-heart"></i></a>
            </span>
            <span>
              <a href="#productid1" class="mfp-open" data-title="Quick wiew"><i class="icon icon-eye"></i></a>
            </span>
          </div>
          <div class="btn btn-add">
            <i class="icon icon-cart"></i>
          </div>
          <div class="figure-grid">
            <span class="label label-info">-20%</span>
            <div class="image">
              <a href="#productid1" class="mfp-open">
                <img src="assets/images/product-10.png" alt="" width="360" />
              </a>
            </div>
            <div class="text">
              <h2 class="title h5">
                <a href="product.html">Headphones Wireless</a>
              </h2>
              <sub>$ 1999,-</sub>
              <sup>$ 1499,-</sup>
              <span class="description clearfix">Gubergren amet dolor ea diam takimata consetetur facilisis
                blandit et aliquyam lorem ea duo labore diam sit et consetetur
                nulla</span>
            </div>
          </div>
        </article>
      </div>
    </div>
    <!--/row-->
    <!-- === button more === -->

  </section>

  <FooterLayout />
</template>

<script>
import FooterLayout from "./FooterLayout.vue";
import axios from "axios";
import { BASE_URL } from "../../../../config";
import { ref } from "vue";

const publicKey = ref(localStorage.getItem("name"));

export default {
  components: { FooterLayout },

  data() {
    return {
      selectedAttribute: null,
      products: [],
      allProducts: [],
      colors: new Set(),
      sizes: new Set(),
      brands: [],
      selectedColor: null,
      selectedSize: null,
      previousColorData: null,
      attributes: new Set(),
      publicKey,
      message: '',
      isError: false,
      showSuccessMessage: false,
      cart: {
        quantity: 1,
      },
      addToCart: false,
    };
  },

  created() {
    this.dataLoad();
  },

  watch: {
    "$route.params.id": function () {
      this.dataLoad();
    },
  },

  beforeRouteEnter(to, from, next) {
    if (to.query.addToCart) {
      next(vm => {
        vm.showSuccessMessage = true;
        setTimeout(() => {
          vm.showSuccessMessage = false;
        }, 5000);
      });
    } else {
      next();
    }
  },

  methods: {
    changeColor(key, val) {
      var newProd = this.allProducts.filter((prod) => prod.color_name === val);
      var size = [];
      var attribute = [];
      newProd.forEach(function (val) {
        if (val?.size_name)
          size.push(val?.size_name);
        if (val?.attribute_type_name)
          attribute.push(val?.attribute_type_name);
      });
      if (size)
        this.sizes = new Set(size);
      if (attribute)
        this.attributes = new Set(attribute);

      if (this.selectedColor === val) {
        this.products = this.previousColorData;
        this.selectedColor = null; // Clear the selection
        if (size)
          this.sizes = new Set(this.allProducts.map((prod) => prod?.size_name)); // Restore all sizes
        if (attribute)
          this.attributes = new Set(
            this.allProducts.map((prod) => prod?.attribute_type_name)
          ); // Restore all attributes
      } else {
        this.previousColorData = this.products;
        this.selectedColor = val;
        this.products = newProd[0];
      }
    },

    changeSize(key, val) {
      var newProd = this.allProducts.filter((prod) => prod.size_name === val);
      var color = [];
      var attribute = [];
      newProd.forEach(function (val) {
        if (val?.color_name)
          color.push(val.color_name);
        if (val?.attribute_type_name)
          attribute.push(val.attribute_type_name);
      });
      if (color)
        this.colors = new Set(color);
      if (attribute)
        this.attributes = new Set(attribute);

      if (this.selectedSize === val) {
        this.products = this.previousSizeData;
        this.selectedSize = null; // Clear the selection
        if (color)
          this.colors = new Set(this.allProducts.map((prod) => prod.color_name)); // Restore all colors
        if (attribute)
          this.attributes = new Set(
            this.allProducts.map((prod) => prod.attribute_type_name)
          ); // Restore all attributes
      } else {
        this.previousSizeData = this.products;
        this.selectedSize = val;
        this.products = newProd[0];
      }
    },

    changeAttribute(key, val) {
      var newProd = this.allProducts.filter(
        (prod) => prod.attribute_type_name === val
      );
      var color = [];
      var size = [];
      newProd.forEach(function (val) {
        if (val?.color_name)
          color.push(val.color_name);
        if (val?.size_name)
          size.push(val.size_name);
      });
      if (color)
        this.colors = new Set(color);
      if (size)
        this.sizes = new Set(size);

      if (this.selectedAttribute === val) {
        // Retrieve the data of the previously selected attribute
        this.products = this.previousAttributeData;
        this.selectedAttribute = null; // Clear the selection
        if (color)
          this.colors = new Set(this.allProducts.map((prod) => prod.color_name)); // Restore all colors
        if (size)
          this.sizes = new Set(this.allProducts.map((prod) => prod.size_name)); // Restore all sizes
      } else {
        // Store the data of the selected attribute as the previous attribute data
        this.previousAttributeData = this.products;
        this.selectedAttribute = val;
        this.products = newProd[0];
      }
    },

    dataLoad() {
      var page = `${BASE_URL}productdetail-view/${this.$route.params.id}`;
      axios.get(page).then(({ data }) => {
        this.products = data.product[0];
        this.allProducts = data.product;
        var pd = data.product;

        let color = [];
        pd.forEach(function (val) {
          if (val.color_name) color.push(val.color_name);
        });
        this.colors = new Set(color);

        let attribute = [];
        pd.forEach(function (val) {
          if (val.attribute_type_name) attribute.push(val.attribute_type_name);
        });
        this.attributes = new Set(attribute);

        let size = [];
        pd.forEach(function (val) {
          if (val.size_name) size.push(val.size_name);
        });
        this.sizes = new Set(size);
      });
    },

    save() {
      this.saveData();
    },

    saveData() {
      let formData = new FormData();
      formData.append("quantity", this.cart.quantity);
      formData.append("sale_price", this.products.sale_price);
      formData.append("brand_id", this.products.brand_id);
      formData.append("product_detail_id", this.products.product_detail_id); // set product_detail_id from products.id
      formData.append("user_id", localStorage.getItem("id"));
      axios
        .post(`${BASE_URL}cart-store`, formData)
        .then((response) => {
          if (response.status === 200) {
            this.showSuccessMessage = true;
            this.message = "Product has been added to cart successfully"; // Update success message
            this.isError = false; // Reset isError to false (no error)
          } else {
            this.message = "Failed to add to cart"; // Update error message
            this.isError = true; // Set isError to true (error)
            this.showSuccessMessage = true; // Show the toast message

          }
          this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
        })
        .catch((error) => {
          this.message = "Failed to add to cart"; // Update error message
          this.isError = true; // Set isError to true (error)
          this.showSuccessMessage = true; // Show the toast message
          console.error("Failed to add to cart", error);
          this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
        });
    },

    hideMessageAfterDelay(delay) {
      setTimeout(() => {
        this.showSuccessMessage = false; // Hide the toast message after the specified delay
      }, delay);
    },
  },
};
</script>

<style scoped>
.alert-danger {
  background-color: #dc143c;
  color: white;
}

/* Custom radio button style */
.custom-radio {
  display: inline-block;
  /* Set the container element to inline-block */
}

.custom-radio .custom-control-input {
  display: none;
  /* Hide the default radio button */
}

.custom-radio .custom-control-label {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ccc;
  margin: 5px;
  cursor: pointer;
  /* Add your custom styles here */
}

.custom-radio .custom-control-input:checked~.custom-control-label {
  background-color: #ccc;
  /* Add your custom styles here */
}

/* Additional styles to display product options inline */
.product-options {
  display: inline-block;
}

.info-box {
  display: inline-block;
  margin-right: 20px;
}

.info-box:last-child {
  margin-right: 0;
}

.toast {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #28a745;
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
  z-index: 999999;
}

.toast.error {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #dc3545;
  /* Background color for error */
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
  z-index: 999999;
}

.toast.show {
  right: 20px;
  /* Slide in to the desired position */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>

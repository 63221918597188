<template>
    <nav class="sidebar sidebar-offcanvas" id="sidebar">

        <ul class="nav">
            <li class="nav-item nav-profile">
                <a href="#" class="nav-link">

                    <div class="nav-profile-text d-flex flex-column">
                        <span class="font-weight-bold mb-2">{{publicKey}}</span>
                        <span class="text-secondary text-small">Affiliate</span>
                    </div>
                    <i class="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
                </a>
            </li>
            <li class="nav-item">
                <Link class="nav-link" href="/dashboard">Dashboard
                <!-- <span class="menu-title"></span>
<i class="mdi mdi-home menu-icon"></i> -->
                </Link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" to="/affiliate-user">
                    <span class="menu-title">Sub Affiliates</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/user-affiliate">
                    <span class="menu-title">Users</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/user-discount">
                    <span class="menu-title">User Discount</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/make-order">
                    <span class="menu-title">Make Order</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/affiliate-cart">
                    <span class="menu-title">Cart</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

        </ul>
    </nav>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
    data(){
        return {
            publicKey: localStorage.getItem("name"),
        }
    },  
    components: {
        RouterLink
    },
    // other component options
}

</script>

<style></style>

<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="editData" enctype="multipart/form-data">
                        <input type="hidden" v-model="filter.id" />

                        <input type="text" class="form-control" v-model="filter.filter_name" /><br /><br />

                        <select class="form-control mb-3" v-model="filter.child_category_id">
                            <option v-for="childcategories in childcategory" :key="childcategories.id" :value="childcategories.id">
                                {{ childcategories.child_category_name }}
                            </option>
                        </select>

                        <button type="submit" class="btn btn-success">Save</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axios from "axios";
import router from "@/router";
import { BASE_URL } from "../../../../../config";

export default {
    name: "EditFilter",

    data() {
        return {
            filter: {
                child_category_id: "",
                filter_name: "",
                id: this.$route.params.id,
            },
            childcategory: {}, // new data property to store the old employee data
            oldFilter: {},
        };
    },
    methods: {

        editData() {
            let formData = new FormData();
            if (this.filter.filter_name) {
                formData.append('filter_name', this.filter.filter_name);
            }
            if (this.filter.child_category_id) {
                formData.append('child_category_id', this.filter.child_category_id);
            }
            axios.post(`${BASE_URL}filter-update/${this.filter.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                this.filter = {
                    filter_name: "",
                    child_category_id: "",
                    id: "",
                };
                router.push("/filter");
            }).catch((error) => {
                console.log(error);
            });
        },
        fetchData() {
            axios
                .get(`${BASE_URL}filter-edit/` + this.filter.id)
                .then((response) => {
                    this.filter = response.data.filter;
                    this.childcategory = response.data.childcategory;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.fetchData(); // call the fetchData method when the component is mounted to fetch the old employee data
    },
};
</script>
<template>
  <head>
    <!-- Required meta tags -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <title>Gocami</title>
  </head>

  <body>
    <div class="wrapper">
      <TopbarLayout />
      
      <router-view />
    </div>
  </body>
</template>
  
<style scoped>
html {
  height: 100%;
}
</style>
  
<script>
import TopbarLayout from "../User/shared/TopbarLayout.vue"

export default {
  components: {  TopbarLayout  }

}
</script>
  
<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="editData" enctype="multipart/form-data">
                        <input type="hidden" v-model="subcategory.id" />

                        <input type="text" class="form-control" v-model="subcategory.sub_category_name" /><br /><br />

                        <select class="form-control mb-3" v-model="subcategory.category_id">
                            <option v-for="category in categories" :key="category.id" :value="category.id">
                                {{ category.category_name }}
                            </option>
                        </select>

                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />

                        <label>Banner SubCategory Image</label>
                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />


                        <button type="submit" class="btn btn-success">Save</button>
                    </form>
                    
                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axios from "axios";
import router from "@/router";
import { BASE_URL } from "../../../../../config";

export default {
    name: "EditSubCategory",

    data() {
        return {
            subcategory: {
                category_id: "",
                sub_category_name: "",
                sub_category_image: "",
                banner_sub_category_image: "",
                id: this.$route.params.id,
            },
            categories: {}, // new data property to store the old employee data
            oldSubCategory: {},
        };
    },
    methods: {
        onFileChange(event) {
            this.subcategory.sub_category_image = event.target.files[0];
            this.subcategory.banner_sub_category_image = event.target.files[0];
        },

        editData() {
            let formData = new FormData();
            if (this.subcategory.sub_category_name) {
                formData.append('sub_category_name', this.subcategory.sub_category_name);
            }
            if (this.subcategory.sub_category_image) {
                formData.append('sub_category_image', this.subcategory.sub_category_image);
            }
            if (this.subcategory.banner_sub_category_image) {
                formData.append('banner_sub_category_image', this.subcategory.banner_sub_category_image);
            }
            if (this.subcategory.category_id) {
                formData.append('category_id', this.subcategory.category_id);
            }
            axios.post(`${BASE_URL}subcategory-update/${this.subcategory.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                this.subcategory = {
                    sub_category_name: "",
                    category_id: "",
                    sub_category_image: "",
                    banner_sub_category_image: "",
                    id: "",
                };
                router.push("/subcategory");
            }).catch((error) => {
                console.log(error);
            });
        },
        fetchData() {
            axios
                .get(`${BASE_URL}subcategory-edit/` + this.subcategory.id)
                .then((response) => {
                    this.subcategory = response.data.subcategory;
                    this.categories = response.data.categories;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.fetchData(); // call the fetchData method when the component is mounted to fetch the old employee data
    },
};
</script>
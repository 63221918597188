<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Configurations Panel</h3>

                    <router-link to="/create-color" class="btn btn-primary">Create Product Configuration</router-link>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product Title</th>
                                <th>Product Sku</th>
                                <th>Product Color</th>
                                <th>Product Size</th>
                                <th>Product Attribute Name</th>
                                <th>Product Quantity</th>
                                <!-- <th>Action</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="productconf in productconfs" v-bind:key="productconf.id">
                                <th scope="row">{{ productconfs.id }}</th>
                                <td>{{ productconf.productdetail.title }}</td>
                                <td>{{ productconf.productdetail.sku }}</td>
                                <td>{{ productconf.color ? productconf.color.color_name : '' }}</td>
                                <td>{{ productconf.size ? productconf.size.size_name : '' }}</td>
                                <td>{{ productconf.attributetype ? productconf.attributetype.attribute_type_name : '' }}
                                </td>
                                <td>{{ productconf.quantity }}</td>
                                <td>
                                    <!-- <router-link :to="{ name: 'EditColor', params: { id: colors.id } }"
                    class="btn btn-warning me-2"><i class="fa fa-pencil"></i></router-link>

                  <button @click="remove(colors)" class="btn btn-danger"><i class="fa fa-trash"></i></button> -->

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
import axios from "axios";
import { BASE_URL } from "../../../../../config";

export default {
    data() {
        return {
            productconfs: [],
        };
    },
    created() {
        this.orderLoad();
    },
    methods: {
        orderLoad() {
            var page = `${BASE_URL}product-conf`;
            axios.get(page).then(({ data }) => {
                console.log(data);
                this.productconfs = data.productconf;
            });
        },
    },
}
</script>
<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Affiliate Discount</h3>

                    <router-link to="/create-affiliate-discount" class="btn btn-primary">Create Affiliate
                        Discount</router-link>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Discount Name</th>
                                <th>Commission</th>
                                <th>Affiliate Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="affiliatediscounts in affiliatediscount" v-bind:key="affiliatediscounts.id">
                                <th scope="row">{{ affiliatediscounts.id }}</th>
                                <td>{{ affiliatediscounts.discount.discount_name }}</td>
                                <td>{{ affiliatediscounts.discount.commission }}%</td>
                                <td>{{ affiliatediscounts.user.first_name }} {{ affiliatediscounts.user.last_name }}</td>
                                <td>
                                    <button @click="remove(affiliatediscounts)" class="btn btn-danger"><i
                                            class="fa fa-trash"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>
      
<script>
import axios from "axios";
import { BASE_URL } from "../../../../../config";

export default {
    name: "AffiliateDiscountView",

    data() {
        return {
            affiliatediscount: [],
        };
    },

    created() {
        this.affiliatediscountLoad();
    },

    methods: {
        affiliatediscountLoad() {
            var page = `${BASE_URL}affiliate-discount`;
            axios.get(page).then(({ data }) => {
                console.log(data);
                this.affiliatediscount = data;
            });
        },

        remove(affiliatediscounts) {
            var url = `${BASE_URL}affiliate-discount-delete/${affiliatediscounts.id}`;
            axios.delete(url).then(() => {
                const index = this.affiliatediscount.indexOf(affiliatediscounts);
                this.affiliatediscount.splice(index, 1);
            });
        },
    },
};

</script>
      
<style scoped></style>
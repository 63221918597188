<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save" enctype="multipart/form-data">
                        <input type="text" class="form-control" placeholder="Create Slider Url"
                            v-model="slider.slider_url" /><br /><br />
                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />

                        <progress v-if="slider.progress" :value="slider.progress.percentage" max="100">
                            {{ slider.progress.percentage }}%
                        </progress>

                        <button type="submit" class="btn btn-primary">Create Slider</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axios from "axios";
import router from "@/router";
import { BASE_URL } from "../../../../../config";

export default {
    name: "CreateSlider",

    data() {
        return {
            slider: {
                slider_url: "",
                slider_image: ""
            },
        };
    },

    methods: {
        onFileChange(event) {
            this.slider.slider_image = event.target.files[0];
        },

        save() {
            this.saveData();
        },
        saveData() {
            let formData = new FormData();
            formData.append('slider_url', this.slider.slider_url);
            formData.append('slider_image', this.slider.slider_image);
            axios.post(`${BASE_URL}slider-store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/slider");
            });
        },
    },
};
</script>
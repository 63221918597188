<template>
  <section class="products">
    <header class="hidden">
      <h3 class="h3 title">Product category grid</h3>
    </header>

    <div class="row row-clean">

      <!-- === product-filters === -->

      <div class="col-md-3 col-xs-12">
        <div class="filters">
          <div v-for="filter in filters" :key="filter.id">
            <!-- Price Start -->
            <h5 class="section-title position-relative text-uppercase mb-3">
              <span class="bg-secondary pr-3">
                {{ filter.filter_name.split("-")[1] }}
              </span>
            </h5>

            <div class="p-4 mb-30">
              <div class="custom-checkbox mb-2" v-for="productfilters in filteredProductfilter(filter.id)"
                :key="productfilters.id">
                <input type="checkbox" class="custom-control-input" :id="'product_filter_' + productfilters.id"
                  :value="productfilters.id" v-model="selectedProductFilters" />
                <label class="custom-control-label" :for="'product_filter_' + productfilters.id">
                  {{ productfilters.product_filter }}
                </label>
              </div>
            </div>
          </div>
        </div> <!--/filters-->
      </div>
      <!--product items-->

      <div class="col-md-9 col-xs-12">

        <div class="sort-bar clearfix">
          <div class="sort-results pull-left">
            <!--Showing result per page-->
            <select>
              <option value="1">10</option>
              <option value="2">50</option>
              <option value="3">100</option>
              <option value="4">All</option>
            </select>
            <!--Items counter-->
            <span>Showing all <strong>50</strong> of <strong>3,250</strong> items</span>
          </div>
          <!--Sort options-->
          <div class="sort-options pull-right">
            <span class="hidden-xs">Sort by</span>
            <select>
              <option value="1">Name</option>
              <option value="2">Popular items</option>
              <option value="3">Price: lowest</option>
              <option value="4">Price: highest</option>
            </select>
            <!--Grid-list view-->
            <span class="grid-list">
              <a href="products-grid.html"><i class="fa fa-th-large"></i></a>
              <a href="products-list.html"><i class="fa fa-align-justify"></i></a>
              <a href="javascript:void(0);" class="toggle-filters-mobile"><i class="fa fa-search"></i></a>
            </span>
          </div>
        </div>

        <div class="row row-clean">

          <div class="col-xs-6 col-sm-4 col-lg-3" v-for="products in filteredProducts" :key="products.productdetail.id">
            <article>
              <div class="info">
                <span class="add-favorite added">
                  <a href="javascript:void(0);" data-title="Add to favorites"
                    data-title-added="Added to favorites list"><i class="icon icon-heart"></i></a>
                </span>
                <span>
                  <router-link :to="{ name: 'ProductDetail', params: { id: products.productdetail.id } }" class="mfp-open" data-title="Quick wiew"><i class="icon icon-eye"></i></router-link>
                </span>
              </div>
              <div class="btn btn-add">
                <i class="icon icon-cart"></i>
              </div>
              <div class="figure-grid">
                <span class="label label-info" v-if="products.productdetail.discount > 0">-{{
                  products.productdetail.discount }}%</span>
                <div class="image">
                  <router-link :to="{ name: 'ProductDetail', params: { id: products.productdetail.id } }"
                    class="mfp-open">
                    <img :src="'https://api.loop-delivery.com/product_detail/' + products.productdetail.image
                      " alt="" width="360" />
                  </router-link>
                </div>
                <div class="text">
                  <h2 class="title h4">
                    <router-link :to="{ name: 'ProductDetail', params: { id: products.productdetail.id } }">{{
                      products.productdetail.title }}</router-link>
                  </h2>
                  <div v-if="products.productdetail.discount > 0">
                    <sub>$ {{ products.productdetail.sale_price.toFixed(2) }},-</sub>
                    <sup>$ {{ products.productdetail.sale_price - (products.productdetail.sale_price *
                      products.productdetail.discount / 100) }},-</sup>
                  </div>
                  <div v-else>
                    <sup>$ {{ products.productdetail.sale_price.toFixed(2) }},-</sup>
                  </div>
                  <span class="description clearfix">Gubergren amet dolor ea diam takimata consetetur facilisis blandit et
                    aliquyam lorem ea duo labore diam sit et consetetur nulla</span>
                </div>
              </div>
            </article>
          </div>

        </div><!--/row-->
        <!--Pagination-->
        <div class="pagination-wrapper">
          <ul class="pagination">
            <li>
              <a href="#" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li class="active"><a href="#">1</a></li>
            <li><a href="#">2</a></li>
            <li><a href="#">3</a></li>
            <li><a href="#">4</a></li>
            <li><a href="#">5</a></li>
            <li>
              <a href="#" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </div>

      </div> <!--/product items-->

    </div><!--/row-->

    <!-- ========================  Product info popup - quick view ======================== -->

  </section>
  <FooterLayout />
</template>
<script>
import FooterLayout from "./FooterLayout.vue";
import axios from "axios";
import { BASE_URL } from "../../../../config";

export default {
  components: { FooterLayout },
  methods: {
    dataLoad() {
      var page = `${BASE_URL}productlist-view/${this.$route.params.id}`;
      axios.get(page).then(({ data }) => {
        console.log(data);
        this.childcategories = data.childcategory;
        this.products = data.product;
        this.filters = data.filter;
        this.productfilters = data.productfilter;
        this.filterbyproducts = data.filterbyproduct;
      });
    },

    filteredProductfilter(filterId) {
      return this.productfilters.filter(
        (productfilters) => productfilters.filter_id === filterId
      );
    },
  },
  data() {
    return {
      selectedProductFilters: [],
      childcategories: [],
      products: [],
      filters: [],
      productfilters: [],
      filterbyproducts: [],
    };
  },

  created() {
    this.dataLoad();
  },

  computed: {
    filteredProducts() {
      if (this.selectedProductFilters.length === 0) {
        return this.products;
      } else {
        const filteredProductIds = this.filterbyproducts
          .filter((filterbyproducts) =>
            this.selectedProductFilters.includes(
              filterbyproducts.product_filter_id
            )
          )
          .map((filterbyproducts) => filterbyproducts.product_detail_id);
        return this.products.filter((products) =>
          filteredProductIds.includes(products.id)
        );
      }
    },
  },


};
</script>


<template>
  <!-- ======================== Navigation ======================== -->

  <nav>
    <div class="clearfix">

      <!-- ==========  Pre navigation ========== -->
      <div class="toast" :class="{ 'show': showSuccessMessage }">
        Registration successful! You can now log in.
      </div>

      <div class="navigation navigation-pre clearfix">
        <div class="row">
          <div class="col-md-3">
            <a href="#"><i class="icon icon-heart-pulse"></i> Help center</a>
          </div>
          <div class="col-md-3">
            <a href="#"><i class="icon icon-cart"></i> Shipping & Returns</a>
          </div>
          <div class="col-md-3">
            <a href="#"><i class="icon icon-cog"></i> Gift cards</a>
          </div>
          <div class="col-md-3">
            <a href="#"><i class="icon icon-map"></i> Store locator </a>
          </div>
        </div>
      </div>

      <!-- ==========  Top navigation ========== -->
      <div class="navigation navigation-top clearfix" v-if="publicKey">


        <ul v-if="role == '0'">

          <li class="left-side">
            <router-link to="/" class="logo-icon"><img :src="'https://api.loop-delivery.com/logo_image/' + logos.logo"
                alt="Alternate Text" /></router-link>
          </li>

          <li class="left-side">
            <router-link to="/allCategories">All</router-link>
          </li>

          <li class="left-side" v-for="category in categories" :key="category.id">
            <router-link :to="{ name: 'SubCategory', params: { id: category.id } }">{{ category.category_name }}
            </router-link>
          </li>

          <li>
            <router-link to="/" class="open-login"><i class="icon icon-user"></i></router-link>
          </li>

          <li>
            <router-link to="/addtocart-view" class="open-cart"><i class="icon icon-cart"></i>
              <span>4</span></router-link>
          </li>
        </ul>

        <ul v-if="role == '1'">
          <li class="left-side">
            <router-link to="/" class="logo-icon"><img :src="'https://api.loop-delivery.com/logo_image/' + logos.logo"
                alt="Alternate Text" /></router-link>
          </li>

          <li class="left-side">
            <router-link to="/allCategories">All</router-link>
          </li>

          <li class="left-side" v-for="category in categories" :key="category.id">
            <router-link :to="{ name: 'SubCategory', params: { id: category.id } }">{{ category.category_name }}
            </router-link>
          </li>

          <li>
            <router-link to="" class="open-login"><i class="icon icon-user"></i></router-link>
          </li>
          <li>
            <a href="javascript:void(0);" class="open-search"><i class="icon icon-magnifier"></i></a>
          </li>
          <li>
            <router-link to="/addtocart-view" class="open-cart"><i class="icon icon-cart"></i>
              <span>{{countcart}}</span></router-link>
          </li>
        </ul>

        <ul v-else-if="role == '2'">
          <li class="left-side">
            <a href="index.html" class="logo-icon"><img :src="'https://api.loop-delivery.com/logo_image/' + logos.logo"
                alt="Alternate Text" /></a>
          </li>

          <li class="left-side">
            <router-link to="/allCategories">All</router-link>
          </li>

          <li class="left-side" v-for="category in categories" :key="category.id">
            <router-link :to="{ name: 'SubCategory', params: { id: category.id } }">{{ category.category_name }}
            </router-link>
          </li>

          <li>
            <router-link href="javascript:void(0);" class="open-login"><i class="icon icon-user"></i></router-link>
          </li>
          <li>
            <a href="javascript:void(0);" class="open-search"><i class="icon icon-magnifier"></i></a>
          </li>
          <li>
            <router-link to="/addtocart-view" class="open-cart"><i class="icon icon-cart"></i>
              <span>4</span></router-link>
          </li>
        </ul>

        <ul v-else-if="role == '3'">
          <li class="left-side">
            <a href="index.html" class="logo-icon"><img :src="'https://api.loop-delivery.com/logo_image/' + logos.logo"
                alt="Alternate Text" /></a>
          </li>

          <li class="left-side">
            <router-link to="/allCategories">All</router-link>
          </li>

          <li class="left-side" v-for="category in categories" :key="category.id">
            <router-link :to="{ name: 'SubCategory', params: { id: category.id } }">{{ category.category_name }}
            </router-link>
          </li>

          <li>
            <router-link href="javascript:void(0);" class="open-login"><i class="icon icon-user"></i></router-link>
          </li>
          <li>
            <a href="javascript:void(0);" class="open-search"><i class="icon icon-magnifier"></i></a>
          </li>
          <li>
            <router-link to="/addtocart-view" class="open-cart"><i class="icon icon-cart"></i>
              <span>4</span></router-link>
          </li>
        </ul>

        <ul v-if="role == '4'">
          <li class="left-side">
            <router-link to="/" class="logo-icon"><img :src="'https://api.loop-delivery.com/logo_image/' + logos.logo"
                alt="Alternate Text" /></router-link>
          </li>

          <li class="left-side">
            <router-link to="/allCategories">All</router-link>
          </li>

          <li class="left-side" v-for="category in categories" :key="category.id">
            <router-link :to="{ name: 'SubCategory', params: { id: category.id } }">{{ category.category_name }}
            </router-link>
          </li>

          <li>
            <router-link to="" class="open-login"><i class="icon icon-user"></i></router-link>
          </li>
          <li>
            <a href="javascript:void(0);" class="open-search"><i class="icon icon-magnifier"></i></a>
          </li>
          <li>
            <router-link to="/addtocart-view" class="open-cart"><i class="icon icon-cart"></i>
              <span>4</span></router-link>
          </li>
        </ul>

      </div>

      <div class="navigation navigation-top clearfix" v-else>
        <ul>
          <!--add active class for current page-->
          <li class="left-side">
            <router-link to="/" class="logo-icon"><img :src="'https://api.loop-delivery.com/logo_image/' + logos.logo"
                alt="Alternate Text" /></router-link>
          </li>

          <li class="left-side">
            <router-link to="/allCategories">All</router-link>
          </li>

          <li class="left-side" v-for="category in categories" :key="category.id">
            <router-link :to="{ name: 'SubCategory', params: { id: category.id } }">{{ category.category_name }}
            </router-link>
          </li>

          <li>
            <router-link to="/addtocart-view" class="open-login"><i class="icon icon-user"></i></router-link>
          </li>
          <li>
            <a href="javascript:void(0);" class="open-search"><i class="icon icon-magnifier"></i></a>
          </li>
          <li>
            <router-link to="/addtocart-view" class="open-cart"><i class="icon icon-cart"></i>
              <span>4</span></router-link>
          </li>
        </ul>
      </div>

      <!-- ==========  Main navigation ========== -->

      <div class="navigation navigation-main">
        <a href="#" class="open-login"><i class="icon icon-user"></i></a>
        <a href="#" class="open-search"><i class="icon icon-magnifier"></i></a>
        <a href="#" class="open-cart"><i class="icon icon-cart"></i> <span>4</span></a>
        <a href="#" class="open-menu"><i class="icon icon-menu"></i></a>
        <div class="floating-menu">
          <!--mobile toggle menu trigger-->
          <div class="close-menu-wrapper">
            <span class="close-menu"><i class="icon icon-cross"></i></span>
          </div>

          <div class="container" style="margin-top: 1%">
            <input class="form-control" placeholder="Search..." v-model="keyword" @keyup="search" />
            <ul v-if="searches.length > 0">
              <li v-for="search in searches" :key="search.id">
                <router-link :to="{ name: 'ProductDetail', params: { id: search.id } }" @click="clearSearch">
                  <img v-lazy="'https://api.loop-delivery.com/product_detail/' + search.productdetail.image" width="40">
                  {{ search.product_name }}
                  <hr />
                </router-link>
              </li>
            </ul>
            <div v-else>
            </div>
          </div>

          <ul>
            <li>
              <router-link to="/">Home</router-link>
            </li>

            <li class="nav-settings" v-if="publicKey">
              <a href="javascript:void(0);"><span class="nav-settings-value"><i class="icon icon-user"></i> {{ publicKey
              }}</span>
                <span class="open-dropdown"><i class="fa fa-angle-down"></i></span></a>
              <div class="navbar-dropdown navbar-dropdown-single">
                <div class="navbar-box">
                  <div class="box-full">
                    <div class="box clearfix">
                      <ul class="nav-settings-list" v-if="role == '0'">
                        <li><router-link to="/user-profile" class="dropdown-item">My Profile</router-link></li>
                        <li><router-link to="/" class="dropdown-item" @click="logout">Logout</router-link></li>
                      </ul>
                      <ul class="nav-settings-list" v-else-if="role == '1'">
                        <li><router-link to="/user-profile" class="dropdown-item">My Profile</router-link></li>
                        <li><router-link to="/admin" class="dropdown-item">Admin Panel</router-link></li>
                        <li><router-link to="/" class="dropdown-item" @click="logout">Logout</router-link></li>
                      </ul>
                      <ul class="nav-settings-list" v-if="role == '2'">
                        <li><router-link to="/user-profile" class="dropdown-item">My Profile</router-link></li>
                        <li><router-link to="/sub-admin" class="dropdown-item">Admin Panel</router-link></li>
                        <li><router-link to="/" class="dropdown-item" @click="logout">Logout</router-link></li>
                      </ul>
                      <ul class="nav-settings-list" v-if="role == '3'">
                        <li><router-link to="/user-profile" class="dropdown-item">My Profile</router-link></li>
                        <li><router-link to="/prod-admin" class="dropdown-item">Admin Panel</router-link></li>
                        <li><router-link to="/" class="dropdown-item" @click="logout">Logout</router-link></li>
                      </ul>
                      <ul class="nav-settings-list" v-if="role == '4'">
                        <li><router-link to="/user-profile" class="dropdown-item">My Profile</router-link></li>
                        <li><router-link to="/affiliate" class="dropdown-item">Affiliate Panel</router-link></li>
                        <li><router-link to="/" class="dropdown-item" @click="logout">Logout</router-link></li>
                      </ul>
                      <ul class="nav-settings-list" v-if="role == '5'">
                        <li><router-link to="/user-profile" class="dropdown-item">My Profile</router-link></li>
                        <li><router-link to="/sub-affiliate" class="dropdown-item">Affiliate Panel</router-link></li>
                        <li><router-link to="/" class="dropdown-item" @click="logout">Logout</router-link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="nav-settings" v-else>
              <a href="javascript:void(0);"><span class="nav-settings-value"><i class="icon icon-user"></i> {{ publicKey
              }}</span>
                <span class="open-dropdown"><i class="fa fa-angle-down"></i></span></a>
              <div class="navbar-dropdown navbar-dropdown-single">
                <div class="navbar-box">
                  <div class="box-full">
                    <div class="box clearfix">
                      <ul class="nav-settings-list">
                        <li><router-link to="/login" class="dropdown-item">Login</router-link></li>
                        <li><router-link to="/register" class="dropdown-item">Register</router-link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </nav>

  <!-- ========================  Header content ======================== -->
</template>
  
<script>
import axios from "axios";
import { BASE_URL } from "../../../../config";
import router from "@/router";

const clearLocalStorage = () => {
  localStorage.clear();
  router.push("/");
};

const timeoutDuration = 3600000;
var timeout = setTimeout(clearLocalStorage, timeoutDuration);

document.addEventListener("mousemove", () => {
  clearTimeout(timeout);
  timeout = setTimeout(clearLocalStorage, timeoutDuration);
});

export default {
  data() {
    return {
      countcart: 0,
      keyword: '',
      searches: '',
      categories: [],
      logos: [],
      childcategories: [],
      loading: true,
      publicKey: localStorage.getItem("name"),
      loginSuccess: false, // Control the visibility of the success message

    };
  },

  beforeRouteEnter(to, from, next) {
    if (to.query.loginSuccess) {
      next(vm => {
        vm.showSuccessMessage = true;
        setTimeout(() => {
          vm.showSuccessMessage = false;
        }, 5000);
      });
    } else {
      next();
    }
  },

  created() {
    this.categoryLoad();
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    clearSearch() {
      this.keyword = '';
      this.searches = [];
    },

    fetchData() {
      axios
        .get(`${BASE_URL}user-get?user_id=${localStorage.getItem("id")}`)
        .then(() => {
        })
        .catch((error) => {
          console.log(error);
        });
    },

    search() {
      if (this.keyword === '') {
        this.searches = [];
      } else {
        axios
          .get(`${BASE_URL}products/search`, { params: { keyword: this.keyword } })
          .then((response) => {
            this.searches = response.data;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },

    fetchAffiliateUserDiscount() {
      axios
        .get(
          `${BASE_URL}user-discount-create?user_id=${localStorage.getItem(
            "id"
          )}`
        )
        .then((response) => {
          this.users = response.data.user;
          this.affiliatediscounts = response.data.affiliatediscount;
        });
    },

    categoryLoad() {
      var page = `${BASE_URL}user-view?user_id=${localStorage.getItem("id")}`;
      this.loading = true;
      axios.get(page).then(({ data }) => {
        this.categories = data.category;
        this.childcategories = data.childcategory;
        this.logos = data.logo;
        this.countcart = data.countCarts;
        this.loading = false;
      });
    },

    logout() {
      localStorage.clear();
      this.publicKey = null; // Clear the publicKey property
      router.replace("/");
    }
  },

  computed: {
    role() {
      return localStorage.getItem("role");
    },

    isSearchEmpty() {
      return this.keyword === '';
    },

  },
};
</script>

<style scoped>
.toast {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #28a745;
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
}

.toast.show {
  right: 20px;
  /* Slide in to the desired position */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
  
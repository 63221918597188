<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Sale Panel</h3>

                    <router-link to="/product-create-sale" class="btn btn-primary">Create Product Sale</router-link>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Sale Name</th>
                                <th>Product Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="productsales in productsale" v-bind:key="productsales.id">
                                <th scope="row">{{ productsales.id }}</th>
                                <td>{{ productsales.sale.child_category_id }}</td>
                                <td>{{ productsales.productdetail.sku }}</td>
                                <td>
                                    <router-link :to="{ name: 'EditProductSale', params: { id: productsales.id } }"
                                        class="btn btn-warning me-2"><i class="fa fa-pencil"></i></router-link>

                                    <button @click="remove(sales)" class="btn btn-danger"><i
                                            class="fa fa-trash"></i></button>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>
      
<script>
import axios from "axios";
import { BASE_URL } from "../../../../../config";

export default {
    name: "ProductSale",

    data() {
        return {
            productsale: [],
        };
    },

    created() {
        this.productsaleLoad();
    },

    methods: {
        productsaleLoad() {
            var page = `${BASE_URL}product-sale`;
            axios.get(page).then(({ data }) => {
                console.log(data);
                this.productsale = data;
            });
        },

        remove(productsales) {
            var url = `${BASE_URL}product-sale-delete/${productsales.id}`;
            axios.delete(url).then(() => {
                const index = this.productsale.indexOf(productsales);
                this.productsale.splice(index, 1);
            });
        },
    },
};

</script>
      
<style scoped></style> 

<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Orders Panel</h3>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Image</th>
                                <th>Sku</th>
                                <th>Title</th>
                                <th>Quantity</th>
                                <!-- <th>Action</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="viewOrders in vieworder" :key="viewOrders.id">
                                <th scope="row">{{ viewOrders.id }}</th>
                                <th scope="row"><img
                                        :src="'https://api.loop-delivery.com/product_detail/' + viewOrders.productdetail.image" />
                                </th>
                                <th scope="row">{{ viewOrders.productdetail.sku }}</th>
                                <th scope="row">{{ viewOrders.productdetail.title }}</th>
                                <th scope="row">{{ viewOrders.quantity }}</th>
                                <th scope="row"><input class="custom-checkbox" type="checkbox"
                                        v-model="viewOrders.selected" /></th>
                            </tr>
                        </tbody>

                        <button @click="submitRefunds">Submit</button>
                    </table>

                </div>
            </div>
        </div>

    </div>
</template>
      
<script>
import axios from "axios";
import { BASE_URL } from "../../../../../config";

export default {
    data() {
        return {
            vieworder: [],
        };
    },

    created() {
        this.orderLoad();
    },

    methods: {
        orderLoad() {
            var page = `${BASE_URL}view-order/${this.$route.params.id}`;
            axios.get(page).then(({ data }) => {
                console.log(data);
                this.vieworder = data.vieworder.map(item => ({
                    ...item,
                    selected: false // Add a 'selected' property to each item
                }));
            });
        },
        submitRefunds() {
            const selectedItems = this.vieworder.filter(item => item.selected);

            if (selectedItems.length === 0) {
                // No items selected, handle accordingly (show an error, etc.)
                return;
            }

            const refundData = {
                cart_id: selectedItems.map(item => item.id),
                quantity: selectedItems.map(item => item.quantity),
            };

            refundData.cart_id = Array.isArray(refundData.cart_id) ? refundData.cart_id : [refundData.cart_id];
            refundData.quantity = Array.isArray(refundData.quantity) ? refundData.quantity : [refundData.quantity];

            axios.post(`${BASE_URL}insert-order`, refundData)
                .then(response => {
                    // Handle the response
                    console.log(response.data);
                })
                .catch(error => {
                    // Handle the error
                    console.error(error);
                });
        },
    },
};
</script>
<style scoped>
.custom-checkbox {
    /* Add your custom styling properties here */
    /* Example properties */
    width: 20px;
    height: 20px;
    background-color: #ddd;
}
</style>
<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="editData">
                        <input type="hidden" v-model="user.id" />
                        <input type="text" class="form-control mb-2" v-model="user.first_name" />
                        <input type="text" class="form-control mb-2" v-model="user.last_name" />
                        <input type="email" class="form-control mb-2" v-model="user.email" />
                        <input type="password" class="form-control mb-2" v-model="user.password" />

                        <select v-model="user.roles" class="form-control">
                            <option value="0">User</option>
                            <option value="1">Admin</option>
                            <option value="2">Product Admin</option>
                            <option value="3">Order Admin</option>
                            <option value="4">Affiliate</option>
                            <option value="5">Sub Affiliate</option>
                        </select>

                        <button type="submit" class="btn btn-success">Save</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axios from "axios";
import router from "@/router";
import { BASE_URL } from "../../../../../config";

export default {
    name: "EditAdminUser",

    data() {
        return {
            user: {
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                roles: "",
                id: this.$route.params.id,
            },
            oldUser: {}, // new data property to store the old employee data
        };
    },
    methods: {
        editData() {
            axios
                .post(
                    `${BASE_URL}update-admin-user/${this.user.id}`, // Use the BASE_URL constant for the base URL
                    this.user
                )
                .then(() => {
                    this.user = {
                        first_name: "",
                        last_name: "",
                        email: "",
                        password: "",
                        roles: "",
                        id: "",
                    };
                    router.push("/admin-user");
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchData() {
            axios
                .get(`${BASE_URL}edit-admin-user/` + this.user.id)
                .then((response) => {
                    this.oldUser = response.data; // store the old employee data in oldEmployee property
                    this.user.first_name = this.oldUser.first_name; // set the old employee_name value to the input
                    this.user.last_name = this.oldUser.last_name; // set the old employee_name value to the input
                    this.user.email = this.oldUser.email; // set the old employee_name value to the input
                    this.user.password = this.oldUser.password; // set the old employee_name value to the input
                    this.user.roles = this.oldUser.roles; // set the old employee_name value to the input
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.fetchData(); // call the fetchData method when the component is mounted to fetch the old employee data
    },
};
</script>
<template>
    <section class="blog blog-block">

        <div class="row row-clean">

            <!-- === blog item === -->

            <div class="col-sm-12" v-for="sub in subcategories" :key="sub.id">
                <article>
                    <div>
                        <div class="image">
                            <img :src="'https://api.loop-delivery.com/banner_sub_category_image/' + sub.banner_sub_category_image"
                                alt="" style="height:30vh" />
                        </div>
                        <div class="entry entry-block">
                            <div class="title">
                                <h2 class="h3">{{ sub.sub_category_name }}</h2>
                            </div>
                            <div class="description">
                                <div class="image-container">
                                    <div class="image-section" v-for="childcategory in sub.childcategories"
                                        :key="childcategory.id">
                                        <router-link :to="{ name: 'ChildCategory', params: { id: childcategory.id } }">
                                            <img :src="'https://api.loop-delivery.com/child_category_image/' + childcategory.child_category_image"
                                                alt="" />
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <hr />

                <section class="products">
                    <div class="row row-clean">
                        <!-- === product-item === -->
                        <div class="col-xs-6 col-sm-4 col-lg-2" v-for="productdetail in sub.productdetail" :key="productdetail.id">
                            <div class="product-item">
                                <article>
                                    <div class="info">
                                        <span class="add-favorite">
                                            <a href="javascript:void(0);" data-title="Add to favorites"
                                                data-title-added="Added to favorites list"><i
                                                    class="icon icon-heart"></i></a>
                                        </span>
                                        <span>
                                            <a href="#productid1" class="mfp-open" data-title="Quick wiew"><i
                                                    class="icon icon-eye"></i></a>
                                        </span>
                                    </div>
                                    <div class="btn btn-add">
                                        <i class="icon icon-cart"></i>
                                    </div>
                                    <div class="figure-grid">
                                        <span class="label label-danger" v-if="productdetail.discount > 50">-{{
                                            productdetail.discount }}%</span>
                                        <span class="label label-info" v-else-if="productdetail.discount < 50 && productdetail.discount > 0">-{{ productdetail.discount }}%</span>

                                        <div class="image">
                                            <router-link
                                                :to="{ name: 'ProductDetail', params: { id: productdetail.product_id } }"
                                                class="mfp-open">
                                                <img :src="'https://api.loop-delivery.com/product_detail/' + productdetail.image"
                                                    alt="" width="360" class="product-image" />
                                            </router-link>
                                        </div>
                                        <div class="text" v-if="productdetail.discount > 0">
                                            <h2 class="title h5">
                                                <a href="product.html">{{ productdetail.title }}</a>
                                            </h2>
                                            <sub>$ {{ productdetail.sale_price.toFixed(2) }},</sub>
                                            <sup>$ {{ (productdetail.sale_price - (productdetail.sale_price
                                                * productdetail.discount / 100)).toFixed(2) }},</sup>
                                        </div>

                                        <div class="text" v-else>
                                            <h2 class="title h5">
                                                <a href="product.html">{{ productdetail.title }}</a>
                                            </h2>
                                            <sup>$ {{ productdetail.sale_price.toFixed(2) }},</sup>
                                        </div>

                                    </div>
                                </article>
                            </div>
                        </div>

                    </div>

                </section>
                <hr />
            </div>
        </div>
    </section>

    <hr />
    <!-- <ul class="col">
                <li>
                    <a href="#">New arrivals</a>
                </li>
                <li>
                    <a href="#">Doscount sale collection</a>
                </li>
                <li class="more">
                    <a href="#">More</a>
                </li>
            </ul> -->
    <!-- === blog item === -->

    <!--/row-->


    <FooterLayout />
</template>


<script>
import FooterLayout from "./FooterLayout.vue";
import axios from "axios";
import { BASE_URL } from "../../../../config";

export default {
    components: { FooterLayout },
    name: "SubCategory",

    data() {
        return {
            subcategories: [],
        };
    },

    created() {
        this.dataLoad();
    },

    watch: {
        '$route.params.id': function () {
            this.dataLoad();
        }
    },

    methods: {
        dataLoad() {
            const page = `${BASE_URL}subcategory-view/${this.$route.params.id}`;
            axios.get(page).then(({ data }) => {
                this.subcategories = data;
                console.log(data);
            });
        },
    },

};
</script>

<style scoped>
.image-container {
    display: flex;
    justify-content: space-between;
}

.image-section {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
}

.image-section:hover {
    transform: scale(1.1);
}

.image-section img {
    max-width: 100%;
    height: auto;
}

.product-item {
    height: 400px;
    /* Adjust the height as per your requirement */
    overflow: hidden;
}

.product-image {
    height: 200px;
    /* Adjust the height as per your requirement */
    object-fit: cover;
    /* or object-fit: contain; */
}
</style>
<template>
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="card">
          <div class="card-body">
            <div class="alert" :class="[isError ? 'alert-danger' : 'alert-success']" role="alert" v-if="message">
              {{ message }}
            </div>
  
            <form @submit.prevent="save">
              <input type="text" class="form-control" placeholder="Create Product" v-model="product.product_name" /><br /><br />
              <button type="submit" class="btn btn-success">Create</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <style scoped>
  .alert {
    margin-bottom: 10px;
  }
  .alert-success {
    background-color: #dff0d8;
    color: #3c763d;
    border-color: #d6e9c6;
  }
  .alert-danger {
    background-color: #f2dede;
    color: #a94442;
    border-color: #ebccd1;
  }
  </style>
  
  <script>
  import axios from "axios";
  import router from "@/router";
  import { BASE_URL } from "../../../../../config";
  
  export default {
    name: "CreateProduct",
  
    data() {
      return {
        product: {
          product_name: "",
        },
        message: "", // Added message variable
        isError: false, // Added isError variable
      };
    },
  
    methods: {
      save() {
        this.saveData();
      },
  
      saveData() {
        axios.post(`${BASE_URL}product-store`, this.product)
          .then(response => {
            if (response.status === 200) {
              this.message = 'Product has been added successfully'; // Update success message
              this.isError = false; // Reset isError to false
              router.push("/create-product");
            } else {
              this.message = 'Failed to add product'; // Update error message
              this.isError = true; // Set isError to true
            }
            this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
          })
          .catch(error => {
            this.message = 'Failed to add product'; // Update error message
            this.isError = true; // Set isError to true
            console.error('Failed to add product', error);
            this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
          });
      },
  
      hideMessageAfterDelay(delay) {
        setTimeout(() => {
          this.message = ''; // Clear the message
          this.isError = false; // Reset isError to false
        }, delay);
      },
    },
  };
  </script>
<template>
    <!-- ========================  Best seller ======================== -->
    <section class="header-content">
        <div class="clearifx">
            <splide>
                <splide-slide v-for="slider in sliders" :key="slider.id">
                    <div class="slide-container">
                        <img :src="'https://api.loop-delivery.com/slider_image/' + slider.slider_image"
                            style="width: 100%; height: 50vh;">
                        <router-link :to="slider.slider_url" class="middle-button btn btn-success">Click Me</router-link>
                    </div>
                </splide-slide>
            </splide>
            <!-- <div class="owl-slider" >
                <div class="item">
                    <div class="box box-left">
                        <div class="text-right">
                            <h2 class="title animated h1" data-animation="fadeInDown">eCommerce template</h2>
                            <div class="animated" data-animation="fadeInUp">
                                Score new arrivals from latest electronics
                                <br />
                                Multipurpose Ecommerce template ready
                            </div>
                            <div class="animated" data-animation="fadeInUp">
                                <a href="#" target="_blank" class="btn btn-clean"><i class="icon icon-cart"></i> Buy</a>
                                <a href="#" target="_blank" class="btn btn-clean"><i class="icon icon-eye"></i> Details</a>
                            </div>
                        </div>
                    </div>
                    <img :src="'http://localhost:8000/category_image/' + category.category_image" alt="Category Image" />
                     Replace `path_to_folder` with the actual path to the folder containing the category images 
                </div>
            </div> -->
        </div>
    </section>

    <!-- ========================  Blog Block ======================== -->

    <section class="blog blog-block blog-intro">

        <div class="container">

            <!-- === blog header === -->

            <header class="hidden">
                <div class="row">
                    <div class="col-md-offset-2 col-md-8 text-center">
                        <h2 class="title">Check out our featured news</h2>
                        <div class="text">
                            <p>Keeping things minimal</p>
                        </div>
                    </div>
                </div>
            </header>

            <div class="row row-clean">

                <!-- === blog item === -->

                <div class="col-xs-6 col-sm-3" style="padding-left: 10px;" v-for="randomSubcategories in randomSubcategoriess"
                    :key="randomSubcategories.id">
                    <div class="product-item">
                        <article>
                            <router-link :to="{ name: 'SubCategory', params: { id: randomSubcategories.category_id } }">
                                <div class="image">
                                    <img v-lazy="'https://api.loop-delivery.com/sub_category_image/' + randomSubcategories.sub_category_image"
                                        alt="" class="product-image-category" />
                                </div>
                                <div class="entry entry-block">
                                    <div class="title">
                                        <h2 class="h3">{{ randomSubcategories.sub_category_name }}</h2>
                                    </div>
                                </div>
                                <div class="show-more">
                                    <span class="btn btn-clean">Shop now</span>
                                </div>
                            </router-link>
                        </article>
                    </div>
                </div>

            </div> <!--/row-->

            <!-- === button more === -->

            <div class="wrapper-more">
                <router-link to="/allCategories" class="btn btn-lg">View all categories</router-link>
            </div>

        </div> <!--/container-->
    </section>

    <!-- <section class="blog blog-block">
         === header === 
        <header class="hidden">
            <div class="text-center">
                <h2 class="title">Fashion</h2>
            </div>
        </header>

        <div class="row row-clean">
            === blog item === 
            <div class="col-sm-4 pt-5" v-for="category in categories" :key="category.id">
                <article>
                    <div>
                        <div class="image">
                            <router-link to=""><img :src="'http://localhost:8000/category_image/' + category.category_image"
                                    alt="" /></router-link>
                        </div>
                        <div class="entry entry-block">
                            <div class="title">
                                <h2 class="h3">{{ category.category_name }}</h2>
                            </div>
                            <div class="description">
                                <ul>
                                    <li v-for="subcategory in getSubcategories(category.id)" :key="subcategory.id">
                                        <router-link :to="{ name: 'ChildCategory', params: { id: subcategory.id } }">{{
                                            subcategory.sub_category_name }}</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </section> -->


    <section class="products">

        <!-- === header title === -->

        <header>
            <div class="row">
                <div class="col-md-offset-2 col-md-8 text-center">
                    <h2 class="title">Products on discount</h2>
                    <div class="text">
                        <p>Check out our latest collections</p>
                    </div>
                </div>
            </div>
        </header>

        <div class="row row-clean">
            <!-- === product-item === -->
            <div class="col-xs-6 col-sm-4 col-lg-2" v-for="productdiscount in productdiscounts" :key="productdiscount.id">
                <div class="product-item">
                    <article>
                        <div class="info">
                            <span class="add-favorite">
                                <a href="javascript:void(0);" data-title="Add to favorites"
                                    data-title-added="Added to favorites list"><i class="icon icon-heart"></i></a>
                            </span>
                            <span>
                                <a href="#productid1" class="mfp-open" data-title="Quick wiew"><i
                                        class="icon icon-eye"></i></a>
                            </span>
                        </div>
                        <div class="btn btn-add">
                            <i class="icon icon-cart"></i>
                        </div>
                        <div class="figure-grid">
                            <span class="label label-danger" v-if="productdiscount.productdetail.discount > 50">-{{
                                (productdiscount.productdetail.sale_price - (productdiscount.productdetail.sale_price *
                                    productdiscount.productdetail.discount / 100)).toFixed(2) }}%</span>
                            <span class="label label-info" v-else>-{{ productdiscount.productdetail.discount }}%</span>
                            <div class="image">
                                <router-link
                                    :to="{ name: 'ProductDetail', params: { id: productdiscount.productdetail.product_id } }"
                                    class="mfp-open">
                                    <img v-lazy="'https://api.loop-delivery.com/product_detail/' + productdiscount.productdetail.image"
                                        alt="" width="360" class="product-image" />
                                </router-link>
                            </div>
                            <div class="text">
                                <h2 class="title h5">
                                    <router-link
                                        :to="{ name: 'ProductDetail', params: { id: productdiscount.productdetail.product_id } }">{{
                                            productdiscount.productdetail.title }}</router-link>
                                </h2>
                                <sub>$ {{ productdiscount.productdetail.sale_price.toFixed(2) }},-</sub>
                                <sup>$ {{ (productdiscount.productdetail.sale_price -
                                    (productdiscount.productdetail.sale_price
                                        * productdiscount.productdetail.discount / 100)).toFixed(2) }},-</sup>
                            </div>
                        </div>
                    </article>
                </div>

            </div>

        </div> <!--/row-->
        <!-- === button more === -->

        <div class="wrapper-more">
            <a href="products-grid.html" class="btn btn-lg">View all</a>
        </div>

        <!-- ========================  Product info popup - quick view ======================== -->

        <div class="popup-main mfp-hide" id="productid1">

            <!-- === product popup === -->

            <div class="product">

                <!-- === popup-title === -->

                <div class="popup-title">
                    <div class="h1 title">
                        Headphones Wireless
                        <small>product category</small>
                    </div>
                </div>

                <!-- === product gallery === -->

                <!-- <div class="owl-product-gallery">
                    <img src="user_assets/assets/images/product-10.png" alt="" width="640" />
                    <img src="user_assets/assets/images/product-10a.png" alt="" width="640" />
                </div> -->

                <!-- === product-popup-info === -->

                <div class="popup-content">
                    <div class="product-info-wrapper">
                        <div class="row">

                            <!-- === left-column === -->

                            <div class="col-sm-6">
                                <div class="info-box">
                                    <strong>Maifacturer</strong>
                                    <span>Brand name</span>
                                </div>
                                <div class="info-box">
                                    <strong>Materials</strong>
                                    <span>Wood, Leather, Acrylic</span>
                                </div>
                                <div class="info-box">
                                    <strong>Availability</strong>
                                    <span><i class="fa fa-check-square-o"></i> in stock</span>
                                </div>
                            </div>

                            <!-- === right-column === -->

                            <div class="col-sm-6">
                                <div class="info-box">
                                    <strong>Available Colors</strong>
                                    <div class="product-colors clearfix">
                                        <span class="color-btn color-btn-red"></span>
                                        <span class="color-btn color-btn-blue checked"></span>
                                        <span class="color-btn color-btn-green"></span>
                                        <span class="color-btn color-btn-gray"></span>
                                        <span class="color-btn color-btn-biege"></span>
                                    </div>
                                </div>
                                <div class="info-box">
                                    <strong>Choose size</strong>
                                    <div class="product-colors clearfix">
                                        <span class="color-btn color-btn-biege">S</span>
                                        <span class="color-btn color-btn-biege checked">M</span>
                                        <span class="color-btn color-btn-biege">XL</span>
                                        <span class="color-btn color-btn-biege">XXL</span>
                                    </div>
                                </div>
                            </div>

                        </div> <!--/row-->
                    </div> <!--/product-info-wrapper-->
                </div> <!--/popup-content-->
                <!-- === product-popup-footer === -->

                <div class="popup-table">
                    <div class="popup-cell">
                        <div class="price">
                            <span class="h3">$ 1999,00 <small>$ 2999,00</small></span>
                        </div>
                    </div>
                    <div class="popup-cell">
                        <div class="popup-buttons">
                            <a href="product.html"><span class="icon icon-eye"></span> <span class="hidden-xs">View
                                    more</span></a>
                            <a href="javascript:void(0);"><span class="icon icon-cart"></span> <span
                                    class="hidden-xs">Buy</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div> <!--/product-->

    </section>

    <section class="blog blog-block">
        <div class="row row-clean">
            <!-- <div class="col-sm-12" v-for="timerSetting in timerSettings" :key="timerSetting.id">
                <article>
                    <div class="image-container">
                        <img :src="'http://localhost:8000/sale/' + timerSetting.sale_image" alt="" class="sale-image" />
                        <div class="time-container">{{ formatTime(timerSetting.remainingTime) }}</div>
                    </div>
                </article>
                <hr />
            </div> -->

            <div v-for="sale in sales" :key="sale.id">
                <router-link :to="{ name: 'ProductSaleView', params: { id: sale.id } }">
                    <div class="sale-item">
                        <img v-lazy="'https://api.loop-delivery.com/sale/' + sale.sale_image" alt="Sale Image">
                        <div class="countdown-container">
                            <div class="countdown">
                                <span class="countdown-label">Sale Ends In:</span>
                                <span class="countdown-timer">{{ sale.countdown }}</span>
                            </div>
                        </div>
                    </div>
                </router-link>

                <section class="products" style="margin-top: 2%; margin-bottom: 3%">
                    <div class="row row-clean">
                        <div class="col-xs-6 col-sm-4 col-lg-2" v-for="product in sale.productsales" :key="product.id">
                            <div class="product-item">
                                <article>
                                    <div class="info">
                                        <span class="add-favorite">
                                            <a href="javascript:void(0);" data-title="Add to favorites"
                                                data-title-added="Added to favorites list"><i
                                                    class="icon icon-heart"></i></a>
                                        </span>

                                    </div>
                                    <div class="figure-grid">
                                        <div class="image">
                                            <router-link
                                                :to="{ name: 'ProductDetail', params: { id: product.productdetail.product_id } }"
                                                class="mfp-open">
                                                <img v-lazy="'https://api.loop-delivery.com/product_detail/' + product.productdetail.image"
                                                    alt="" width="360" class="product-image" />
                                            </router-link>
                                        </div>
                                        <div class="text">
                                            <h2 class="title h5">
                                                <router-link
                                                    :to="{ name: 'ProductDetail', params: { id: product.productdetail.product_id } }">{{
                                                        product.productdetail.title }}</router-link>
                                            </h2>
                                            <sup>$ {{ product.productdetail.sale_price.toFixed(2) }},-</sup>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>

                    </div>

                    <!-- === button more === -->

                </section>
            </div>

        </div>
    </section>

    <section class="products">

        <!-- === header title === -->

        <header>
            <div class="row">
                <div class="col-md-offset-2 col-md-8 text-center">
                    <h2 class="title">Clothing & Shoes</h2>
                    <div class="text">
                        <p>Check out our latest collections</p>
                    </div>
                </div>
            </div>
        </header>

        <div class="row row-clean">
            <div class="col-xs-6 col-sm-4 col-lg-2" v-for="product in products" :key="product.id">
                <div class="product-item">
                    <article>
                        <div class="info">
                            <span class="add-favorite">
                                <a href="javascript:void(0);" data-title="Add to favorites"
                                    data-title-added="Added to favorites list"><i class="icon icon-heart"></i></a>
                            </span>

                        </div>
                        <div class="figure-grid">
                            <div class="image">
                                <router-link
                                    :to="{ name: 'ProductDetail', params: { id: product.productdetail.product_id } }"
                                    class="mfp-open">
                                    <img v-lazy="'https://api.loop-delivery.com/product_detail/' + product.productdetail.image"
                                        alt="" width="360" class="product-image" />
                                </router-link>
                            </div>
                            <div class="text">
                                <h2 class="title h5">
                                    <router-link
                                        :to="{ name: 'ProductDetail', params: { id: product.productdetail.product_id } }">{{
                                            product.productdetail.title }}</router-link>
                                </h2>
                                <sup>$ {{ product.productdetail.sale_price.toFixed(2) }},-</sup>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>

        <!-- === button more === -->

        <div class="wrapper-more">
            <a href="products-grid.html" class="btn btn-lg">View store</a>
        </div>
    </section>

    <!-- ========================  Block banner shop  ======================== -->

    <!-- ========================  Stretcher widget ======================== -->

    <section class="stretcher-wrapper">

        <!-- === stretcher header === -->

        <header>
            <div class="row">
                <div class="col-md-offset-2 col-md-8 text-center">
                    <h1 class="h2 title">Premium watches</h1>
                    <div class="text">
                        <p>Give the gift of polished punctuality</p>
                    </div>
                </div>
            </div>
        </header>

        <!-- === stretcher === -->

        <!-- <ul class="stretcher">


            <li class="stretcher-item" style="background-image:url(user_assets/assets/images/shop-6.jpg);">
                <div class="stretcher-logo">
                    <div class="text">
                        <span class="text-intro">Man's</span>
                    </div>
                </div>
                <figure>
                    <h4>Man's watches</h4>
                    <figcaption>Collection of prestigious, high precision timepieces</figcaption>
                </figure>
                <a href="#">Anchor link</a>
            </li>


            <li class="stretcher-item" style="background-image:url(user_assets/assets/images/shop-7.jpg);">
                <div class="stretcher-logo">
                    <div class="text">
                        <span class="text-intro">Woman's</span>
                    </div>
                </div>
                <figure>
                    <h4>Womans's watches</h4>
                    <figcaption>Choose among a wonderful variety of refined and feminine timepieces</figcaption>
                </figure>
                <a href="#">Anchor link</a>
            </li>


            <li class="stretcher-item" style="background-image:url(user_assets/assets/images/shop-8.jpg);">
                <div class="stretcher-logo">
                    <div class="text">
                        <span class="text-intro">Professional</span>
                    </div>
                </div>
                <figure>
                    <h4>Professional watches</h4>
                    <figcaption>Discover your favourite model by combining materials</figcaption>
                </figure>
                <a href="#">Anchor link</a>
            </li>


            <li class="stretcher-item" style="background-image:url(user_assets/assets/images/shop-9.jpg);">
                <div class="stretcher-logo">
                    <div class="text">
                        <span class="text-intro">Diamond bezzels</span>
                    </div>
                </div>
                <figure>
                    <h4>Diamond bezzels</h4>
                    <figcaption>Diamond bezel watch collection of luxurious pieces</figcaption>
                </figure>
                <a href="#">Anchor link</a>
            </li>


            <li class="stretcher-item more">
                <div class="more-icon">
                    <span data-title-show="Show more" data-title-hide="+"></span>
                </div>
                <a href="#"></a>
            </li>

        </ul> -->
    </section>

    <!-- ========================  Cards ======================== -->

    <!-- ========================  Blog ======================== -->

    <!-- ========================  Banner ======================== -->

    <section class="banner">

        <!-- === header === -->

        <h2 class="hidden">Banner</h2>

        <div class="container">
            <div class="row">
                <div class="col-md-offset-2 col-md-8 text-center">
                    <h2 class="title">Our story</h2>
                    <p>
                        We believe in creativity as one of the major forces of progress. With this idea, we traveled
                        throughout Italy to find exceptional
                        artisans and bring their unique handcrafted objects to connoisseurs everywhere.
                    </p>
                    <p>
                        <a href="about.html" class="btn btn-clean">Read full story</a>
                    </p>
                </div>
            </div>
        </div>
    </section>

    <!-- ========================  Instagram ======================== -->

    <section class="instagram">

        <!-- === instagram header === -->

        <header>
            <h2 class="h3 title">
                Follow us<br />
                <i class="fa fa-instagram fa-3x"></i> <br />
                Instagram
            </h2>
            <div class="text">
                <p>@LagerShop</p>
            </div>
        </header>

        <!-- === instagram gallery === -->

        <!-- <div class="gallery clearfix">
            <a class="item" href="#">
                <img src="user_assets/assets/images/square-1.jpg" alt="Alternate Text" />
            </a>
            <a class="item" href="#">
                <img src="user_assets/assets/images/square-2.jpg" alt="Alternate Text" />
            </a>
            <a class="item" href="#">
                <img src="user_assets/assets/images/square-3.jpg" alt="Alternate Text" />
            </a>
            <a class="item" href="#">
                <img src="user_assets/assets/images/square-4.jpg" alt="Alternate Text" />
            </a>
            <a class="item" href="#">
                <img src="user_assets/assets/images/square-5.jpg" alt="Alternate Text" />
            </a>
            <a class="item" href="#">
                <img src="user_assets/assets/images/square-6.jpg" alt="Alternate Text" />
            </a>
            <a class="item" href="#">
                <img src="user_assets/assets/images/square-7.jpg" alt="Alternate Text" />
            </a>
            <a class="item" href="#">
                <img src="user_assets/assets/images/square-8.jpg" alt="Alternate Text" />
            </a>
            <a class="item" href="#">
                <img src="user_assets/assets/images/square-9.jpg" alt="Alternate Text" />
            </a>
            <a class="item" href="#">
                <img src="user_assets/assets/images/square-10.jpg" alt="Alternate Text" />
            </a>
            <a class="item" href="#">
                <img src="user_assets/assets/images/square-11.jpg" alt="Alternate Text" />
            </a>
            <a class="item" href="#">
                <img src="user_assets/assets/images/square-12.jpg" alt="Alternate Text" />
            </a>
        </div> /gallery -->

    </section>

    <FooterLayout />
</template>

<script>

import FooterLayout from "./FooterLayout.vue";
import axios from "axios";
import { BASE_URL } from "../../../../config";
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export default {
    components: { FooterLayout, Splide, SplideSlide, },
    data() {
        return {
            sliders: [],
            categories: [],
            products: [],
            sales: [],
            proddet: [],
            subcategories: [],
            childcategories: [],
            productdiscounts: [],
            loading: true, // Add this flag
            countdownIntervals: {},
            randomSubcategoriess: [],
        };
    },
    created() {
        this.categoryLoad();
        this.fetchSlider();
    },

    mounted() {
        this.fetchSale();
        this.fetchData();
    },

    methods: {

        fetchSlider() {
            var page = `${BASE_URL}slider`;
            axios.get(page).then(({ data }) => {
                this.sliders = data.slider;
                console.log(data);
            });
        },
        fetchData() {
            axios
                .get(`${BASE_URL}user-get?user_id=${localStorage.getItem("id")}`)
                .then(() => {
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        fetchSale() {
            axios.get(`${BASE_URL}sale-admin`)
                .then(response => {
                    this.sale = response.data;
                    this.startCountdownTimers();
                })
                .catch(error => {
                    console.error(error);
                });
        },

        startCountdownTimers() {
            this.sales.forEach(sale => {
                const countdownTime = new Date(sale.countdown_time).getTime();
                const now = new Date().getTime();
                const timeRemaining = countdownTime - now;

                if (timeRemaining > 0) {
                    const countdownInterval = setInterval(() => {
                        const currentTime = new Date().getTime();
                        const timeRemaining = countdownTime - currentTime;

                        if (timeRemaining <= 0) {
                            clearInterval(countdownInterval);
                        }
                        sale.countdown = this.getCountdownTime(timeRemaining);
                    }, 1000);

                    this.countdownIntervals[sale.id] = countdownInterval;
                }
            });
        },

        getCountdownTime(timeRemaining) {
            const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
            const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
            const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));

            return `${days} Days, ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        },


        categoryLoad() {
            var page = `${BASE_URL}user-view`;
            axios.get(page).then(({ data }) => {
                this.categories = data.category;
                this.sales = data.sale;
                this.products = data.product;
                this.productdiscounts = data.productdiscount;
                this.proddet = data.produtdet;
                this.subcategories = data.subcategory;
                this.childcategories = data.childcategory;
                this.randomSubcategoriess = data.randomSubcategories;
                this.loading = false; // Set loading flag to false
            });
        },

        // getSubcategories(categoryId) {
        //     Filter the subcategories based on the category ID
        //     return this.subcategories.filter(subcategory => subcategory.category_id === categoryId);
        // },

        save(productId) {
            const formData = new FormData();
            formData.append("product_detail_id", productId);
            formData.append("user_id", localStorage.getItem("id"));
            axios
                .post(`${BASE_URL}favorite-store`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .catch((error) => {
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.error
                    ) {
                        alert(error.response.data.error);
                    } else {
                        alert("An error occurred while processing your request.");
                    }
                });
        },
    },

};
</script>

<style scoped>
.slide-container {
    position: relative;
}

.middle-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.image-container {
    position: relative;
    display: flex;
    align-items: flex-start;
}

.sale-image {
    height: 30vh;
    margin-right: 10px;
}

.time-container {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #000;
    color: #fff;
    padding: 5px 10px;
    font-size: 14px;
}

.product-item {
    height: 400px;
    /* Adjust the height as per your requirement */
    overflow: hidden;
}

.product-image {
    height: 200px;
    /* Adjust the height as per your requirement */
    object-fit: cover;
    /* or object-fit: contain; */
}

.product-image-category {
    height: 350px;
    /* Adjust the height as per your requirement */
    object-fit: cover;
    /* or object-fit: contain; */
}

.sale-item {
    position: relative;
    display: flex;
    align-items: center;
}

.countdown-container {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    text-align: center;
}

.countdown {
    color: #fff;
    font-size: 16px;
}

.countdown-label {
    font-weight: bold;
    margin-bottom: 5px;
}

.countdown-timer {
    font-size: 24px;
}
</style>
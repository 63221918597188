<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="editData" enctype="multipart/form-data">
                        <input type="hidden" v-model="brand.id" />
                        <input type="text" class="form-control mb-3" v-model="brand.brand_name" />

                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />

                        <progress v-if="brand.progress" :value="brand.progress.percentage" max="100">
                            {{ brand.progress.percentage }}%
                        </progress>

                        <button type="submit" class="btn btn-success">Save</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axios from "axios";
import router from "@/router";
import { BASE_URL } from "../../../../../config";

export default {
    name: "EditBrand",

    data() {
        return {
            brand: {
                brand_name: "",
                brand_image: "",
                id: this.$route.params.id,
            },
            oldBrand: {}, // new data property to store the old employee data
        };
    },
    methods: {
        onFileChange(event) {
            this.brand.brand_image = event.target.files[0];
        },
        editData() {
            let formData = new FormData();
            if (this.brand.brand_name) {
                formData.append('brand_name', this.brand.brand_name);
            }
            if (this.brand.brand_image) {
                formData.append('brand_image', this.brand.brand_image);
            }
            axios.post(`${BASE_URL}brand-update/${this.brand.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                this.brand = {
                    brand_name: "",
                    brand_image: "",
                    id: "",
                };
                router.push("/brand");
            }).catch((error) => {
                console.log(error);
            });
        },
        fetchData() {
            axios
                .get(`${BASE_URL}brand-edit/` + this.brand.id)
                .then((response) => {
                    this.oldBrand = response.data;
                    this.brand.brand_name = this.oldBrand.brand_name;
                    this.brand.brand_image = this.oldBrand.brand_image;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.fetchData(); // call the fetchData method when the component is mounted to fetch the old employee data
    },
};
</script>
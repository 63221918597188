<template>
    <head>
      <!-- Required meta tags -->
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
      <title>Admin Dashboard</title>
    </head>
  
    <body>
      <div class="container-scroller">
        <!-- partial:partials/_navbar.html -->
        <AdminNavbar />
        <!-- partial -->
        <div class="container-fluid page-body-wrapper">
          <!-- partial:partials/_sidebar.html -->
          <AdminSidebar />
          <!-- partial -->
          <router-view />
          <!-- main-panel ends -->
        </div>
        <!-- page-body-wrapper ends -->
      </div>
    </body>
  </template>
    
  <style></style>
    
  <script>
  
  import AdminNavbar from "../SouAdmin/shared/AdminNavbar.vue"
  import AdminSidebar from "../SouAdmin/shared/AdminSidebar.vue"
  
  export default {
    components: { AdminNavbar, AdminSidebar }
  }
  </script>
    
<template>
  <!-- ========================  Main header ======================== -->

  <div class="toast" :class="{ 'show': showSuccessMessage, 'error': isError }">
    {{ message }}
  </div>

  <section class="main-header">
    <header>
      <div class="container text-center">
        <h2 class="h2 title">Checkout</h2>
        <ol class="breadcrumb breadcrumb-inverted">
          <li>
            <a href="index.html"><span class="icon icon-home"></span></a>
          </li>
          <li><a class="active" href="checkout-1.html">Cart items</a></li>
          <li><a href="checkout-2.html">Delivery</a></li>
          <li><a href="checkout-3.html">Payment</a></li>
          <li><a href="checkout-4.html">Receipt</a></li>
        </ol>
      </div>
    </header>
  </section>

  <!-- ========================  Checkout ======================== -->

  <section class="checkout">
    <div class="container">
      <!-- ========================  Cart wrapper ======================== -->

      <div class="cart-wrapper">
        <!--cart header -->

        <div class="cart-block cart-block-header clearfix">
          <div>
            <span>Product</span>
          </div>
          <div>
            <span>&nbsp;</span>
          </div>
          <div>
            <span>Quantity</span>
          </div>
          <div class="text-right">
            <span>Price</span>
          </div>
        </div>

        <!--cart items-->
        <div v-if="isLoading" class="spinner">Loading...</div>

        <div class="clearfix" v-else>
          <div class="cart-block cart-block-item clearfix" style="margin-top: 1%; display: flex; align-items: center;"
            v-for="cart in carts" :key="cart.id">
            <div class="image">
              <img :src="'https://api.loop-delivery.com/product_detail/' + cart.productdetail.image" alt="" />
            </div>

            <div class="title" style="flex-grow: 1;">
              <div class="h4">{{ cart.productdetail.title }}</div>
              <div>{{ cart.productdetail.sku }}</div>
            </div>

            <div class="input-group quantity" style="display: flex; align-items: center;">


              <button class="btn btn-sm btn-primary" :disabled="cart.quantity <= 1" @click="decrementQuantity(cart)">
                <i class="fa fa-minus"></i>
              </button>

              <div class="quantity">
                <input v-model="cart.quantity" class="form-control form-quantity" readonly />
              </div>

              <button class="btn btn-sm btn-primary"
                :disabled="cart.quantity >= cart.productdetail.productconfiguration.quantity"
                @click="incrementQuantity(cart)">
                <i class="fa fa-plus"></i>
              </button>

            </div>

            <div class="price" style="margin-left: auto;" v-if="cart.productdetail.discount > 0">

              <span class="final h3" style="margin-right: 10px;" v-if="cart.price_after_discount != null">
                ${{ cart.price_after_discount.toFixed(2) }}
              </span>

              <span class="final h3" style="margin-right: 10px;" v-else>
                ${{ (cart.sale_price * cart.quantity - (cart.sale_price * cart.quantity * cart.productdetail.discount /
                  100)).toFixed(2) }}
              </span>

              <span class="discount" v-if="cart.price_after_discount != null" style="margin-right: 10px;">
                ${{ (cart.productdetail.sale_price * cart.quantity).toFixed(2) }}
              </span>

              <span class="discount" v-else-if="cart.price_after_discount === null" style="margin-right: 10px;">
                ${{ (cart.productdetail.sale_price * cart.quantity).toFixed(2) }}
              </span>

            </div>

            <div class="price" style="margin-left: auto;" v-else>
              <span class="final h3" style="margin-right: 10px;">
                ${{ cart.price_after_discount ? (cart.price_after_discount).toFixed(2) : (cart.productdetail.sale_price *
                  cart.quantity - (cart.productdetail.sale_price * cart.quantity * cart.productdetail.discount /
                    100)).toFixed(2) }}
              </span>
              <span class="discount" v-if="cart.price_after_discount != null" style="margin-right: 10px;">
                ${{ (cart.productdetail.sale_price * cart.quantity).toFixed(2) }}
              </span>
            </div>

            <!--delete-this-item-->
            <button @click="remove(cart)" class="btn btn-danger">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>

        <!--cart prices -->

        <div class="clearfix" style="margin-top: 2%">
          <div class="cart-block cart-block-footer clearfix">
            <div>
              <strong>sub Total</strong>
            </div>
            <div>
              <span>$ {{ cartTotal.toFixed(2) }}</span>
            </div>
          </div>

          <div class="cart-block cart-block-footer clearfix" v-if="selectedGovernorateDetails">
            <div>
              <strong>Delivery</strong>
            </div>
            <div>
              <span>$ {{ selectedGovernorateDetails.price.toFixed(2) }}</span>
            </div>
          </div>

          <div class="cart-block cart-block-footer clearfix" v-if="selectedGovernorateDetails">
            <div>
              <strong>Total</strong>
            </div>
            <div>
              <span>$ {{ (cartTotal + selectedGovernorateDetails.price).toFixed(2) }}</span>
            </div>
          </div>
        </div>

        <!--cart final price -->

        <div class="clearfix">

          <div class="cart-block cart-block-footer cart-block-footer-price clearfix">
            <div>
              <form @submit.prevent="save">
                <div class="alert" :class="[isCouponError ? 'alert-danger' : 'alert-success']" role="alert"
                  v-if="couponMessage">
                  <span v-if="isCouponError"><i class="fa fa-close"></i></span>
                  <span v-else>&#10004;</span>
                  {{ couponMessage }}
                </div>
                <span class="checkbox">
                  <input type="checkbox" id="couponCodeID" />
                  <label for="couponCodeID">Promo code</label>
                  <input type="text" v-model="cart.coupon_code" class="form-control form-coupon"
                    placeholder="Enter your coupon code" />
                </span>

                <button type="submit" class="btn btn-main">Apply</button>
              </form>
            </div>
            <div>
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <form @submit.prevent="saveOrder">
        <div class="login-block login-block-signup">
          <hr />

          <h3>Your Information</h3>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <input type="text" v-model="checkout.first_name" class="form-control" placeholder="First name: *" />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <input type="text" v-model="checkout.middle_name" class="form-control" placeholder="Middle name: *" />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <input type="text" v-model="checkout.last_name" class="form-control" placeholder="Last name: *" />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <select class="form-control" v-model="checkout.selectedGovernorate">
                  <option value="0" selected hidden>-- Choose your governorates --</option>
                  <option v-for="governorate in governorates" :key="governorate.id" :value="governorate.id">
                    {{ governorate.governorates_name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <select class="form-control" v-model="checkout.selectedCity">
                  <option value="0" selected hidden>-- Choose your city --</option>
                  <option v-for="city in filteredFilterGovernorate(checkout.selectedGovernorate)" :key="city.id"
                    :value="city.id">
                    {{ city.city_name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <select class="form-control" v-model="checkout.area_id">
                  <option value="0" selected hidden>-- Choose your area --</option>
                  <option v-for="area in filteredFilterCity(checkout.selectedCity)" :key="area.id" :value="area.id">
                    {{ area.area_name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <input type="text" v-model="checkout.address" class="form-control" placeholder="Address: *" />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <input type="text" v-model="checkout.phone" class="form-control" placeholder="Phone: *" />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <input type="text" v-model="checkout.note" class="form-control" placeholder="Note: *" />
              </div>
            </div>



          </div>
        </div>

        <div class="col-md-12">
          <div class="white-block">
            <div class="h4">Choose delivery</div>

            <hr />

            <span class="checkbox">
              <input type="radio" id="deliveryId2" name="deliveryOption" v-model="checkout.deliveryOption"
                value="delivery" />
              <label for="deliveryId2">Delivery - <strong>{{ selectedGovernorateDetails ? '$ ' +
                selectedGovernorateDetails.price.toFixed(2) : '' }}</strong></label>
            </span>

            <span class="checkbox">
              <input type="radio" id="deliveryId3" name="deliveryOption" v-model="checkout.deliveryOption"
                value="pickup" />
              <label for="deliveryId3">Pick up in the store - <strong>0</strong></label>
            </span>

            <div class="col-xs-6 text-center">
              <button type="submit" class="btn btn-main">
                <span class="icon icon-cart"></span> Checkout
              </button>
            </div>

            <hr />
          </div>
        </div>
      </form>
      <!--/signup-->

      <hr />
      <!-- ========================  Cart navigation ======================== -->
    </div>
    <!--/container-->

    <div class="clearfix mx-auto">
      <div class="row">
        <!-- <div class="col-xs-6 text-center">
          <a href="checkout-2.html" class="btn btn-main"><span class="icon icon-cart"></span> Checkout</a>
        </div> -->
      </div>
    </div>
  </section>
  <FooterLayout />
  <!-- Cart End -->
</template>

<script>
import FooterLayout from "./FooterLayout.vue";
import axios from "axios";
import { BASE_URL } from "../../../../config";

export default {
  components: { FooterLayout },
  name: "addtocart-view",

  data() {
    return {
      addToCart: false,
      message: '',
      isError: false,
      showSuccessMessage: false,
      cart: {
        coupon_code: "",
      },
      checkout: {
        area_id: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        address: "",
        note: "",
        phone: "",
        deliveryOption: 'delivery',
        pick_up: 1,
        selectedGovernorate: [],
        selectedCity: []
      },
      priceAfterDiscount: null,
      carts: [],
      governorates: [],
      areas: [],
      cities: [],
      isLoading: false, // Add this line
      isCouponError: false,
      couponMessage: ""
    };
  },

  created() {
    this.cartLoad();

  },

  computed: {
    selectedGovernorateDetails() {
      if (this.checkout.selectedCity === 0) {
        return null; // No governorate selected
      }

      if (this.checkout.deliveryOption === 'pickup') {
        return { price: 0 }; // Set price to 0 for pick up in store
      }

      return this.cities.find((city) => city.id === this.checkout.selectedCity);
    },

    cartTotal() {
      return this.carts.reduce((total, item) => {
        let price;
        if (item.price_after_discount !== null) {
          price = item.price_after_discount;
        } else {
          price = item.productdetail.sale_price * item.quantity - (item.productdetail.sale_price * item.quantity * item.productdetail.discount / 100);
        }
        return total + price;
      }, 0);
    },
  },

  watch: {
    carts: {
      handler() {
        this.$nextTick(() => {
          this.updateCartTotal();
        });
      },
      deep: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    if (to.query.addToCart) {
      next(vm => {
        vm.showSuccessMessage = true;
        setTimeout(() => {
          vm.showSuccessMessage = false;
        }, 5000);
      });
    } else {
      next();
    }
  },

  methods: {
    filteredFilterGovernorate(governorateId) {
      return this.cities.filter(city => city.governorates_id === governorateId)
    },

    filteredFilterCity(cityId) {
      return this.areas.filter(areas => areas.city_id === cityId);
    },

    saveOrder() {
      this.saveOrderData();
    },

    saveOrderData() {
      if (this.checkout.deliveryOption === 'pickup') {
        this.checkout.pick_up = 0; // Pick up from the store
      } else {
        this.checkout.pick_up = 1; // Not pick up from the store
      }

      // Prepare the order data to be sent to the server
      const orderData = {
        area_id: this.checkout.area_id,
        first_name: this.checkout.first_name,
        middle_name: this.checkout.middle_name,
        last_name: this.checkout.last_name,
        address: this.checkout.address,
        note: this.checkout.note,
        phone: this.checkout.phone,
        pick_up: this.checkout.pick_up,
        coupon_code: this.cart.coupon_code
        // other order data properties...
      };
      // Make the API request to save the order data
      axios.post(`${BASE_URL}checkout?user_id=${localStorage.getItem("id")}`, orderData)
        .then((response) => {
          if (response.status === 200) {
            this.showSuccessMessage = true;
            this.message = "Checkout done successfully";
            this.isError = false;
            this.hideMessageAfterDelay(5000);
            this.cartLoad();
          } else {
            this.showSuccessMessage = true;
            this.message = "Failed to add";
            this.isError = true;
            this.hideMessageAfterDelay(5000);
          }
          this.hideCouponMessageAfterDelay(5000);
        })
        .catch((error) => {
          this.showSuccessMessage = true;
          this.message = "Failed to add";
          this.isError = true;
          console.error("Failed to add", error);
          this.hideMessageAfterDelay(5000);
        });
    },

    save() {
      this.saveData();
    },
    cartLoad() {
      var page = `${BASE_URL}addToCart?user_id=${localStorage.getItem("id")}`;
      axios.get(page).then(({ data }) => {
        this.carts = data.cart;
        this.governorates = data.governorate;
        this.cities = data.city;
        this.areas = data.area;
        this.isLoading = false; // Set loading state to false after data is received
      });
    },

    incrementQuantity(carts) {
      carts.quantity++;
      // send an AJAX request to update the cart quantity in the database
      axios.post(`${BASE_URL}cart-update/${carts.id}`, {
        quantity: carts.quantity,
        user_id: localStorage.getItem("id"),
        product_detail_id: carts.productdetail.id,
      });
    },

    decrementQuantity(carts) {
      if (carts.quantity > 1) {
        carts.quantity--;

        // send an AJAX request to update the cart quantity in the database
        axios.post(`${BASE_URL}cart-update/${carts.id}`, {
          quantity: carts.quantity,
          user_id: localStorage.getItem("id"),
          product_detail_id: carts.productdetail.id,
        });
      }
    },

    updateCartTotal() {
      if (
        this.cart &&
        this.cart.productdetail &&
        this.cart.productdetail.discount !== undefined &&
        this.cart.quantity !== undefined
      ) {
        if (this.cart.productdetail.discount > 0 && this.priceAfterDiscount !== null) {
          // Calculate total with discount
          this.cartTotal = (this.priceAfterDiscount * this.cart.quantity).toFixed(2);
        } else {
          // Calculate total without discount
          this.cartTotal = (this.cart.productdetail.sale_price * this.cart.quantity - (this.cart.productdetail.sale_price * this.cart.quantity * this.cart.productdetail.discount / 100)).toFixed(2);
        }
      }
    },

    saveData() {
      let formData = new FormData();
      formData.append("coupon_code", this.cart.coupon_code);
      axios
        .post(
          `${BASE_URL}coupon?user_id=${localStorage.getItem("id")}`,
          formData
        )
        .then((response) => {
          if (response.status === 200) {
            this.showSuccessMessage = true;
            this.message = "Coupon has been added successfully";
            this.isError = false;
            this.hideMessageAfterDelay(5000);
            this.cartLoad();
            this.priceAfterDiscount = response.data.price_after_discount; // Update the price after discount
            this.updateCartTotal(); // Update the cart total
          } else {
            this.showSuccessMessage = true;
            this.message = "Failed to add coupon";
            this.isError = true;
            this.hideMessageAfterDelay(5000);

          }
          this.hideCouponMessageAfterDelay(5000);
        })
        .catch((error) => {
          this.showSuccessMessage = true;
          this.message = "Failed to add coupon";
          this.isError = true;
          console.error("Failed to add coupon", error);
          this.hideMessageAfterDelay(5000);
          console.error("Failed to add to cart", error);
        });
    },

    remove(carts) {
      var url = `${BASE_URL}cart-delete/${carts.id}`;
      axios
        .delete(url)
        .then((response) => {
          if (Array.isArray(this.carts) && carts) {
            const index = this.carts.indexOf(carts);
            if (index !== -1) {
              this.carts.splice(index, 1);
            }
          }
          if (response.status === 200) {
            this.message = "Product has been removed from cart successfully"; // Update success message
            this.isError = false; // Reset isError to false
          } else {
            this.message = "Failed to remove product"; // Update error message
            this.isError = true; // Set isError to true
          }
          this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
        })
        .catch((error) => {
          this.message = "Failed to remove product"; // Update error message
          this.isError = true; // Set isError to true
          console.error("Failed to remove product", error);
          this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
        });
    },

    hideCouponMessageAfterDelay(delay) {
      setTimeout(() => {
        this.Couponmessage = ""; // Clear the message
        this.isCouponError = false; // Reset isError to false
      }, delay);
    },

    hideMessageAfterDelay(delay) {
      setTimeout(() => {
        this.showSuccessMessage = false; // Hide the toast message after the specified delay
      }, delay);
    },

  },
};
</script>

<style scoped>
.alert-danger {
  background-color: #dc143c;
  color: white;
}

.toast {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #28a745;
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
  z-index: 999999;
}

.toast.error {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #dc3545;
  /* Background color for error */
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
  z-index: 999999;
}

.toast.show {
  right: 20px;
  /* Slide in to the desired position */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
import { createRouter, createWebHashHistory } from "vue-router";

import ColorView from "../components/Admin/pages/Color/Color.vue";
import EditColor from "../components/Admin/pages/Color/EditColor.vue";
import CreateColor from "../components/Admin/pages/Color/CreateColor.vue";

import ProdColorView from "../components/ProdAdmin/pages/Color/ProdColor.vue";
import ProdEditColor from "../components/ProdAdmin/pages/Color/ProdEditColor.vue";
import ProdCreateColor from "../components/ProdAdmin/pages/Color/ProdCreateColor.vue";

import SizeView from "../components/Admin/pages/Size/Size.vue";
import EditSize from "../components/Admin/pages/Size/EditSize.vue";
import CreateSize from "../components/Admin/pages/Size/CreateSize.vue";

import DiscountView from "../components/Admin/pages/Discount/Discount.vue";
import EditDiscount from "../components/Admin/pages/Discount/EditDiscount.vue";
import CreateDiscount from "../components/Admin/pages/Discount/CreateDiscount.vue";

import ProductConfigurationView from "../components/Admin/pages/ProductConfiguration/ProductConfiguration.vue";
import CreateProductConfiguration from "../components/Admin/pages/ProductConfiguration/CreateProductConfiguration.vue";
import EditProductConfiguration from "../components/Admin/pages/ProductConfiguration/EditProductConfiguration.vue";

import ProdCatView from "../components/Admin/pages/ProdCat/ProdCatView.vue";
import CreateProdCat from "../components/Admin/pages/ProdCat/CreateProdCat.vue";
import EditProdCat from "../components/Admin/pages/ProdCat/EditProdCat.vue";

import BrandDiscountView from "../components/Admin/pages/BrandDiscount/BrandDiscount.vue";
import EditBrandDiscount from "../components/Admin/pages/BrandDiscount/EditBrandDiscount.vue";
import CreateBrandDiscount from "../components/Admin/pages/BrandDiscount/CreateBrandDiscount.vue";

import AffiliateDiscountView from "../components/Admin/pages/AffiliateDiscount/AffiliateDiscount.vue";
import CreateAffiliateDiscount from "../components/Admin/pages/AffiliateDiscount/CreateAffiliateDiscount.vue";
import EditAffiliateDiscount from "../components/Admin/pages/AffiliateDiscount/EditAffiliateDiscount.vue";

import AffiliateUserDiscountView from "../components/Admin/pages/AffiliateUserDiscount/AffiliateUserDiscount.vue";
import CreateAffiliateUserDiscount from "../components/Admin/pages/AffiliateUserDiscount/CreateAffiliateUserDiscount.vue";
import EditAffiliateUserDiscount from "../components/Admin/pages/AffiliateUserDiscount/EditAffiliateUserDiscount.vue";

import ProductDiscountView from "../components/Admin/pages/ProductDiscount/ProductDiscount.vue";
import CreateProductDiscount from "../components/Admin/pages/ProductDiscount/CreateProductDiscount.vue";

import BrandView from "../components/Admin/pages/Brand/Brand.vue";
import EditBrand from "../components/Admin/pages/Brand/EditBrand.vue";
import CreateBrand from "../components/Admin/pages/Brand/CreateBrand.vue";

import CategoryView from "../components/Admin/pages/Category/Category.vue";
import EditCategory from "../components/Admin/pages/Category/EditCategory.vue";
import CreateCategory from "../components/Admin/pages/Category/CreateCategory.vue";

import SubCategoryView from "../components/Admin/pages/SubCategory/SubCategory.vue";
import EditSubCategory from "../components/Admin/pages/SubCategory/EditSubCategory.vue";
import CreateSubCategory from "../components/Admin/pages/SubCategory/CreateSubCategory.vue";

import ChildCategoryView from "../components/Admin/pages/ChildCategory/ChildCategory.vue";
import EditChildCategory from "../components/Admin/pages/ChildCategory/EditChildCategory.vue";
import CreateChildCategory from "../components/Admin/pages/ChildCategory/CreateChildCategory.vue";

import ProductView from "../components/Admin/pages/Product/Product.vue";
import EditProduct from "../components/Admin/pages/Product/EditProduct.vue";
import CreateProduct from "../components/Admin/pages/Product/CreateProduct.vue";

import UserDiscount from "../components/Affiliate/pages/Discount/UserDiscount.vue";
import UserInvoice from "../components/Affiliate/pages/Discount/UserInvoice.vue";
import CreateUserDiscount from "../components/Affiliate/pages/Discount/CreateUserDiscount.vue";
import EditUserDiscount from "../components/Affiliate/pages/Discount/EditUserDiscount.vue";

import SaleView from "../components/Admin/pages/Sale/SaleView.vue";
import CreateSale from "../components/Admin/pages/Sale/CreateSale.vue";
import EditSale from "../components/Admin/pages/Sale/EditSale.vue";

import ProductSale from "../components/Admin/pages/ProductSale/ProductSale.vue";
import CreateProductSale from "../components/Admin/pages/ProductSale/CreateProductSale.vue";
import EditProductSale from "../components/Admin/pages/ProductSale/EditProductSale.vue";

import ProductDetailView from "../components/Admin/pages/ProductDetail/ProductDetail.vue";
import EditProductDetail from "../components/Admin/pages/ProductDetail/EditProductDetail.vue";
import CreateProductDetail from "../components/Admin/pages/ProductDetail/CreateProductDetail.vue";

import AllOrder from "../components/Admin/pages/Orders/AllOrder.vue";
import PendingOrder from "../components/Admin/pages/Orders/PendingOrder.vue";
import ClosedOrder from "../components/Admin/pages/Orders/ClosedOrder.vue";
import MovedOrder from "../components/Admin/pages/Orders/MovedOrder.vue";
import RefundOrder from "../components/Admin/pages/Orders/CanceledOrder.vue";
import ViewOrder from "../components/Admin/pages/Orders/ViewOrder.vue";
import ExportInvoice from "../components/User/shared/ExportInvoice.vue";

import AttributeView from "../components/Admin/pages/Attribute/Attribute.vue";
import EditAttribute from "../components/Admin/pages/Attribute/EditAttribute.vue";
import CreateAttribute from "../components/Admin/pages/Attribute/CreateAttribute.vue";

import LogoView from "../components/Admin/pages/Logo/LogoView.vue";
import CreateLogo from "../components/Admin/pages/Logo/CreateLogo.vue";
import EditLogo from "../components/Admin/pages/Logo/EditLogo.vue";

import AttributeTypeView from "../components/Admin/pages/AttributeType/AttributeType.vue";
import EditAttributeType from "../components/Admin/pages/AttributeType/EditAttributeType.vue";
import CreateAttributeType from "../components/Admin/pages/AttributeType/CreateAttributeType.vue";

import FilterView from "../components/Admin/pages/Filter/Filter.vue";
import EditFilter from "../components/Admin/pages/Filter/EditFilter.vue";
import CreateFilter from "../components/Admin/pages/Filter/CreateFilter.vue";

import RefundView from "../components/Admin/pages/RefundProduct/RefundView.vue";
import RefundProductView from "../components/Admin/pages/RefundProduct/RefundProductView.vue";

import FilterTypeView from "../components/Admin/pages/FilterType/FilterType.vue";
import EditFilterType from "../components/Admin/pages/FilterType/EditFilterType.vue";
import CreateFilterType from "../components/Admin/pages/FilterType/CreateFilterType.vue";

import UserProductView from "../components/User/shared/ProductLayout.vue";
import UserLayout from "../components/Layouts/UserPage.vue";

import AffiliateUser from "../components/Affiliate/pages/User/AffiliateUser.vue";
import AffiliateCreateUser from "../components/Affiliate/pages/User/CreateAffiliateUser.vue";
import AffiliateEditUser from "../components/Affiliate/pages/User/EditAffiliateUser.vue";

import UserAffiliate from "../components/Affiliate/pages/SubAffiliate/AffiliateHierarchie.vue";
import CreateUserAffiliate from "../components/Affiliate/pages/SubAffiliate/CreateAffiliateHierarchie.vue";
import EditUserAffiliate from "../components/Affiliate/pages/SubAffiliate/EditAffiliateHierarchie.vue";

import AdminUser from "../components/Admin/pages/User/AdminUser.vue";
import CreateAdminUser from "../components/Admin/pages/User/CreateAdminUser.vue";
import EditAdminUser from "../components/Admin/pages/User/EditAdminUser.vue";

import SubAffiliateUser from "../components/SouAffiliate/pages/User/SubAffiliateUser.vue";
import CreateSubAffiliateUSer from "../components/SouAffiliate/pages/User/CreateSubAffiliateUser.vue";
import EditSubAffiliateUser from "../components/SouAffiliate/pages/User/EditSubAffiliateUser.vue";

import SliderView from "../components/Admin/pages/Slider/SliderView.vue";
import CreateSlider from "../components/Admin/pages/Slider/CreateSlider.vue";
import EditSlider from "../components/Admin/pages/Slider/EditSlider.vue";

import MakeOrder from "../components/Affiliate/pages/Order/MakeOrder.vue";
import AffiliateCart from "../components/Affiliate/pages/Cart/AffiliateCartView.vue";

import ProductSaleView from "../components/User/shared/ProductSale.vue";
import SubCategory from "../components/User/shared/SubCategoryView.vue";
import ChildCategory from "../components/User/shared/ChildCategory.vue";
import ProductList from "../components/User/shared/ProductList.vue";
import ProductDetail from "../components/User/shared/ProductDetail.vue";
import AddToCart from "../components/User/shared/AddToCart.vue";
import AddToFavorite from "../components/User/shared/AddToFavorite.vue";
import LoginView from "../components/User/shared/LoginView.vue";
import RegisterView from "../components/User/shared/RegisterView.vue";
import AllCategories from "../components/User/shared/CategoryView.vue";
import UserProfile from "../components/User/shared/userProfile.vue";
import GrandBazar from "../components/User/shared/GrandBazar.vue";

import AdminLayout from "../components/Layouts/AdminPage.vue";
import ProdAdminLayout from "../components/Layouts/ProdAdminPage.vue";
import SuperAdminLayout from "../components/Layouts/SuperAdminPage.vue";
import AffiliateLayout from "../components/Layouts/AffiliatePage.vue";
import SubAffiliateLayout from "../components/Layouts/SubAfiiliatePage.vue";

const routes = [
  {
    path: "/sub-affiliate",
    name: "sub-affiliate",
    beforeEnter: SubAffiliateGuard,
    component: SubAffiliateLayout,
    children: [
      {
        path: "/sub-affiliate-user",
        name: "SubAffiliateUser",
        component: SubAffiliateUser,
      },
      {
        path: "/create-sub-affiliate-user",
        name: "CreateSubAffiliateUser",
        component: CreateSubAffiliateUSer,
      },
      {
        path: "/edit-sub-affiliate-user",
        name: "EditSubAffiliateUser",
        component: EditSubAffiliateUser,
      },
    ],
  },
  {
    path: "/affiliate",
    name: "affiliate",
    beforeEnter: AffiliateGuard,
    component: AffiliateLayout,
    children: [
      {
        path: "/make-order",
        name: "MakeOrder",
        component: MakeOrder
      },
      {
        path: "/affiliate-cart",
        name: "AffiliateCart",
        component: AffiliateCart
      },
      {
        path: "/affiliate-user",
        name: "AffiliateUser",
        component: AffiliateUser,
      },

      {
        path: "/create-affiliate-user",
        name: "CreateAffiliateUser",
        component: AffiliateCreateUser,
      },

      {
        path: "/edit-affiliate-user/:id",
        name: "EditAffiliateUser",
        component: AffiliateEditUser,
      },

      {
        path: "/user-affiliate",
        name: "UserAffiliate",
        component: UserAffiliate,
      },

      {
        path: "/create-user-affiliate",
        name: "CreateUserAffiliate",
        component: CreateUserAffiliate,
      },

      {
        path: "/edit-user-affiliate/:id",
        name: "EditUserAffiliate",
        component: EditUserAffiliate,
      },

      {
        path: "/user-discount",
        name: "UserDiscount",
        component: UserDiscount,
      },

      {
        path: "user-invoice/:id",
        name: "UserInvoice",
        component: UserInvoice,
      },

      {
        path: "/create-user-discount",
        name: "CreateUserDiscount",
        component: CreateUserDiscount,
      },

      {
        path: "/edit-user-discount/:id",
        name: "EditUserDiscount",
        component: EditUserDiscount,
      },
    ],
  },

  {
    path: "/superdadmin",
    name: "superadmin",
    component: SuperAdminLayout,
    children: [
      {
        path: "/prod-color",
        name: "ProdColorView",
        component: ProdColorView,
      },

      {
        path: "/prod-create-color",
        name: "ProdCreateColor",
        component: ProdCreateColor,
      },
      {
        path: "/edit-color/:id",
        name: "ProdEditColor",
        component: ProdEditColor,
      },
    ],
  },

  {
    path: "/prodadmin",
    name: "prodadmin",
    component: ProdAdminLayout,
    children: [
      {
        path: "/prod-color",
        name: "ProdColorView",
        component: ProdColorView,
      },

      {
        path: "/prod-create-color",
        name: "ProdCreateColor",
        component: ProdCreateColor,
      },
      {
        path: "/edit-color/:id",
        name: "ProdEditColor",
        component: ProdEditColor,
      },
    ],
  },

  {
    path: "/admin",
    name: "admin",
    component: AdminLayout,
    beforeEnter: superadminGuard,
    children: [
      {
        path: "/slider",
        name: "SliderView",
        component: SliderView
      },
      {
        path: "/create-slider",
        name: "CreateSlider",
        component: CreateSlider
      },
      {
        path: "/edit-slider/:id",
        name: "EditSlider",
        component: EditSlider
      },
      {
        path: "/product-conf",
        name: "ProductConfigurationView",
        component: ProductConfigurationView
      },
      {
        path: "/create-product-conf",
        name: "CreateProductConfiguration",
        component: CreateProductConfiguration
      },
      {
        path: "/edit-product-conf/:id",
        name: "EditProductConfiguration",
        component: EditProductConfiguration
      },
      {
        path: "/admin-user",
        name: "AdminUser",
        component: AdminUser,
      },
      {
        path: "/create-admin-user",
        name: "CreateAdminUser",
        component: CreateAdminUser,
      },
      {
        path: "/edit-admin-user/:id",
        name: "EditAdminUser",
        component: EditAdminUser,
      },

      {
        path: "/sale",
        name: "SaleView",
        component: SaleView,
      },
      {
        path: "/create-sale",
        name: "CreateSale",
        component: CreateSale,
      },
      {
        path: "/edit-sale/:id",
        name: "EditSale",
        component: EditSale,
      },
      {
        path: "/refund",
        name: "RefundView",
        component: RefundView
      },
      {
        path: "/refund-product-view/:id",
        name: "RefundProductView",
        component: RefundProductView
      },

      {
        path: "/product-sale",
        name: "ProductSale",
        component: ProductSale,
      },
      {
        path: "/product-create-sale",
        name: "CreateProductSale",
        component: CreateProductSale,
      },
      {
        path: "/product-edit-sale/:id",
        name: "EditProductSale",
        component: EditProductSale,
      },
      {
        path: "/logo-view",
        name: "LogoView",
        component: LogoView,
      },
      {
        path: "/edit-logo/:id",
        name: "EditLogo",
        component: EditLogo,
      },
      {
        path: "/create-logo",
        name: "CreateLogo",
        component: CreateLogo,
      },

      {
        path: "/color",
        name: "ColorView",
        component: ColorView,
      },
      {
        path: "/create-color",
        name: "CreateColor",
        component: CreateColor,
      },
      {
        path: "/edit-color/:id",
        name: "EditColor",
        component: EditColor,
      },

      {
        path: "/category",
        name: "CategoryView",
        component: CategoryView,
      },
      {
        path: "/create-category",
        name: "CreateCategory",
        component: CreateCategory,
      },
      {
        path: "/edit-category/:id",
        name: "EditCategory",
        component: EditCategory,
      },

      {
        path: "/subcategory",
        name: "SubCategoryView",
        component: SubCategoryView,
      },
      {
        path: "/create-subcategory",
        name: "CreateSubCategory",
        component: CreateSubCategory,
      },
      {
        path: "/edit-subcategory/:id",
        name: "EditSubCategory",
        component: EditSubCategory,
      },

      {
        path: "/childcategory",
        name: "ChildCategoryView",
        component: ChildCategoryView,
      },
      {
        path: "/create-childcategory",
        name: "CreateChildCategory",
        component: CreateChildCategory,
      },
      {
        path: "/edit-childcategory/:id",
        name: "EditChildCategory",
        component: EditChildCategory,
      },

      {
        path: "/size",
        name: "SizeView",
        component: SizeView,
      },
      {
        path: "/create-size",
        name: "CreateSize",
        component: CreateSize,
      },
      {
        path: "/edit-size/:id",
        name: "EditSize",
        component: EditSize,
      },

      {
        path: "/discount",
        name: "DiscountView",
        component: DiscountView,
      },
      {
        path: "/create-discount",
        name: "CreateDiscount",
        component: CreateDiscount,
      },
      {
        path: "/edit-discount/:id",
        name: "EditDiscount",
        component: EditDiscount,
      },

      {
        path: "/brand-discount",
        name: "BrandDiscountView",
        component: BrandDiscountView,
      },
      {
        path: "/create-brand-discount",
        name: "CreateBrandDiscount",
        component: CreateBrandDiscount,
      },
      {
        path: "/edit-brand-discount/:id",
        name: "EditBrandDiscount",
        component: EditBrandDiscount,
      },

      {
        path: "/prodcat",
        name: "ProdCatView",
        component: ProdCatView,
      },
      {
        path: "/create-prodcat",
        name: "CreateProdCat",
        component: CreateProdCat,
      },
      {
        path: "/edit-prodcat/:id",
        name: "EditProdCat",
        component: EditProdCat,
      },

      {
        path: "/affiliate-discount",
        name: "AffilaiteDiscountView",
        component: AffiliateDiscountView,
      },
      {
        path: "/create-affiliate-discount",
        name: "CreateAffiliateDiscount",
        component: CreateAffiliateDiscount,
      },
      {
        path: "/edit-affiliate-discount/:id",
        name: "EditAffiliateDiscount",
        component: EditAffiliateDiscount,
      },

      {
        path: "/affiliate-user-discount",
        name: "AffilaiteUserDiscountView",
        component: AffiliateUserDiscountView,
      },
      {
        path: "/create-affiliate-user-discount",
        name: "CreateAffiliateUserDiscount",
        component: CreateAffiliateUserDiscount,
      },
      {
        path: "/edit-affiliate-user-discount/:id",
        name: "EditAffiliateUserDiscount",
        component: EditAffiliateUserDiscount,
      },

      {
        path: "/product-discount",
        name: "ProductDiscountView",
        component: ProductDiscountView,
      },
      {
        path: "/create-product-discount",
        name: "CreateProductDiscount",
        component: CreateProductDiscount,
      },

      {
        path: "/brand",
        name: "BrandView",
        component: BrandView,
      },
      {
        path: "/create-brand",
        name: "CreateBrand",
        component: CreateBrand,
      },
      {
        path: "/edit-brand/:id",
        name: "EditBrand",
        component: EditBrand,
      },

      {
        path: "/product",
        name: "ProductView",
        component: ProductView,
      },
      {
        path: "/create-product",
        name: "CreateProduct",
        component: CreateProduct,
      },
      {
        path: "/edit-product/:id",
        name: "EditProduct",
        component: EditProduct,
      },

      {
        path: "/productdetail",
        name: "ProductDetailView",
        component: ProductDetailView,
      },
      {
        path: "/create-productdetail",
        name: "CreateProductdetail",
        component: CreateProductDetail,
      },
      {
        path: "/edit-productdetail/:id",
        name: "EditProductDetail",
        component: EditProductDetail,
      },

      {
        path: "/attribute",
        name: "AttributeView",
        component: AttributeView,
      },
      {
        path: "/create-attribute",
        name: "CreateAttribute",
        component: CreateAttribute,
      },
      {
        path: "/edit-attribute/:id",
        name: "EditAttribute",
        component: EditAttribute,
      },

      {
        path: "/attributetype",
        name: "AttributeTypeView",
        component: AttributeTypeView,
      },
      {
        path: "/create-attributetype",
        name: "CreateAttributeType",
        component: CreateAttributeType,
      },
      {
        path: "/edit-attributetype/:id",
        name: "EditAttributeType",
        component: EditAttributeType,
      },

      {
        path: "/filter",
        name: "FilterView",
        component: FilterView,
      },
      {
        path: "/create-filter",
        name: "CreateFilter",
        component: CreateFilter,
      },
      {
        path: "/edit-filter/:id",
        name: "EditFilter",
        component: EditFilter,
      },

      {
        path: "/filtertype",
        name: "FilterTypeView",
        component: FilterTypeView,
      },
      {
        path: "/create-filtertype",
        name: "CreateFilterType",
        component: CreateFilterType,
      },
      {
        path: "/edit-filtertype/:id",
        name: "EditFilterType",
        component: EditFilterType,
      },
      {
        path: "/all-orders",
        name: "AllOrder",
        component: AllOrder,
      },
      {
        path: "/pending-orders",
        name: "PendingOrder",
        component: PendingOrder,
      },
      {
        path: "/closed-orders",
        name: "ClosedOrder",
        component: ClosedOrder,
      },
      {
        path: "/moved-orders",
        name: "MovedOrder",
        component: MovedOrder,
      },
      {
        path: "/refund-orders",
        name: "RefundOrder",
        component: RefundOrder,
      },
      {
        path: "/view-order/:id",
        name: "ViewOrder",
        component: ViewOrder,
      },
    ],
  },
  {
    path: "/",
    name: "UserView",
    component: UserLayout,
    redirect: "/",
    children: [
      {
        path: "/user-profile",
        name: "UserProfile",
        component: UserProfile
      },
      {
        path: "/",
        name: "Product",
        component: UserProductView,
      },
      {
        beforeEnter: superadminGuard,
        path: "/export-invoice/:id",
        name: "ExportInvoice",
        component: ExportInvoice,
      },
      {
        path: "/subcategory-view/:id",
        name: "SubCategory",
        component: SubCategory,
      },
      {
        path: "/grand-bazar/:id",
        name: "GrandBazar",
        component: GrandBazar
      },
      {
        path: "/childcategory-view/:id",
        name: "ChildCategory",
        component: ChildCategory,
      },

      {
        path: "/productlist-view/:id",
        name: "ProductList",
        component: ProductList,
      },

      {
        path: "/productdetail-view/:id",
        name: "ProductDetail",
        component: ProductDetail,
      },

      {
        path: "/allCategories",
        name: "AllCategories",
        component: AllCategories,
      },

      {
        path: "/productsale/:id",
        name: "ProductSaleView",
        component: ProductSaleView,
      },
      {
        path: "/addtocart-view",
        name: "AddToCart",
        beforeEnter: isAuthGuard,
        component: AddToCart,
        meta: { requiresAuth: true },
      },

      {
        path: "/addtofavorite-view",
        name: "AddToFavorite",
        beforeEnter: isAuthGuard,
        component: AddToFavorite,
        meta: { requiresAuth: true },
      },
    ],
  },

  {
    path: "/login",
    name: "Login",
    beforeEnter: AuthGuard,
    component: LoginView,
  },

  {
    path: "/register",
    name: "register",
    beforeEnter: AuthGuard,
    component: RegisterView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

function superadminGuard(to, from, next) {
  const role = localStorage.getItem("role");

  if (role === "1") {
    // User has the "superadmin" role, allow access to the route
    next();
  } else {
    // User does not have the "superadmin" role, redirect to another page or show an error message
    next("/"); // Redirect to the NotFound component
  }
}

function isAuthGuard(to, from, next) {
  const role = localStorage.getItem("role");

  if (
    role === "0" ||
    role === "1" ||
    role === "2" ||
    role === "3" ||
    role === "4" ||
    role === "5"
  ) {
    // User has one of the allowed roles, allow access to the route
    next();
  } else {
    // User does not have one of the allowed roles, redirect to another page or show an error message
    next("/"); // Redirect to the NotFound component
  }
}

function AuthGuard(to, from, next) {
  const role = localStorage.getItem("name");

  if (role) {
    // User has one of the allowed roles, allow access to the route
    next("/");
  } else {
    // User does not have one of the allowed roles, redirect to another page or show an error message
    next();
  }
}

function AffiliateGuard(to, from, next) {
  const role = localStorage.getItem("role");

  if (role === "4") {
    // User has the "superadmin" role, allow access to the route
    next();
  } else {
    // User does not have the "superadmin" role, redirect to another page or show an error message
    next("/"); // Redirect to the NotFound component
  }
}

function SubAffiliateGuard(to, from, next) {
  const role = localStorage.getItem("role");

  if (role === "5") {
    // User has the "superadmin" role, allow access to the route
    next();
  } else {
    // User does not have the "superadmin" role, redirect to another page or show an error message
    next("/"); // Redirect to the NotFound component
  }
}

export default router;

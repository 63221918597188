<template>
  <section class="login-wrapper login-wrapper-page">
    <div class="container">
      <div class="login-block login-block-signup">
        <div class="h4">
          Register now
          <router-link to="/register" class="btn btn-main btn-xs btn-login pull-right">Sign Up</router-link>
        </div>
        <div class="toast" :class="{ 'show': showSuccessMessage }">
          Registration successful! You can now log in.
        </div>
        <hr />
        <form @submit.prevent="save">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group" :class="{ 'has-error': validationErrors.email }">
                <input type="email" v-model="login.email" class="form-control" placeholder="Your Email" />
                <span class="error-message" v-if="validationErrors.email">{{ validationErrors.email[0] }}</span>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group" :class="{ 'has-error': validationErrors.password }">
                <input type="password" v-model="login.password" class="form-control" placeholder="Your Password" />
                <span class="error-message" v-if="validationErrors.password">{{ validationErrors.password[0] }}</span>
              </div>
            </div>

            <div class="col-md-12">
              <button type="submit" class="btn btn-main btn-block">Sign In</button>
            </div>
          </div>
        </form>
      </div>
      <!--/signup-->
    </div>
  </section>
</template>
<script>
import axios from "axios";
import router from "@/router";
import { BASE_URL } from "../../../../config";

export default {
  name: "LoginView",

  data() {
    return {
      login: {
        email: "",
        password: "",
      },
      validationErrors: {},
      showSuccessMessage: false, // Control the visibility of the success message

    };
  },
  beforeRouteEnter(to, from, next) {
    if (to.query.registrationSuccess) {
      next(vm => {
        vm.showSuccessMessage = true;
        setTimeout(() => {
          vm.showSuccessMessage = false;
        }, 5000);
      });
    } else {
      next();
    }
  },
  methods: {
    save() {
      this.saveData();
    },
    saveData() {
      axios.post(`${BASE_URL}login`, this.login).then((response) => {

        // Store the token in local storage or a cookie
        localStorage.setItem("token", response.data.token);
        localStorage.setItem(
          "name",
          response.data.user.first_name + " " + response.data.user.last_name
        );
        localStorage.setItem("id", response.data.user.id);
        localStorage.setItem("role", response.data.user.roles);

        // Redirect to the desired page and reload
        router.push({ path: '/', query: { loginSuccess: true } }).then(() => { });
      }).catch((error) => {
        if (error.response && error.response.status === 422) {
          this.validationErrors = error.response.data.errors;
        }
      });
    },

  },
};
</script>

<style scoped>
section {
  margin-top: 150px !important;
}

.text-danger {
  color: red !important;
}

body {
  background-color: #2e73bb;
  color: #ffffff;
  font-family: Arial, sans-serif;
}

.wrapper {
  margin-top: 150px;
}

.login-wrapper-page {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-block-signup {
  text-align: center;
}

.login-block-signup .h4 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.login-block-signup .btn-login {
  background-color: transparent;
  color: #2e73bb;
  font-weight: bold;
  border: 2px solid #2e73bb;
  margin-top: -5px;
}

.login-block-signup .btn-login:hover {
  background-color: #2e73bb;
  color: #ffffff;
}

.login-block-signup hr {
  margin-top: 20px;
  margin-bottom: 30px;
  border-color: #ccc;
}

.login-block-signup .form-group {
  margin-bottom: 20px;
}

.login-block-signup input[type="text"],
.login-block-signup input[type="email"],
.login-block-signup input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.login-block-signup .error-message {
  display: block;
  margin-top: 5px;
  font-size: 12px;
}

.login-block-signup button {
  background-color: #2e73bb;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  border: none;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}

.login-block-signup button:hover {
  background-color: #1c528c;
}

.toast {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%; /* Start offscreen on the right */
  background-color: #28a745;
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
}

.toast.show {
  right: 20px; /* Slide in to the desired position */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
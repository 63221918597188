<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="editData" enctype="multipart/form-data">
                        <input type="hidden" v-model="productdetail.id" />

                        <span><label for="title">Product Title: </label></span><input type="text" class="form-control mb-3" v-model="productdetail.title" />
                        <span><label for="sku">Product Sku: </label></span><input type="text" class="form-control mb-3" v-model="productdetail.sku" />
                        <span><label for="description">Product Description: </label></span><input type="text" class="form-control mb-3" v-model="productdetail.description" />
                        <span><label for="details">Product Details: </label></span><input type="text" class="form-control mb-3" v-model="productdetail.details" />
                        <span><label for="discount">Product Discount: </label></span><input type="text" class="form-control mb-3" v-model="productdetail.discount" />
                        <span><label for="saleprice">Product Sale Price: </label></span><input type="text" class="form-control mb-3" v-model="productdetail.sale_price" />
                        <span><label for="location">Product Sale Price: </label></span><input type="text" class="form-control mb-3" v-model="productdetail.location" />
                        <span><label for="barcode">Product Sale Price: </label></span><input type="text" class="form-control mb-3" v-model="productdetail.barcode" />
                        <span><label for="expirydate">Product Sale Price: </label></span><input type="datetime-local" class="form-control mb-3" v-model="productdetail.expiry_date" required />

                        <span><label for="title">Product Brand: </label></span>
                        <select class="form-control mb-3" v-model="productdetail.brand_id">
                            <option v-for="brand in brand" :value="brand.id" :key="brand.id">
                                {{ brand.brand_name }}
                            </option>
                        </select>

                        <span><label for="title">Product Child Category: </label></span>
                        <select class="form-control mb-3" v-model="productdetail.child_category_id">
                            <option v-for="childcategory in childcategory" :value="childcategory.id"
                                :key="childcategory.id">
                                {{ childcategory.child_category_name }}
                            </option>
                        </select>

                        <span><label for="title">Product Configuration: </label></span>

                        <div class="row">
                            <div class="col-3">
                                <select class="form-control mb-3" v-model="productdetail.color_id">
                                    <option v-for="color in color" :value="color.id"
                                        :key="color.id">
                                        {{ color.color_name }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-3">
                                <select class="form-control mb-3" v-model="productdetail.size_id">
                                    <option v-for="size in size" :value="size.id"
                                        :key="size.id">
                                        {{ size.size_name }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-3">
                                <select class="form-control mb-3" v-model="productdetail.attribute_type_id">
                                    <option v-for="attributetype in attributetype" :value="attributetype.id"
                                        :key="attributetype.id">
                                        {{ attributetype.attribute_type_name }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-3">
                                <input type="text" class="form-control mb-3" v-model="productdetail.quantity" />
                            </div>

                        </div>

                        <span><label for="title">Product Image: </label></span>
                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />

                        <button type="submit" class="btn btn-success">Save</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axios from "axios";
import router from "@/router";
import { BASE_URL } from "../../../../../config";

export default {
    name: "EditProductDetail",

    data() {
        return {
            productdetail: {
                brand_id: 0,
                product_id: 0,
                child_category_id: 0,
                sku: "",
                title: "",
                description: "",
                details: "",
                sale_price: "",
                discount: 0,
                image: "",
                selectedChildcategory: [],
                selectedFilter: [],
                product_filter_id: [],
                products: [{ product_filter_id: '' }],
                color_id: '',
                size_id: '',
                attribute_type_id: '',
                quantity: "",
                type: "",
                location: "",
                barcode: "",
                expiry_date: "",
                id: this.$route.params.id,
            },
            product: {}, // new data property to store the old employee data
            brand: {}, // new data property to store the old employee data
            childcategory: {}, // new data property to store the old employee data
            color: {},
            size: {},
            attributetype: {},
            configuration: {},
            oldProductDetail: {},
        };
    },
    methods: {
        onFileChange(event) {
            this.productdetail.image = event.target.files[0];
        },

        editData() {
            let formData = new FormData();
            if (this.productdetail.brand_id) {
                formData.append('brand_id', this.productdetail.brand_id);
            }
            if (this.productdetail.product_id) {
                formData.append('product_id', this.productdetail.product_id);
            }
            if (this.productdetail.child_category_id) {
                formData.append('child_category_id', this.productdetail.child_category_id);
            }
            if (this.productdetail.sku) {
                formData.append('sku', this.productdetail.sku);
            }
            if (this.productdetail.title) {
                formData.append('title', this.productdetail.title);
            }
            if (this.productdetail.description) {
                formData.append('description', this.productdetail.description);
            }
            if (this.productdetail.details) {
                formData.append('details', this.productdetail.details);
            }
            if (this.productdetail.sale_price) {
                formData.append('sale_price', this.productdetail.sale_price);
            }
            if (this.productdetail.discount) {
                formData.append('discount', this.productdetail.discount);
            }
            if (this.productdetail.image) {
                formData.append('image', this.productdetail.image);
            }
            if (this.productdetail.color_id) {
                formData.append('color_id', this.productdetail.color_id);
            }
            if (this.productdetail.size_id) {
                formData.append('size_id', this.productdetail.size_id);
            }
            if (this.productdetail.attribute_type_id) {
                formData.append('attribute_type_id', this.productdetail.attribute_type_id);
            }
            if (this.productdetail.quantity) {
                formData.append('quantity', this.productdetail.quantity);
            }
            if (this.productdetail.location) {
                formData.append('location', this.productdetail.location);
            }
            if (this.productdetail.expiry_date) {
                formData.append('expiry_date', this.productdetail.expiry_date);
            }
            if (this.productdetail.barcode) {
                formData.append('barcode', this.productdetail.barcode);
            }
            axios.post(`${BASE_URL}productdetail-update/${this.productdetail.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                this.productdetail = {
                    brand_id: 0,
                    product_id: 0,
                    child_category_id: 0,
                    sku: "",
                    title: "",
                    description: "",
                    details: "",
                    sale_price: "",
                    discount: 0,
                    image: "",
                    selectedChildcategory: [],
                    selectedFilter: [],
                    product_filter_id: [],
                    products: [{ product_filter_id: '' }],
                    color_id: '',
                    size_id: '',
                    attribute_type_id: '',
                    quantity: "",
                    type: "",
                    location: "",
                    expiry_date: "",
                    barcode: "",
                    id: "",
                };
                router.push("/productdetail");
            }).catch((error) => {
                console.log(error);
            });
        },

        fetchData() {
            axios
                .get(`${BASE_URL}productdetail-edit/` + this.productdetail.id)
                .then((response) => {
                    this.productdetail = response.data.productdetail;
                    this.brand = response.data.brand;
                    this.product = response.data.product;
                    this.childcategory = response.data.childcategory;
                    this.color = response.data.color;
                    this.size = response.data.size;
                    this.attributetype = response.data.attributetype;
                    this.configuration = response.data.configuration;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },

    mounted() {
        this.fetchData(); // call the fetchData method when the component is mounted to fetch the old employee data
    },

};
</script>
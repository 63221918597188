<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">
                    <form>
                        <select v-model="selectedSale" class="form-control" @change="fetchProductDetails">
                            <option value="0" selected disabled hidden>-- Choose Sale --</option>
                            <option v-for="sale in sales" :key="sale.id" :value="sale.childcategory.id">
                                {{ sale.childcategory.child_category_name }}
                            </option>
                        </select>
                        <button @click="submitForm" class="btn btn-success mt-3 mb-4">Submit</button>

                        <table class="table w-100">

                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Image</th>
                                    <th>Sku</th>
                                    <th>Title</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="productdetail in productdetails" :key="productdetail.id">
                                    <th scope="row">{{ productdetail.productdetail.id }}</th>
                                    <td><img
                                            :src="'http://localhost:8000/product_detail/' + productdetail.productdetail.image">
                                    </td>
                                    <td>{{ productdetail.productdetail.sku }}</td>
                                    <td>{{ productdetail.productdetail.title }}</td>
                                    <td>
                                        <input type="checkbox" class="custom-checkbox"
                                            :value="productdetail.productdetail.id" v-model="selectedProducts">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "../../../../../config";
import router from "@/router";

export default {
    name: "CreateProductSale",

    data() {
        return {
            productsale: {
                product_detail_id: 0,
                sale_id: 0,
            },

            productdetails: [],
            sales: [],
            selectedSale: null,
            selectedProducts: [],
        };
    },

    methods: {
        submitForm() {
            // Map selectedSale to sale.id
            const selectedSaleId = this.sales.find(sale => sale.childcategory.id === this.selectedSale).id;

            const formData = {
                sale_id: selectedSaleId,
                product_detail_id: this.selectedProducts,
            };
            axios
                .post(`${BASE_URL}product-sale-store`, formData)
                .then(() => {
                    // Redirect to the desired page
                    router.push("/product-sale");
                })
                .catch(error => {
                    console.error(error);
                });
        },

        fetchProductSale() {
            axios
                .get(`${BASE_URL}product-sale-create`)
                .then(response => {
                    this.productdetail = response.data.productdetails;
                    this.sales = response.data.sale;
                })
                .catch(error => {
                    console.error(error);
                });
        },

        fetchProductDetails() {
            if (this.selectedSale) {
                axios
                    .get(`${BASE_URL}product-details/${this.selectedSale}`)
                    .then(response => {
                        this.productdetails = response.data;
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                this.productdetails = [];
            }
        },
    },

    mounted() {
        this.fetchProductSale();
    },

    watch: {
        selectedSale() {
            this.fetchProductDetails();
        },
    },
};
</script>

<style scoped>
.custom-checkbox {
    /* Add your custom styling properties here */
    /* Example properties */
    width: 20px;
    height: 20px;
    background-color: #ddd;
}

.custom-checkbox {
    /* Add your custom styling properties here */
    /* Example properties */
    width: 20px;
    height: 20px;
    background-color: #ddd;
}

.custom-checkbox:checked {
    /* Add your custom styling for checked state */
    /* Example properties */
    background-color: #5cb85c;
    /* Change the background color when checked */
}
</style>
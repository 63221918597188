<template>
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="card">
          <div class="card-body">
  
            <h3 class="mb-5">Users Panel</h3>
            <router-link to="/create-user-affiliate" class="btn btn-primary">Create User/Affiliate</router-link>

            <table class="table w-100">
              <thead>
                <tr>
                  <th>#</th>
                  <th>User Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="subaffiliate in subaffiliates" v-bind:key="subaffiliate.id">
                  <th scope="row">{{ subaffiliate.id }}</th>
                  <td>{{ subaffiliate.affiliate.first_name }}</td>
                  <td>
                    <router-link :to="{ name: 'EditAffiliateUser', params: { id: subaffiliate.id } }"
                      class="btn btn-warning me-2"><i class="fa fa-pencil"></i></router-link>
  
                    <button @click="remove(subaffiliate)" class="btn btn-danger"><i class="fa fa-trash"></i></button>
  
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
    </div>
  </template>
        
  <script>
  import axios from "axios";
  import { BASE_URL } from "../../../../../config";
  
  export default {
    name: "AffiliateUser",
  
    data() {
      return {
        subaffiliates: [],
      };
    },
  
    created() {
      this.userLoad();
    },
  
    methods: {
      userLoad() {
        var page = `${BASE_URL}user-affiliate?user_id=${localStorage.getItem("id")}`;
        axios.get(page).then(({ data }) => {
          console.log(data);
          this.subaffiliates = data.subaffiliate;
        });
      },
  
      remove(subaffiliates) {
        var url = `${BASE_URL}affiliate-user-delete/${subaffiliates.id}`;
        axios.delete(url).then(() => {
          const index = this.subaffiliate.indexOf(subaffiliates);
          this.subaffiliate.splice(index, 1);
        });
      },
    },
  };
  
  </script>
        
  <style scoped></style>
<template>
    <div class="container">
        <form @submit="updateProfile">
            <div class="form-group">
                <label for="first_name">First Name</label>
                <input type="text" id="first_name" v-model="firstName" class="form-control">
            </div>
            <div class="form-group">
                <label for="last_name">Last Name</label>
                <input type="text" id="last_name" v-model="lastName" class="form-control">
            </div>
            <div class="form-group">
                <label for="email">Email</label>
                <input type="email" id="email" v-model="email" class="form-control">
            </div>
            <div class="form-group">
                <label for="current_password">Current Password</label>
                <input type="password" id="current_password" v-model="currentPassword" class="form-control">
            </div>
            <div class="form-group">
                <label for="new_password">New Password</label>
                <input type="password" id="new_password" v-model="newPassword" class="form-control">
            </div>
            <div class="form-group">
                <label for="confirm_password">Confirm Password</label>
                <input type="password" id="confirm_password" v-model="confirmPassword" class="form-control">
                <span class="text-danger" v-if="passwordError">{{ passwordError }}</span>
            </div>

            <div class="form-group">
                <button class="btn btn-success" type="submit">Update</button>
            </div>
        </form>

    </div>

    <div class="container" style="margin-top: 20px">
        <div class="row" v-if="!users">
            <!-- Display the form if the user exists -->
            <form @submit.prevent="save">
                <input type="text" v-model="userAffilaiteUniqueCode.unique_code" id="" placeholder="Enter your affilaite unique code"/>
                <button type="submit" class="btn btn-success" style="margin-top:10px">Apply</button>
            </form>
        </div>

        <div class="row" style="margin-bottom:55px" v-else>
            Your Affiliate Coupon is: {{ users.unique_code }}
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "../../../../config";

export default {
    data() {
        return {
            users: [],
            userAffilaiteUniqueCode: {
                user_id: "",
                unique_code: "",
            },
            firstName: '',
            lastName: '',
            email: '',
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            passwordError: '',
        };
    },
    mounted() {
        const userId = localStorage.getItem('id');
        this.fetchData(userId);
    },

    created() {
        this.fetchCoupon();
    },

    methods: {
        save() {
            this.saveData();
        },

        saveData() {
            let formData = new FormData();
            formData.append("user_id", `${localStorage.getItem("id")}`);
            formData.append("unique_code", this.userAffilaiteUniqueCode.unique_code);
            axios
                .post(`${BASE_URL}user-affiliate-unique-code`, formData, {})
                .then(() => {
                    this.fetchCoupon();
                });
        },

        fetchCoupon() {
            var page = `${BASE_URL}exist-coupon?user_id=${localStorage.getItem("id")}`;
            axios.get(page).then(({ data }) => {
                this.users = data.user;
                this.isLoading = false; // Set loading state to false after data is received
            });
        },

        fetchData(userId) {
            axios
                .get(`${BASE_URL}user-get/${userId}`)
                .then(response => {
                    const user = response.data;
                    this.firstName = user.first_name;
                    this.lastName = user.last_name;
                    this.email = user.email;
                    this.userFullName = user.first_name + ' ' + user.last_name;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        updateProfile(event) {
            event.preventDefault();
            const userId = localStorage.getItem('id');

            if (this.newPassword !== this.confirmPassword) {
                this.passwordError = 'New password and confirmation password do not match';
                return;
            }

            if (this.newPassword && !this.currentPassword) {
                this.passwordError = 'Please enter your current password';
                return;
            }

            this.passwordError = ''; // Reset the password error message

            // Make the API request to update the user profile
            axios.put(`${BASE_URL}profile/${userId}`, {
                first_name: this.firstName,
                last_name: this.lastName,
                email: this.email,
                current_password: this.currentPassword,
                new_password: this.newPassword,
                confirm_password: this.confirmPassword, // Add this line to match the variable name in the Laravel controller
            })
                .then(() => {
                    console.log('Profile updated successfully!');
                })
                .catch(error => {
                    if (error.response && error.response.status === 400) {
                        this.passwordError = error.response.data.message;
                    } else {
                        console.error('Profile update failed:', error);
                    }
                });
        },
    }
};
</script>

<style scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

form {
    width: 700px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

input:focus {
    outline: none;
    border-color: #4c9aff;
    box-shadow: 0 0 4px rgba(76, 154, 255, 0.5);
}
</style>
  

<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">
                    <h3 class="mb-5">Affiliate Order Panel</h3>

                    <div class="row mb-5">
                        <div class="col-6">
                            <label for="brand-select">Select Brand:</label>
                            <select class="form-control w-100" id="brand-select" v-model="selectedBrandId"
                                @change="filterProductDetails">
                                <option value="" hidden selected>All Brands</option>
                                <option value="">All Brands</option>
                                <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand.brand_name }}
                                </option>
                            </select>
                        </div>

                        <div class="col-6">
                            <label for="brand-select">Select User:</label>
                            <select class="form-control w-100" v-model="cart.user_id">
                                <option value="" hidden selected>Users / Sub Affiliate</option>
                                <option v-for="user in users" :key="user.id" :value="user.user_id">{{ user.user.first_name
                                }} {{ user.user.last_name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Sku</th>
                                <th>Title</th>
                                <th>Price</th>
                                <th>Discount</th>
                                <th>Quantity</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="productDetail in filteredProductDetails" :key="productDetail.id">
                                <td>
                                    <div class="image-container">
                                        <img :src="'https://api.loop-delivery.com/product_detail/' + productDetail.image"
                                            class="product-image"
                                            @mouseenter="enlargeImage('https://api.loop-delivery.com/product_detail/' + productDetail.image)" />
                                        <div class="enlarged-image">
                                            <img :src="'https://api.loop-delivery.com/product_detail/' + productDetail.image" />
                                        </div>
                                    </div>
                                </td>
                                <td>{{ productDetail.sku }}</td>
                                <td>{{ productDetail.title }}</td>
                                <td>{{ productDetail.sale_price.toFixed(2) }}</td>
                                <td>{{ productDetail.discount }}</td>
                                <td v-if="getProductConfigurationQuantity(productDetail.id) > 0">
                                    <div class="input-group d-flex align-items-center">
                                        <button class="btn btn-outline-secondary btn-quantity" type="button"
                                            @click="decreaseQuantity(productDetail)">-</button>
                                        <input type="number" class="form-control custom-input"
                                            v-model.number="productDetail.quantity"
                                            :max="getProductConfigurationQuantity(productDetail.id)"
                                            @change="updateCartQuantity(productDetail.quantity)" placeholder="Qty" />
                                        <button class="btn btn-outline-secondary btn-quantity" type="button"
                                            @click="increaseQuantity(productDetail)">+</button>
                                    </div>
                                    <div class="error-message"
                                        v-if="productDetail.quantity > getProductConfigurationQuantity(productDetail.id)">
                                        Quantity exceeds available stock
                                    </div>
                                    <button v-else type="submit" class="btn btn-success add-to-cart-button"
                                        @click="addToCart(productDetail)">
                                        Add to cart
                                    </button>
                                </td>
                                <td v-else>
                                    <div class="input-group">
                                        <input type="text" class="form-control custom-input" :value="productDetail.quantity"
                                            placeholder="Qty" readonly />
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary btn-quantity" type="button"
                                                @click="decreaseQuantity(productDetail)">-</button>
                                            <button class="btn btn-outline-secondary btn-quantity" type="button"
                                                @click="increaseQuantity(productDetail)" disabled>+</button>
                                        </div>
                                    </div>
                                    <div class="out-of-stock-message"
                                        v-if="getProductConfigurationQuantity(productDetail.id) === 0">
                                        Out of stock
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

            </div>
        </div>
    </div>
</template>
  
<script>
import axios from "axios";
import { BASE_URL } from "../../../../../config";

export default {
    name: "RefundView",

    data() {
        return {
            users: [],
            brands: [],
            productdetails: [],
            productConfigurations: [],
            selectedBrandId: "",
            selectedProductDetails: [],
            filteredProductDetails: [],
            productDetail: {
                quantity: 1
            },
            cart: {
                quantity: 1,
                user_id: '',
            }
        };
    },

    created() {
        this.productSaleLoad();
        this.filterProductDetails();
    },

    methods: {
        enlargeImage(imageUrl) {
            const enlargedContainer = document.createElement('div');
            enlargedContainer.classList.add('enlarged-image');

            const enlargedImage = document.createElement('img');
            enlargedImage.src = imageUrl;

            enlargedContainer.appendChild(enlargedImage);

            const body = document.querySelector('body');
            body.appendChild(enlargedContainer);

            setTimeout(() => {
                enlargedContainer.remove();
            }, 1); // Adjust the delay as needed
        },

        updateCartQuantity(quantity) {
            this.cart.quantity = quantity;
        },

        addToCart(productDetail) {
            const maxQuantity = this.getProductConfigurationQuantity(productDetail.id);

            if (productDetail.quantity > maxQuantity) {
                // Show an error message or handle the validation error in your desired way
                return;
            }

            this.cart.quantity = productDetail.quantity;

            const formData = new FormData();
            formData.append("quantity", productDetail.quantity);
            formData.append("sale_price", productDetail.sale_price);
            formData.append("brand_id", productDetail.brand_id);
            formData.append("product_detail_id", productDetail.id);
            formData.append("user_id", this.cart.user_id);

            axios
                .post(`${BASE_URL}order-store`, formData)
                .then((response) => {
                    if (response.status === 200) {
                        this.message = "Product has been added to the cart successfully";
                        this.isError = false;
                    } else {
                        this.message = "Failed to add the product to the cart";
                        this.isError = true;
                    }
                    this.hideMessageAfterDelay(5000);
                })
                .catch((error) => {
                    this.message = "Failed to add the product to the cart";
                    this.isError = true;
                    console.error("Failed to add the product to the cart", error);
                    this.hideMessageAfterDelay(5000);
                });
        },

        productSaleLoad() {
            const page = `${BASE_URL}make-order?user_id=${localStorage.getItem("id")}`;
            axios.get(page).then(response => {
                this.users = response.data.user;
                this.brands = response.data.brand;
                this.productdetails = response.data.productdetail;
                this.productConfigurations = response.data.productConfiguration;
            });
        },

        filterProductDetails() {
            if (this.selectedBrandId) {
                this.filteredProductDetails = this.productdetails.filter(
                    product => product.brand_id === this.selectedBrandId
                );
            } else {
                this.filteredProductDetails = this.productdetails;
            }
        },

        getProductConfigurationQuantity(productDetailId) {
            const productConfiguration = this.productConfigurations.find(config => config.product_detail_id === productDetailId);
            return productConfiguration ? productConfiguration.quantity : 0;
        },

        getMaxQuantity(productDetailId) {
            const productConfiguration = this.productConfigurations.find(config => config.product_detail_id === productDetailId);
            return productConfiguration ? productConfiguration.quantity : 0;
        },

        decreaseQuantity(productDetail) {
            if (productDetail.quantity > 1) {
                productDetail.quantity--;
            }
        },

        increaseQuantity(productDetail) {
            const maxQuantity = this.getMaxQuantity(productDetail.id);
            if (!productDetail.quantity || isNaN(productDetail.quantity)) {
                productDetail.quantity = 0;
            }
            if (productDetail.quantity < maxQuantity) {
                productDetail.quantity++;
            }
        },
    },

    mounted() {
        this.productSaleLoad();
    }
};
</script>
  
<style scoped>
.custom-checkbox {
    width: 20px;
    height: 20px;
    background-color: #ddd;
}

/* Styling for checked checkbox */
.custom-checkbox:checked {
    background-color: #5bc0de;
}

.input-group.d-flex {
    display: flex;
    align-items: center;
}

.input-group-append {
    display: flex;
    gap: 0.5rem;
}

/* Add custom CSS styles here */
.custom-input {
    width: 40px;
    /* Adjust the width as needed */
}

.btn-quantity {
    padding: 5px 10px;
    /* Adjust the padding as needed */
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    /* Adjust the margin as needed */
}

.out-of-stock-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    /* Adjust the margin as needed */
}

.add-to-cart-button {
    padding: 8px 16px;
    /* Adjust the padding as needed */
}

/* Image enlargement effect */
.product-image {
    transition: transform 0.3s ease;
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
}

.image-container {
    position: relative;
    display: inline-block;
}

.enlarged-image {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    max-width: 90%;
    max-height: 90%;
}

.enlarged-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.image-container:hover .enlarged-image {
    display: block;
}
</style>
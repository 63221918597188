<template>
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="card">
          <div class="card-body">
  
            <h3 class="mb-5">Logo Panel</h3>
  
            <router-link to="/create-logo" class="btn btn-primary">Create Logo</router-link>
  
            <table class="table w-100">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Logo Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="logos in logo" :key="logos.id">
                  <th scope="row">{{ logos.id }}</th>
                  <td><img :src="'https://api.loop-delivery.com/logo_image/' + logos.logo"></td>
                  <td>
                    <router-link :to="{ name: 'EditLogo', params: { id: logos.id } }" class="btn btn-warning me-2"><i
                        class="fa fa-pencil"></i></router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </template>
      

  <script>
  import axios from "axios";
  import { BASE_URL } from "../../../../../config";
  
  export default {
    name: "LogoView",
  
    data() {
      return {
        logo: [],
      };
    },
  
    created() {
      this.brandLoad();
    },
  
    methods: {
      brandLoad() {
        var page = `${BASE_URL}logo`;
        axios.get(page).then(({ data }) => {
          console.log(data);
          this.logo = data;
        });
      },
  
    },
  };
  
  </script>
      
  <style scoped></style>
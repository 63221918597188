<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save" enctype="multipart/form-data">
                        <input type="text" class="form-control" placeholder="Sub Category Name"
                            v-model="subcategory.sub_category_name" /><br /><br />

                        <select v-model="subcategory.category_id" class="form-control mb-3">
                            <option value="0" selected disabled hidden>-- Choose Category --</option>
                            <option v-for="category in categories" :key="category.id" :value="category.id">
                                {{ category.category_name }}
                            </option>
                        </select>

                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />

                        <progress v-if="subcategory.progress" :value="subcategory.progress.percentage" max="100">
                            {{ subcategory.progress.percentage }}%
                        </progress>

                        <label>Banner Sub Category</label>
                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />

                        <progress v-if="subcategory.progress" :value="subcategory.progress.percentage" max="100">
                            {{ subcategory.progress.percentage }}%
                        </progress>

                        <button type="submit" class="btn btn-success">Create</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    

<script>
import axios from "axios";
import router from "@/router";
import { BASE_URL } from "../../../../../config";

export default {
    name: "CreateSubCategory",

    data() {
        return {
            subcategory: {
                category_id: 0,
                sub_category_name: "",
                sub_category_image: "",
                banner_sub_category_image: "",
            },
            categories: [], // Update variable name to employees
        };
    },

    methods: {
        onFileChange(event) {
            this.subcategory.sub_category_image = event.target.files[0];
            this.subcategory.banner_sub_category_image = event.target.files[0];
        },

        save() {
            this.saveData();
        },
        saveData() {
            let formData = new FormData();
            formData.append('sub_category_name', this.subcategory.sub_category_name);
            formData.append('category_id', this.subcategory.category_id);
            formData.append('sub_category_image', this.subcategory.sub_category_image);
            formData.append('banner_sub_category_image', this.subcategory.banner_sub_category_image);
            axios.post(`${BASE_URL}subcategory-store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/subcategory");
            });
        },
        fetchCategories() {
            axios
                .get(`${BASE_URL}subcategory-create`) // Update endpoint to fetch employees
                .then(response => {
                    this.categories = response.data;
                });
        },
    },
    mounted() {
        this.fetchCategories();
    },
};
</script>

<template>
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="card">
          <div class="card-body">
  
            <h3 class="mb-5">Discount Panel</h3>
  
            <router-link to="/create-discount" class="btn btn-primary">Create Discount</router-link>
  
            <table class="table w-100">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Discount Name</th>
                  <th>Commission</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="discounts in discount" v-bind:key="discounts.id">
                  <th scope="row">{{ discounts.id }}</th>
                  <td>{{ discounts.discount_name }}</td>
                  <td>{{ discounts.commission }}</td>
                  <td>
                    <router-link :to="{ name: 'EditDiscount', params: { id: discounts.id } }"
                      class="btn btn-warning me-2"><i class="fa fa-pencil"></i></router-link>
  
                    <button @click="remove(discounts)" class="btn btn-danger"><i class="fa fa-trash"></i></button>
  
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
    </div>
  </template>
      
  <script>
  import axios from "axios";
  import { BASE_URL } from "../../../../../config";
  
  export default {
    name: "DiscountView",
  
    data() {
      return {
        discount: [],
      };
    },
  
    created() {
      this.discountLoad();
    },
  
    methods: {
        discountLoad() {
        var page = `${BASE_URL}discount`;
        axios.get(page).then(({ data }) => {
          console.log(data);
          this.discount = data;
        });
      },
  
      remove(discounts) {
        var url = `${BASE_URL}discount-delete/${discounts.id}`;
        axios.delete(url).then(() => {
          const index = this.discount.indexOf(discounts);
          this.discount.splice(index, 1);
        });
      },
    },
  };
  
  </script>
      
  <style scoped></style>
<template>
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="card">
          <div class="card-body">
  
            <h3 class="mb-5">User Panel</h3>
  
            <router-link to="/create-admin-user" class="btn btn-primary">Create User</router-link>
  
            <table class="table w-100">
              <thead>
                <tr>
                  <th>#</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="users in user" v-bind:key="users.id">
                  <th scope="row">{{ users.id }}</th>
                  <td>{{ users.first_name }}</td>
                  <td>{{ users.last_name }}</td>
                  <td>{{ users.email }}</td>
                  <td v-if="users.roles === 1"><span class="badge badge-success">Admin</span></td>
                  <td v-else-if="users.roles === 2"><span class="badge badge-warning">Product Admin</span></td>
                  <td v-else-if="users.roles === 3"><span class="badge badge-primary">Order Admin</span></td>
                  <td v-else-if="users.roles === 4"><span class="badge badge-danger">Affiliate</span></td>
                  <td v-else-if="users.roles === 5"><span class="badge badge-info">Sub Affiliate</span></td>
                  <td v-else>User</td>
                  
                  <td>
                    <router-link :to="{ name: 'EditAdminUser', params: { id: users.id } }"
                      class="btn btn-warning me-2"><i class="fa fa-pencil"></i></router-link>
  
                    <button @click="remove(users)" class="btn btn-danger"><i class="fa fa-trash"></i></button>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
  
    </div>
  </template>
      
  <script>
  import axios from "axios";
  import { BASE_URL } from "../../../../../config";
  
  export default {
    name: "AdminUser",
  
    data() {
      return {
        user: [],
      };
    },
  
    created() {
      this.userLoad();
    },
  
    methods: {
      userLoad() {
        var page = `${BASE_URL}admin-user`;
        axios.get(page).then(({ data }) => {
          this.user = data;
        });
      },
  
      remove(users) {
        var url = `${BASE_URL}admin-user-delete/${users.id}`;
        axios.delete(url).then(() => {
          const index = this.user.indexOf(users);
          this.user.splice(index, 1);
        });
      },
    },
  };
  
  </script>
      
  <style scoped></style>
<template>
  <head>
    <!-- Required meta tags -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <title>Gocami</title>
  </head>

  <body>
    <div class="toast" :class="{ 'show': showSuccessMessage }">
      Registration successful! You can now log in.
    </div>

    <router-view />

  </body>
</template>
<style scoped>
.toast {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #28a745;
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
}

.toast.show {
  right: 20px;
  /* Slide in to the desired position */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
    
<script>
export default {
  data() {
    return {
      loginSuccess: false, // Control the visibility of the success message

    };
  },

  beforeRouteEnter(to, from, next) {
    if (to.query.loginSuccess) {
      next(vm => {
        vm.showSuccessMessage = true;
        setTimeout(() => {
          vm.showSuccessMessage = false;
        }, 5000);
      });
    } else {
      next();
    }
  },
}
</script>
  
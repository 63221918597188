<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">User Discount</h3>

                    <router-link to="/create-user-discount" class="btn btn-primary">Create Affiliate
                        Discount</router-link>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Affilaite/User Name</th>
                                <th>Commission</th>
                                <th>Coupon Code</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="affiliateuserdiscounts in  affiliateuserdiscount "
                                v-bind:key="affiliateuserdiscounts.id">
                                <th scope="row">{{ affiliateuserdiscounts.id }}</th>
                                <td><router-link :to="{ name: 'UserInvoice', params: { id: affiliateuserdiscounts.id } }">
                                        {{ affiliateuserdiscounts.user.first_name }} {{
                                            affiliateuserdiscounts.user.last_name }}
                                    </router-link></td>
                                <td>{{ affiliateuserdiscounts.commission }}%</td>
                                <td>{{ affiliateuserdiscounts.coupon_code }}</td>
                                <td v-if="affiliateuserdiscounts.status == 1">Fixed Coupon</td>
                                <td v-else>Simple Coupon</td>
                                <td>
                                    <button @click="remove(affiliateuserdiscounts)" class="btn btn-danger"><i
                                            class="fa fa-trash"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>
      
<script>
import axios from "axios";
import { BASE_URL } from "../../../../../config";

export default {
    name: "UserDiscount",

    data() {
        return {
            affiliateuserdiscount: [],
        };
    },

    created() {
        this.affiliateuserdiscountLoad();
    },

    methods: {
        affiliateuserdiscountLoad() {
            var page = `${BASE_URL}user-discount`;
            axios.get(page).then(({ data }) => {
                this.affiliateuserdiscount = data;
            });
        },

        remove(affiliateuserdiscounts) {
            var url = `${BASE_URL}user-discount-delete/${affiliateuserdiscounts.id}`;
            axios.delete(url).then(() => {
                const index = this.affiliateuserdiscount.indexOf(affiliateuserdiscounts);
                this.affiliateuserdiscount.splice(index, 1);
            });
        },
    },
};

</script>
      
<style scoped>
* {
    text-decoration: none;
}
</style>
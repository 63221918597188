<template>
  <section class="products">
    <header class="hidden">
      <h3 class="h3 title">Product category grid</h3>
    </header>

    <div class="row row-clean">

      <!-- === product-filters === -->

      <div class="col-md-3 col-xs-12">
        <div class="filters">
          <div v-for="filter in filters" :key="filter.id" class="filter-section">
            <!-- Price Start -->
            <h5 class="section-title text-uppercase mb-3" style="margin-left:2%">
              {{ filter.filter_name.split("-")[1] }}
            </h5>

            <div class="filter-options">
              <div class="custom-checkbox" v-for="productfilters in filteredProductfilter(filter.id)"
                :key="productfilters.id" style="margin-left:2%">
                <input type="checkbox" class="custom-control-input" :id="'product_filter_' + productfilters.id"
                  :value="productfilters.id" v-model="selectedProductFilters" />
                <label class="custom-control-label" :for="'product_filter_' + productfilters.id">
                  {{ productfilters.product_filter }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-9 col-xs-12">

        <div class="sort-bar clearfix">
          <div class="sort-results pull-left">
            <!--Showing result per page-->
            <select>
              <option value="1">10</option>
              <option value="2">50</option>
              <option value="3">100</option>
              <option value="4">All</option>
            </select>
            <!--Items counter-->
            <span>Showing all <strong>50</strong> of <strong>3,250</strong> items</span>
          </div>
          <!--Sort options-->
          <div class="sort-options pull-right">
            <span class="hidden-xs">Sort by</span>
            <select>
              <option value="1">Name</option>
              <option value="2">Popular items</option>
              <option value="3">Price: lowest</option>
              <option value="4">Price: highest</option>
            </select>
            <!--Grid-list view-->
            <span class="grid-list">
              <a href="products-grid.html"><i class="fa fa-th-large"></i></a>
              <a href="products-list.html"><i class="fa fa-align-justify"></i></a>
              <a href="javascript:void(0);" class="toggle-filters-mobile"><i class="fa fa-search"></i></a>
            </span>
          </div>
        </div>

        <section class="blog blog-block">
          <div class="row row-clean">
            <div class="col-sm-12" v-for="timerSetting in timerSettings" :key="timerSetting.id">
              <article>
                <div class="image-container">
                  <img :src="'https://api.loop-delivery.com/sale/' + timerSetting.sale_image" alt="" class="sale-image" />
                  <div class="time-container">{{ formatTime(timerSetting.remainingTime) }}</div>
                </div>
              </article>
              <hr />
            </div>
          </div>
        </section>

        <div class="row row-clean">

          <div class="col-xs-6 col-sm-4 col-lg-3" v-for="products in filteredProducts" :key="products.productdetail.id">
            <div class="product-item">
              <article>
                <div class="info">
                  <span class="add-favorite added">
                    <a href="javascript:void(0);" data-title="Add to favorites"
                      data-title-added="Added to favorites list"><i class="icon icon-heart"></i></a>
                  </span>
                  <span>
                    <a href="#productid1" class="mfp-open" data-title="Quick wiew"><i class="icon icon-eye"></i></a>
                  </span>
                </div>
                <div class="btn btn-add">
                  <i class="icon icon-cart"></i>
                </div>
                <div class="figure-grid">
                  <span class="label label-info" v-if="products.productdetail.discount > 0">-{{
                    products.productdetail.discount }}%</span>
                  <div class="image">
                    <router-link :to="{ name: 'ProductDetail', params: { id: products.productdetail.product_id } }"
                      class="mfp-open">
                      <img :src="'https://api.loop-delivery.com/product_detail/' + products.productdetail.image
                        " alt="" width="360" class="product-image" />
                    </router-link>
                  </div>
                  <div class="text">
                    <h2 class="title h4">
                      <router-link :to="{ name: 'ProductDetail', params: { id: products.productdetail.product_id } }">{{
                        products.productdetail.title }}</router-link>
                    </h2>
                    <div v-if="products.productdetail.discount > 0">
                      <sub>$ {{ products.productdetail.sale_price.toFixed(2) }},-</sub>
                      <sup>$ {{ products.productdetail.sale_price - (products.productdetail.sale_price *
                        products.productdetail.discount / 100) }},-</sup>
                    </div>
                    <div v-else>
                      <sup>$ {{ products.productdetail.sale_price.toFixed(2) }},-</sup>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>

        </div><!--/row-->
        <!--Pagination-->
        <div class="pagination-wrapper">
          <ul class="pagination">
            <li>
              <a href="#" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li class="active"><a href="#">1</a></li>
            <li><a href="#">2</a></li>
            <li><a href="#">3</a></li>
            <li><a href="#">4</a></li>
            <li><a href="#">5</a></li>
            <li>
              <a href="#" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </div>

      </div> <!--/product items-->

    </div><!--/row-->

    <!-- ========================  Product info popup - quick view ======================== -->


  </section>
  <FooterLayout />
</template>
<script>
import FooterLayout from "./FooterLayout.vue";
import axios from "axios";
import { BASE_URL } from "../../../../config";

export default {
  components: { FooterLayout },
  methods: {

    fetchTimerSettings() {
      axios.get(`${BASE_URL}sale/${this.$route.params.id}`)
        .then(response => {
          const fetchedTimerSettings = response.data;
          this.timerSettings = fetchedTimerSettings.map(timerSetting => ({
            ...timerSetting,
            remainingTime: this.calculateRemainingTime(timerSetting),
          }));
          this.startTimerIntervals();

          // Check if any timer has expired and update the type field
          this.timerSettings.forEach(timerSetting => {
            if (timerSetting.type === 0 && timerSetting.remainingTime <= 0) {
              timerSetting.type = 1; // Set the type to 1 (expired)
            }
          });
        })
        .catch(error => {
          console.error(error);
          // Handle error, e.g., show an error message to the user
        });
    },

    calculateRemainingTime(timerSetting) {
      const startTime = new Date(timerSetting.start_time).getTime();
      const endTime = startTime + timerSetting.duration * 60 * 1000;
      const remainingTime = Math.ceil((endTime - Date.now()) / 1000); // Convert to seconds and round up
      return Math.max(remainingTime, 0); // Ensure the remaining time is not negative
    },
    startTimerIntervals() {
      this.timerSettings.forEach(timerSetting => {
        const timerInterval = setInterval(() => {
          this.updateRemainingTime(timerSetting);
        }, 1000);
        this.timerIntervals[timerSetting.id] = timerInterval;
      });
    },
    updateRemainingTime(timerSetting) {
      timerSetting.remainingTime -= 1; // Subtract 1 second from the remaining time

      if (timerSetting.remainingTime <= 0) {
        timerSetting.remainingTime = 0;
        timerSetting.type = 1; // Set the type to 1 (expired)
        this.updateTimerType(timerSetting.id, 1); // Call the API to update the type in the backend
        this.clearIntervalForTimer(timerSetting.id);
      }
    },
    updateTimerType(timerId, newType) {
      axios.put(`${BASE_URL}sale-update/${timerId}`, { type: newType })
        .then(() => {
        })
        .catch(() => {
        });
    },
    clearIntervalForTimer(timerId) {
      clearInterval(this.timerIntervals[timerId]);
      delete this.timerIntervals[timerId];
    },
    formatTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(remainingSeconds)}`;
    },
    padZero(value) {
      return value.toString().padStart(2, '0');
    },
    clearAllIntervals() {
      Object.values(this.timerIntervals).forEach(interval => {
        clearInterval(interval);
      });
      this.timerIntervals = {};
    },

    dataLoad() {
      var page = `${BASE_URL}productlist-view/${this.$route.params.id}`;
      axios.get(page).then(({ data }) => {
        console.log(data);
        this.childcategories = data.childcategory;
        this.products = data.product;
        this.filters = data.filter;
        this.productfilters = data.productfilter;
        this.filterbyproducts = data.filterbyproduct;
        this.sales = data.sale;
      });
    },

    filteredProductfilter(filterId) {
      return this.productfilters.filter(productfilters => productfilters.filter_id === filterId);
    },
  },
  data() {
    return {
      selectedProductFilters: [],
      childcategories: [],
      products: [],
      filters: [],
      productfilters: [],
      filterbyproducts: [],
      sales: [],
      timerSettings: [],
      timerIntervals: {},
    };
  },

  created() {
    this.dataLoad();
    this.fetchTimerSettings();
  },

  beforeUnmount() {
    this.clearAllIntervals();
  },

  computed: {
    filteredProducts() {
      if (this.selectedProductFilters.length === 0) {
        return this.products;
      } else {
        const filteredProductIds = this.filterbyproducts
          .filter(filterbyproduct => this.selectedProductFilters.includes(filterbyproduct.product_filter_id))
          .map(filterbyproduct => filterbyproduct.product_detail_id);
        return this.products.filter(product => filteredProductIds.includes(product.productdetail.id));
      }
    },
  },


};
</script>
  
<style scoped>
/* Custom checkbox styles */
.filter-section {
  margin-bottom: 20px;
}

.section-title {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.filter-options {
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.custom-control-input {
  margin-right: 10px;
}

.custom-control-label {
  font-size: 0.9em;
  color: #555;
}

.custom-control-label:hover {
  color: #333;
}

.custom-checkbox input[type="checkbox"] {
  /* Hide the default checkbox */
  position: absolute;
  opacity: 0;
}

.custom-checkbox label {
  /* Style the custom checkbox */
  display: inline-block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.custom-checkbox label:before {
  /* Create the checkbox indicator */
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
}

.custom-checkbox input[type="checkbox"]:checked+label:before {
  /* Style the checked checkbox */
  background-color: #2196F3;
  /* Change to your desired color */
  border-color: #2196F3;
  /* Change to your desired color */
}

.custom-checkbox label:after {
  /* Create the checkbox inner indicator */
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input[type="checkbox"]:checked+label:after {
  /* Show the inner indicator when checkbox is checked */
  display: block;
  top: 5px;
  left: 5px;
  width: 6px;
  height: 6px;
  background-color: white;
}

.image-container {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.sale-image {
  height: 30vh;
  margin-right: 10px;
}

.time-container {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
  font-size: 14px;
}

.product-item {
  height: 400px;
  /* Adjust the height as per your requirement */
  overflow: hidden;
}

.product-image {
  height: 200px;
  /* Adjust the height as per your requirement */
  object-fit: cover;
  /* or object-fit: contain; */
}
</style>
<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Orders Panel</h3>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>User Name</th>
                                <th>Location</th>
                                <th>Address</th>
                                <th>Note</th>
                                <th>Status</th>
                                <th>Action</th>
                                <!-- <th>Action</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="orders in order" :key="orders.id"> <!-- Update from "orders" to "order" -->
                                <th scope="row">{{ orders.id }}</th>
                                <td>{{ orders.first_name }} {{ orders.middle_name }} {{ orders.last_name }}</td>
                                <td>{{ orders.area_id }}</td>
                                <td>{{ orders.address }}</td>
                                <td>{{ orders.note }}</td>
                                <td v-if="orders.status == 0" class="bg-danger text-center">pending</td>
                                <td>
                                    <form @submit.prevent="editData(orders)">
                                        <input type="hidden" v-model="orders.id" />
                                        <button type="submit" class="btn btn-success">Update</button>
                                    </form>
                                    <!-- <router-link :to="{ name: 'ViewOrder', params: { id: orders.id } }"
                                        class="btn btn-warning me-2">
                                        <i class="fa fa-eye"></i>
                                    </router-link>
                                    <router-link :to="{ name: 'ExportInvoice', params:{id: orders.id}}">invoice</router-link> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>
      
<script>
import axios from "axios";
import { BASE_URL } from "../../../../../config";

export default {
    name: "ViewOrder",

    data() {
        return {
            order: [],
        };
    },

    created() {
        this.orderLoad();
    },

    methods: {
        orderLoad() {
            var page = `${BASE_URL}orders`;
            axios.get(page).then(({ data }) => {
                console.log(data);
                this.order = data.orders;
            });
        },

        editData(orders) {
            axios
                .put(
                    `${BASE_URL}update-pending-order/${orders.id}`,
                    orders
                )
                .then(() => {
                    // Reload the page
                    window.location.reload();
                })
                .catch((error) => {
                    console.log(error);
                });
        },


    },
};

</script>
      
<style scoped></style>
<template>
    <nav class="sidebar sidebar-offcanvas" id="sidebar">

        <ul class="nav">
            <li class="nav-item nav-profile">
                <a href="#" class="nav-link">
                    <div class="nav-profile-image">
                        <span class="login-status online"></span>
                        <!-- change to offline or busy as needed -->
                    </div>
                    <div class="nav-profile-text d-flex flex-column">
                        <span class="font-weight-bold mb-2">{{publicKey}}</span>
                        <span class="text-secondary text-small">Admin Full Access</span>
                    </div>
                    <i class="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
                </a>
            </li>
            <li class="nav-item">
                <Link class="nav-link" href="/dashboard">Dashboard
                <!-- <span class="menu-title"></span>
<i class="mdi mdi-home menu-icon"></i> -->
                </Link>
            </li>

            <div class="btn-group dropright mx-auto text-left">
                <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    Orders
                </button>
                <div class="dropdown-menu">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/all-orders">
                            <span class="menu-title">All Orders</span>
                            <i class="mdi mdi-contacts menu-icon"></i>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/pending-orders">
                            <span class="menu-title">Pending Orders</span>
                            <i class="mdi mdi-contacts menu-icon"></i>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/closed-orders">
                            <span class="menu-title">Closed Orders</span>
                            <i class="mdi mdi-contacts menu-icon"></i>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/moved-orders">
                            <span class="menu-title">Shipped Orders</span>
                            <i class="mdi mdi-contacts menu-icon"></i>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/refund-orders">
                            <span class="menu-title">Refund Orders</span>
                            <i class="mdi mdi-contacts menu-icon"></i>
                        </router-link>
                    </li>
                </div>
            </div>

            <li class="nav-item">
                <router-link class="nav-link" to="/logo-view">
                    <span class="menu-title">Logo</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/slider">
                    <span class="menu-title">Slider</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/admin-user">
                    <span class="menu-title">User</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/color">
                    <span class="menu-title">Color</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/size">
                    <span class="menu-title">Size</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/brand">
                    <span class="menu-title">Brand</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/category">
                    <span class="menu-title">Category</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/subcategory">
                    <span class="menu-title">Sub Category</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/childcategory">
                    <span class="menu-title">Child Category</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/attribute">
                    <span class="menu-title">Attribute</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/attributetype">
                    <span class="menu-title">Attribute Type</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/filter">
                    <span class="menu-title">Filters</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/filtertype">
                    <span class="menu-title">Filters Type</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/product">
                    <span class="menu-title">Product</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/productdetail">
                    <span class="menu-title">Product Details</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/prodcat">
                    <span class="menu-title">Product Category</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/product-conf">
                    <span class="menu-title">Product Configuration</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/refund">
                    <span class="menu-title">Refund Invoice</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/discount">
                    <span class="menu-title">Discount</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/brand-discount">
                    <span class="menu-title">Brand Discount</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/product-discount">
                    <span class="menu-title">Product Discount</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/affiliate-discount">
                    <span class="menu-title">Affiliate Discount</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/affiliate-user-discount">
                    <span class="menu-title">Affiliate User Discount</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/sale">
                    <span class="menu-title">Sale</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" to="/product-sale">
                    <span class="menu-title">Product Sale</span>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </router-link>
            </li>

        </ul>
    </nav>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
    data(){
        return {
            publicKey: localStorage.getItem("name"),
        }
    },  
    components: {
        RouterLink
    },
    // other component options
}

</script>
<style></style>

<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save" enctype="multipart/form-data">
                        <select v-model="sale.child_category_id" class="form-control mb-3">
                            <option value="0" selected hidden>-- Choose Child Category --</option>
                            <option v-for="childcategory in childcategories" :key="childcategory.id"
                                :value="childcategory.id">
                                {{ childcategory.child_category_name }}
                            </option>
                        </select>

                        <div>
                            <label for="duration">Duration (in minutes):</label>
                            <input type="datetime-local" class="form-control mb-3" v-model="sale.countdown_time" required />
                        </div>

                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />

                        <progress v-if="sale.progress" :value="sale.progress.percentage" max="100">
                            {{ sale.progress.percentage }}%
                        </progress>

                        <button type="submit" class="btn btn-primary">Create Sale</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>

import axios from "axios";
import router from "@/router";
import { BASE_URL } from "../../../../../config";

export default {
    name: "CreateSale",

    data() {
        return {
            sale: {
                child_category_id: 0,
                sale_image: '',
                countdown_time: '',
            },
            childcategories: [],
        };
    },

    methods: {
        onFileChange(event) {
            this.sale.sale_image = event.target.files[0];
        },

        save() {
            this.saveData();
        },
        saveData() {
            let formData = new FormData();
            formData.append('child_category_id', this.sale.child_category_id);
            formData.append('sale_image', this.sale.sale_image);
            formData.append('countdown_time', this.sale.countdown_time);
            axios.post(`${BASE_URL}sale-store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/sale");
            });
        },

        fetchData() {
            axios
                .get(`${BASE_URL}sale-create`)
                .then((response) => {
                    this.childcategories = response.data.childcategory;
                })
        },
    },

    mounted() {
        this.fetchData();
    },

};
</script>
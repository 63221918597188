<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="editData">
                        <input type="hidden" v-model="color.id" />
                        <input type="text" class="form-control" v-model="color.color_name" />

                        <button type="submit" class="btn btn-success">Save</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axios from "axios";
import router from "@/router";
import { BASE_URL } from "../../../../../config";

export default {
    name: "EditColor",

    data() {
        return {
            color: {
                color_name: "",
                id: this.$route.params.id,
            },
            oldColor: {}, // new data property to store the old employee data
        };
    },
    methods: {
        editData() {
            axios
                .put(
                    `${BASE_URL}color-update/${this.color.id}`, // Use the BASE_URL constant for the base URL
                    this.color
                )
                .then(() => {
                    this.color = {
                        color_name: "",
                        id: "",
                    };
                    router.push("/color");
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchData() {
            axios
                .get(`${BASE_URL}color-edit/` + this.color.id)
                .then((response) => {
                    this.oldColor = response.data; // store the old employee data in oldEmployee property
                    this.color.color_name = this.oldColor.color_name; // set the old employee_name value to the input
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.fetchData(); // call the fetchData method when the component is mounted to fetch the old employee data
    },
};
</script>
<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save" enctype="multipart/form-data">

                        <select v-model="productdetail.product_id" class="form-control mb-3">
                            <option value="0" selected disabled hidden>-- Choose Product Name --</option>
                            <option v-for="products in product" :key="products.id" :value="products.id">
                                {{ products.product_name }}
                            </option>
                        </select>

                        <input type="text" class="form-control mb-3" placeholder="Product Title"
                            v-model="productdetail.title" />

                        <input type="text" class="form-control mb-3" placeholder="Product Sku"
                            v-model="productdetail.sku" />

                        <input type="text" class="form-control mb-3" placeholder="Product Description"
                            v-model="productdetail.description" />

                        <input type="text" class="form-control mb-3" placeholder="Product Details"
                            v-model="productdetail.details" />

                        <input type="text" class="form-control mb-3" placeholder="Product Sale Price"
                            v-model="productdetail.sale_price" />

                        <input type="text" class="form-control mb-3" placeholder="Product Discount"
                            v-model="productdetail.discount" />

                        <input type="text" class="form-control mb-3" placeholder="Product Location"
                            v-model="productdetail.location" />

                        <input type="text" class="form-control mb-3" placeholder="Product Barcode"
                            v-model="productdetail.barcode" />

                        <input type="datetime-local" class="form-control mb-3" v-model="productdetail.expiry_date" required />


                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />

                        <progress v-if="productdetail.progress" :value="productdetail.progress.percentage" max="100">
                            {{ productdetail.progress.percentage }}%
                        </progress>

                        <select v-model="productdetail.brand_id" class="form-control mb-3">
                            <option value="0" selected disabled hidden>-- Choose Brand Name --</option>
                            <option v-for="brands in brand" :key="brands.id" :value="brands.id">
                                {{ brands.brand_name }}
                            </option>
                        </select>

                        <h4>Filters:</h4>

                        <div class="form-group" v-for="(productdetail, index) in productdetail.products" :key="index">

                            <div class="row mb-5">
                                <div class="col-4">
                                    <select class="form-control mb-3" v-model="productdetail.selectedChildcategory">
                                        <option value="0" selected disabled hidden>-- Choose Child Category Name --</option>
                                        <option v-for="childcategori in childcategory" :key="childcategori.id"
                                            :value="childcategori.id">
                                            {{ childcategori.child_category_name }}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-4">
                                    <select class="form-control" v-model="productdetail.selectedFilter">
                                        <option value="0" hidden selected>Select Filter</option>
                                        <option
                                            v-for="filter in filteredFilterchildcategory(productdetail.selectedChildcategory)"
                                            :key="filter.id" :value="filter.id">
                                            {{ filter.filter_name }}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-4">
                                    <select class="form-control" v-model="productdetail.product_filter_id">
                                        <option value="0" hidden selected>Select Product Filter</option>
                                        <option
                                            v-for="filterbyproduct in filteredProductbyfilter(productdetail.selectedFilter)"
                                            :key="filterbyproduct.id" :value="filterbyproduct.id">{{
                                                filterbyproduct.product_filter }}</option>
                                    </select>
                                </div>
                                <div class="col-md-1 d-flex">
                                    <button type="button" class="btn btn-danger me-3"
                                        @click="removeProduct(index)">-</button>
                                    <button type="button" class="btn btn-success" @click="addFilter">+</button>
                                </div>
                            </div>
                        </div>

                        <h4 class="mt-3">Attributes</h4>

                        <div class="row m-3">
                            <div class="col-3">
                                <select class="form-control" v-model="productdetail.color_id">
                                    <option v-for="colors in color" :key="colors.id" :value="colors.id">
                                        {{ colors.color_name }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-3">
                                <select class="form-control" v-model="productdetail.size_id">
                                    <option v-for="sizes in size" :key="sizes.id" :value="sizes.id">
                                        {{ sizes.size_name }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-3">
                                <select class="form-control" v-model="productdetail.attribute_type_id">
                                    <option v-for="attributetypes in attributetype" :key="attributetypes.id"
                                        :value="attributetypes.id">
                                        {{ attributetypes.attribute_type_name }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-3">
                                <input type="text" class="form-control" v-model="productdetail.quantity"
                                    placeholder="Product Quantity">
                            </div>

                        </div>

                        <button type="submit" class="btn btn-primary">Create</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
        

<script>
import axios from "axios";
import router from "@/router";
import { BASE_URL } from "../../../../../config";

export default {
    name: "CreateProductDetail",

    data() {
        return {
            productdetail: {
                brand_id: 0,
                product_id: 0,
                sku: "",
                title: "",
                description: "",
                details: "",
                sale_price: "",
                discount: 0,
                image: "",
                selectedChildcategory: [],
                selectedFilter: [],
                product_filter_id: [],
                products: [{ product_filter_id: '' }],
                color_id: '',
                size_id: '',
                attribute_type_id: '',
                quantity: "",
                location: "",
                barcode: "",
                expiry_date: "",
            },

            childcategory: [],
            brand: [],
            product: [],
            filter: [],
            productbyfilter: [],
            color: [],
            size: [],
            attributetype: []
        };
    },

    methods: {

        addFilter() {
            this.productdetail.products.push({ selectedChildcategory: '', selectedFilter: '', product_filter_id: '' });
            this.productdetail.selectedChildcategory.push('');
            this.productdetail.selectedFilter.push('');
            this.productdetail.product_filter_id.push('');
        },

        removeProduct(index) {
            this.productdetail.products.splice(index, 1);
            this.productdetail.selectedChildcategory.splice(index, 1);
            this.productdetail.selectedFilter.splice(index, 1);
            this.productdetail.product_filter_id.splice(index, 1);
        },

        filteredFilterchildcategory(childcategoryId) {
            return this.filter.filter(filter => filter.child_category_id === childcategoryId)
        },

        filteredProductbyfilter(filterId) {
            return this.productbyfilter.filter(productbyfilter => productbyfilter.filter_id === filterId);
        },

        onFileChange(event) {
            this.productdetail.image = event.target.files[0];
        },

        save() {
            this.saveData();
        },

        saveData() {
            let formData = new FormData();
            formData.append('product_id', this.productdetail.product_id);
            formData.append('sku', this.productdetail.sku);
            formData.append('title', this.productdetail.title);
            formData.append('description', this.productdetail.description);
            formData.append('details', this.productdetail.details);
            formData.append('sale_price', this.productdetail.sale_price);
            formData.append('discount', this.productdetail.discount);
            formData.append('image', this.productdetail.image);
            formData.append('brand_id', this.productdetail.brand_id);
            formData.append('color_id', this.productdetail.color_id);
            formData.append('product_detail_id', this.productdetail.product_detail_id);
            formData.append('product_detail_color', this.productdetail.product_detail_color);
            formData.append('size_id', this.productdetail.size_id);
            formData.append('product_detail_size', this.productdetail.product_detail_size);
            formData.append('attribute_type_id', this.productdetail.attribute_type_id);
            formData.append('product_detail_attribute', this.productdetail.product_detail_attribute);
            formData.append('quantity', this.productdetail.quantity);
            formData.append('location', this.productdetail.location);
            formData.append('expiry_date', this.productdetail.expiry_date);
            formData.append('barcode', this.productdetail.barcode);

            // Loop through each object in the products array and append the product_filter_id value
            for (let i = 0; i < this.productdetail.products.length; i++) {
                formData.append(`products[${i}][product_filter_id]`, this.productdetail.products[i].product_filter_id);
            }

            axios.post(`${BASE_URL}productdetail-store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/productdetail");
            });
        },

        fetchData() {
            axios
                .get(`${BASE_URL}productdetail-create`)
                .then((response) => {
                    this.product = response.data.product;
                    this.brand = response.data.brand;
                    this.childcategory = response.data.childcategory;
                    this.filter = response.data.filter;
                    this.productbyfilter = response.data.productbyfilter;
                    this.color = response.data.color;
                    this.size = response.data.size;
                    this.attributetype = response.data.attributetype;
                }).catch((error) => {
                    console.log(error);
                });
        },
    },

    mounted() {
        this.fetchData();
    },
};

</script>
